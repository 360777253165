import { SpotifyArtist } from '../types';
import { SPOTIFY_ARTISTS_CONFIG } from '../constants';

export const getSpotifyFilteredArtists = (
  artists: SpotifyArtist[],
): SpotifyArtist[] => {
  return artists.filter((artist) => artist.id in SPOTIFY_ARTISTS_CONFIG);
};

export const isSpotifySilentArtist = (artist: SpotifyArtist): boolean => {
  return SPOTIFY_ARTISTS_CONFIG[artist.id].silent;
};

export const getSpotifyNonSilentArtists = (
  artists: SpotifyArtist[],
): SpotifyArtist[] => {
  return artists.filter((artist) => !isSpotifySilentArtist(artist));
};
