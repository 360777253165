import { call, put, takeLatest } from 'redux-saga/effects';
import { logEvent } from 'firebase/analytics';
import { NotifiableError } from '@bugsnag/core/types/common';
import { FIREBASE_EVENT } from '../../../analytics/events';
import { handleError } from '../../../errors';
import { spotifyInitUser } from '../actions';
import { SpotifyUser } from '../types';
import { fetchSpotifyUserRequest } from '../api';
import { analytics } from '../../../analytics';

function* handleSpotifyInitUser() {
  try {
    const user: SpotifyUser = yield call(fetchSpotifyUserRequest);
    yield put(spotifyInitUser.success(user));
    logEvent(analytics, FIREBASE_EVENT.SPOTIFY_USER_INIT_SUCCESS);
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.SPOTIFY_USER_INIT_FAILURE,
    });
    yield put(spotifyInitUser.failure(error));
  }
}

export function* watchSpotifyInitUser() {
  yield takeLatest(spotifyInitUser.request, handleSpotifyInitUser);
}
