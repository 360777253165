import { SpotifyUser } from './types';
import { SpotifyApi } from '../SpotifyApi';

export const fetchSpotifyUserRequest = async (): Promise<SpotifyUser> => {
  const response = await SpotifyApi.get('https://api.spotify.com/v1/me');

  const { display_name: name, id } = response.data;

  return {
    id,
    name,
  };
};
