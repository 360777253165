import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    maxWidth: 500,

    '@media (min-width: 600px)': {
      width: 500,
    },
  },
}));

export const Content: FC = (props) => {
  const { children } = props;

  const classes = useStyles();

  return <Box className={classes.wrapper}>{children}</Box>;
};
