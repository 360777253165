import React, { FC, useCallback, useEffect } from 'react';
import { PlatformOption } from './PlatformOption';
import Cookies from 'js-cookie';
import { DEEZER_AUTH_TOKEN_KEY } from '../../deezer/constants';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { ReactComponent as DeezerSvg } from '../../svg/deezer.svg';

export const PlatformOptionDeezer: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get(DEEZER_AUTH_TOKEN_KEY)) {
      navigate(AppRoute.deezer, { replace: true });
    }
  }, [navigate]);

  const handleClick = useCallback(() => {
    navigate(AppRoute.deezer);
  }, [navigate]);

  return (
    <PlatformOption name="Deezer" icon={DeezerSvg} onClick={handleClick} />
  );
};
