import { createSelector } from 'reselect';
import { orderBy } from 'lodash';
import { getState } from '../../store/selectors';
import { getSpotifyUser } from '../user/selectors';
import { RootState } from '../../store';
import { SpotifyPlaylist, SpotifyPlaylistStateRecordExtended } from './types';
import { getSettingsSilentVisible } from '../../settings/selectors';
import {
  getSpotifyNonSilentLibraryTracks,
  isSpotifySilentTrack,
} from '../utils';

const getSpotify = createSelector(getState, ({ spotify }) => spotify);
export const getSpotifyPlaylists = createSelector(
  getSpotify,
  ({ playlists }) => playlists,
);
export const getSpotifyPlaylistsPagination = createSelector(
  getSpotifyPlaylists,
  ({ pagination }) => pagination,
);

export const getSpotifyPlaylistList = createSelector(
  getSpotifyPlaylists,
  ({ playlists }) => playlists,
);

export const getSpotifyPlaylistsLoading = createSelector(
  getSpotifyPlaylists,
  ({ loading }) => loading,
);

export const getSpotifyPlaylistsState = createSelector(
  getSpotifyPlaylists,
  getSettingsSilentVisible,
  ({ playlistsState }, silentVisible) =>
    Object.entries(playlistsState).reduce((prev, [playlistId, state]) => {
      return {
        ...prev,
        [playlistId]: {
          ...state,
          visibleTracks: silentVisible
            ? state.tracks
            : getSpotifyNonSilentLibraryTracks(state.tracks),
          silentTracks: state.tracks.filter(isSpotifySilentTrack),
          selectedTracks: silentVisible
            ? state.selectedTracks
            : getSpotifyNonSilentLibraryTracks(state.selectedTracks),
        },
      };
    }, {} as SpotifyPlaylistStateRecordExtended),
);

export const getSpotifyVisiblePlaylists = createSelector(
  getSpotifyPlaylistList,
  getSpotifyPlaylistsState,
  getSpotifyUser,
  (playlists, playlistsState, user) => {
    return orderBy(
      playlists.filter(({ owner }) => owner.id === user?.id),
      (playlist) => playlistsState[playlist.id].visibleTracks,
      'desc',
    );
  },
);

const getSpotifyPlaylistState = createSelector(
  getSpotifyPlaylistsState,
  (_: RootState, playlistId: SpotifyPlaylist['id']) => playlistId,
  (playlistsState, playlistId) => playlistsState[playlistId],
);

export const getSpotifyPlaylistStateById = (
  playlistId: SpotifyPlaylist['id'],
) => {
  return (state: RootState) => getSpotifyPlaylistState(state, playlistId);
};

export const getSpotifyPlaylistsTracksTotal = createSelector(
  getSpotifyPlaylistsState,
  (playlistsState) =>
    Object.values(playlistsState).reduce(
      (prev, current) => {
        return {
          total: prev.total + current.tracks.length,
          silentTotal: prev.silentTotal + current.silentTracks.length,
          visibleTotal: prev.visibleTotal + current.visibleTracks.length,
        };
      },
      { total: 0, silentTotal: 0, visibleTotal: 0 },
    ),
);
