import React, { FC } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress';

interface ProgressProps extends CircularProgressProps {
  current: number;
  total: number;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const Progress: FC<ProgressProps> = (props) => {
  const { current, total, ...rest } = props;
  const classes = useStyles();

  if (!(current || total) || current > total) {
    return <CircularProgress color="inherit" {...rest} />;
  }

  const value = Math.round((current / total) * 100);

  return (
    <Box className={classes.wrapper}>
      <CircularProgress
        variant="determinate"
        color="inherit"
        value={value}
        {...rest}
      />
      <Typography variant="caption">{`${value}%`}</Typography>
    </Box>
  );
};
