import React, { FC } from 'react';
import { Settings } from '../../../../components/Settings';
import { useSelector } from 'react-redux';
import { getDeezerUserArtistsSilent } from '../../selectors';

export const DeezerUserArtistsSettings: FC = () => {
  const silentArtists = useSelector(getDeezerUserArtistsSilent);

  return <Settings silentTotal={silentArtists.length} />;
};
