import React, { FC } from 'react';
import { MainPage } from './MainPage';
import { PlatformSelect } from '../components/PlatformSelect';
import { useTranslation } from 'react-i18next';

export const HomePage: FC = () => {
  const { t } = useTranslation();
  return (
    <MainPage title={t('home.title')} description={t('home.description')}>
      <PlatformSelect />
    </MainPage>
  );
};
