import { all, call } from 'redux-saga/effects';
import {
  watchSpotifyDeleteFollowedArtists,
  watchSpotifyFetchFollowedArtists,
  watchSpotifyInitFollowedArtists,
} from './sagas';

export function* spotifyFollowedArtistsSaga() {
  yield all([
    call(watchSpotifyInitFollowedArtists),
    call(watchSpotifyFetchFollowedArtists),
    call(watchSpotifyDeleteFollowedArtists),
  ]);
}
