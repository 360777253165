import { all, call } from 'redux-saga/effects';
import {
  watchDeezerDeleteLibraryTracks,
  watchDeezerFetchLibraryTracks,
  watchDeezerInitLibrary,
} from './sagas';

export function* deezerLibrarySaga() {
  yield all([
    call(watchDeezerInitLibrary),
    call(watchDeezerFetchLibraryTracks),
    call(watchDeezerDeleteLibraryTracks),
  ]);
}
