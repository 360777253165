import React, { FC, useCallback, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { useInfoButtonStyles } from './styles';
import { InstagramPostView } from './IntagramPostView';

interface InfoButtonProps {
  icon?: React.ReactNode;
}

export const InfoButton: FC<InfoButtonProps> = (props) => {
  const { icon } = props;
  const { t } = useTranslation();
  const classes = useInfoButtonStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Box>
      <IconButton onClick={handleOpen}>{icon ? icon : <InfoIcon />}</IconButton>
      <Dialog onClose={handleClose} open={open} scroll="paper">
        <DialogTitle>
          {t('info.title')}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.close}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <InstagramPostView />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
