import { useCallback, useState } from 'react';

export const useSelectItems = <T>() => {
  const [items, setItems] = useState<T[] | null>(null);

  const setItem = useCallback((item) => setItems([item]), [setItems]);

  const deselectItems = useCallback(() => setItems(null), [setItems]);

  return {
    items,
    deselectItems,
    selectItems: setItems,
    selectItem: setItem,
  };
};
