import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { EntityEvidenceContent } from './EntityEvidenceContent';
import { EntityEvidence, EntityType } from '../types';
import { useTranslation } from 'react-i18next';

export interface EvidenceDialogEntity {
  id: string | number;
  name: string;
  evidence: EntityEvidence;
  silent: boolean;
  owner?: string;
}

interface EvidenceDialogProps {
  trackName?: string;
  entities: EvidenceDialogEntity[];
  open: boolean;
  onClose: () => void;
  entityType?: EntityType;
}

export const EvidenceDialog: FC<EvidenceDialogProps> = (props) => {
  const { open, onClose, trackName, entities, entityType } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="evidence-dialog-title"
      aria-describedby="evidence-dialog-description"
      scroll="paper"
    >
      <DialogTitle id="evidence-dialog-title">{t('details')}</DialogTitle>
      <DialogContent>
        {trackName ? (
          <Typography mb={1} fontSize="small">
            {t('evidence.dialog.description', {
              trackName: `${trackName} - ${entities
                .map(({ name }) => name)
                .join(', ')}`,
            })}
          </Typography>
        ) : null}
        {entities.map(({ id, evidence, silent, name, owner }) => (
          <EntityEvidenceContent
            key={id}
            entityName={name}
            evidence={evidence}
            silent={silent}
            entityType={entityType}
            owner={owner}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};
