import { YoutubeSubscription } from './types';
import { YOUTUBE_CHANNELS_CONFIG } from '../constants';

export const getYoutubeFilteredSubscriptions = (
  subscriptions: YoutubeSubscription[],
): YoutubeSubscription[] => {
  return subscriptions.filter(
    (subscription) =>
      subscription.snippet.resourceId.channelId in YOUTUBE_CHANNELS_CONFIG,
  );
};

export const isYoutubeSilentSubscription = (
  subscription: YoutubeSubscription,
): boolean => {
  return YOUTUBE_CHANNELS_CONFIG[subscription.snippet.resourceId.channelId]
    .silent;
};

export const getYoutubeNonSilentSubscriptions = (
  subscriptions: YoutubeSubscription[],
): YoutubeSubscription[] => {
  return subscriptions.filter(
    (subscription) => !isYoutubeSilentSubscription(subscription),
  );
};
