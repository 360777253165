import React, { FC, memo } from 'react';
import { DeezerArtist } from '../../../types';
import { UserArtist } from '../../../../components/UserArtists/UserArtist';

interface DeezerUserArtistProps {
  artist: DeezerArtist;
  onDelete: (artist: DeezerArtist) => void;
  onSelect: (artist: DeezerArtist) => void;
  onDeselect: (artist: DeezerArtist) => void;
  onDetails: (artist: DeezerArtist) => void;
  selected: boolean;
}

export const DeezerUserArtist: FC<DeezerUserArtistProps> = memo((props) => {
  const { artist, onDelete, selected, onSelect, onDeselect, onDetails } = props;
  const { name, picture } = artist;

  return (
    <UserArtist
      artist={artist}
      picture={picture}
      name={name}
      selected={selected}
      onSelect={onSelect}
      onDeselect={onDeselect}
      onDelete={onDelete}
      onDetails={onDetails}
    />
  );
});

DeezerUserArtist.displayName = 'DeezerUserArtist';
