import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const fontFamily = 'Arial,sans-serif';

export const useInfoButtonStyles = makeStyles((theme: Theme) => ({
  content: {
    '&.MuiDialogContent-root': {
      padding: 0,
    },
  },
  close: {
    '&.MuiButtonBase-root': {
      position: 'absolute',
      right: 8,
      top: 8,
      color: theme.palette.grey[500],
    },
  },
  blockquote: {
    background: '#FFF',
    border: 0,
    borderRadius: '3px',
    boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
    margin: '1px',
    maxWidth: 540,
    minWidth: 326,
    padding: 0,
    width: 'calc(100% - 2px)',
  },
  contentLink: {
    background: '#FFFFFF',
    lineHeight: 0,
    padding: '0 0',
    textAlign: 'center',
    textDecoration: 'none',
    width: '100%',
  },
  block: {
    backgroundColor: '#F4F4F4',
    borderRadius: '50%',
    flexGrow: 0,
    height: 40,
    marginRight: '14px',
    width: 40,
  },
  block2: {
    backgroundColor: '#F4F4F4',
    borderRadius: '4px',
    flexGrow: 0,
    height: 14,
    marginBottom: '6px',
    width: 100,
  },
  block3: {
    backgroundColor: '#F4F4F4',
    borderRadius: '4px',
    flexGrow: 0,
    height: 14,
    width: 60,
  },
  block4: {
    display: 'block',
    height: 50,
    margin: '0 auto 12px',
    width: 50,
  },
  viewTextWrapper: {
    color: '#3897f0',
    fontFamily: fontFamily,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 550,
    lineHeight: '18px',
  },
  block5: {
    backgroundColor: '#F4F4F4',
    borderRadius: '50%',
    height: '12.5px',
    width: '12.5px',
    transform: 'translateX(0px) translateY(7px)',
  },
  block6: {
    backgroundColor: '#F4F4F4',
    height: '12.5px',
    transform: 'rotate(-45deg) translateX(3px) translateY(1px)',
    width: '12.5px',
    flexGrow: 0,
    marginRight: '14px',
    marginLeft: '2px',
  },
  block7: {
    backgroundColor: '#F4F4F4',
    borderRadius: '50%',
    height: '12.5px',
    width: '12.5px',
    transform: 'translateX(9px) translateY(-18px)',
  },
  block8: {
    backgroundColor: '#F4F4F4',
    borderRadius: '50%',
    flexGrow: 0,
    height: '20px',
    width: '20px',
  },
  block9: {
    width: 0,
    height: 0,
    borderTop: '2px solid transparent',
    borderLeft: '6px solid #f4f4f4',
    borderBottom: '2px solid transparent',
    transform: 'translateX(16px) translateY(-4px) rotate(30deg)',
  },
  block10: {
    width: '0px',
    borderTop: '8px solid #F4F4F4',
    borderRight: '8px solid transparent',
    transform: 'translateY(16px)',
  },
  block11: {
    backgroundColor: '#F4F4F4',
    flexGrow: 0,
    height: '12px',
    width: '16px',
    transform: 'translateY(-4px)',
  },
  block12: {
    width: 0,
    height: 0,
    borderTop: '8px solid #F4F4F4',
    borderLeft: '8px solid transparent',
    transform: 'translateY(-4px) translateX(8px)',
  },
  block13: {
    backgroundColor: '#F4F4F4',
    borderRadius: '4px',
    flexGrow: 0,
    height: '14px',
    marginBottom: '6px',
    width: '224px',
  },
  block14: {
    backgroundColor: '#F4F4F4',
    borderRadius: '4px',
    flexGrow: 0,
    height: '14px',
    width: '144px',
  },
  authorWrapper: {
    color: '#c9c8cd',
    fontFamily: fontFamily,
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: 0,
    marginTop: '8px',
    overflow: 'hidden',
    padding: '8px 0 7px',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  author: {
    color: '#c9c8cd',
    fontFamily: fontFamily,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '17px',
    textDecoration: 'none',
  },
}));
