import { NotifiableError } from '@bugsnag/core/types/common';
import {
  deezerDeleteUserArtists,
  deezerFetchUserArtists,
  deezerInitUserArtists,
} from '../actions';
import { logEvent } from 'firebase/analytics';
import { ActionType } from 'typesafe-actions';
import { takeLatest, take, call, put, select } from 'redux-saga/effects';
import {
  deleteDeezerUserArtistsRequest,
  fetchDeezerUserArtistsRequest,
} from '../api';
import { DeezerPagination } from '../../types';
import { analytics } from '../../../analytics';
import { FIREBASE_EVENT } from '../../../analytics/events';
import { handleError } from '../../../errors';
import { Platform } from '../../../types';
import { getDeezerUserArtistsPagination } from '../selectors';
import { DeezerUserArtistsFetchResponse } from '../types';

function* handleDeezerInitUserArtists() {
  let pagination: DeezerPagination = yield select(
    getDeezerUserArtistsPagination,
  );
  try {
    do {
      yield put(
        deezerFetchUserArtists.request({
          url: pagination.next,
        }),
      );
      yield take(deezerFetchUserArtists.success);
      pagination = yield select(getDeezerUserArtistsPagination);
    } while (pagination.next);
    yield put(deezerInitUserArtists.success());
    logEvent(analytics, FIREBASE_EVENT.DEEZER_USER_ARTISTS_INIT_SUCCESS);
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.DEEZER_USER_ARTISTS_INIT_FAILURE,
    });
    yield put(deezerInitUserArtists.failure(error));
  }
}

export function* watchDeezerInitUserArtists() {
  yield takeLatest(deezerInitUserArtists.request, handleDeezerInitUserArtists);
}

function* handleDeezerFetchUserArtists(
  action: ActionType<typeof deezerFetchUserArtists.request>,
) {
  try {
    const data: DeezerUserArtistsFetchResponse = yield call(
      fetchDeezerUserArtistsRequest,
      action.payload,
    );
    yield put(deezerFetchUserArtists.success(data));
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.DEEZER_USER_ARTISTS_FETCH_FAILURE,
    });
    yield put(deezerFetchUserArtists.failure(error));
  }
}

export function* watchDeezerFetchUserArtists() {
  yield takeLatest(
    deezerFetchUserArtists.request,
    handleDeezerFetchUserArtists,
  );
}

function* handleDeezerDeleteUserArtists(
  action: ActionType<typeof deezerDeleteUserArtists.request>,
) {
  try {
    for (const artistId of action.payload) {
      yield call(deleteDeezerUserArtistsRequest, artistId);
      logEvent(analytics, FIREBASE_EVENT.DEEZER_USER_ARTIST_DELETE_SUCCESS, {
        deletedCount: 1,
        platform: Platform.Deezer,
      });
    }
    yield put(deezerDeleteUserArtists.success(action.payload));
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.DEEZER_USER_ARTIST_DELETE_FAILURE,
    });
    yield put(deezerDeleteUserArtists.failure(error));
  }
}

export function* watchDeezerDeleteUserArtists() {
  yield takeLatest(
    deezerDeleteUserArtists.request,
    handleDeezerDeleteUserArtists,
  );
}
