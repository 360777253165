import React, { FC, useCallback, useMemo } from 'react';
import { Box, List } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import {
  getDeezerUserArtistsDeleteLoading,
  getDeezerUserArtistsSelected,
  getDeezerUserArtistsVisible,
} from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { DeezerUserArtist } from './DeezerUserArtist';
import { useTranslation } from 'react-i18next';
import {
  deezerDeleteUserArtists,
  deezerDeselectUserAllArtists,
  deezerDeselectUserArtist,
  deezerSelectUserArtists,
} from '../../actions';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { DeezerArtist } from '../../../types';
import { LoadingState } from '../../../../types';
import { ListActions } from '../../../../components/ListActions';
import { DeezerEvidenceDialog } from '../../../components/DeezerEvidenceDialog';
import { useSetItem } from '../../../../hooks/useSetItem';
import { useSelectItems } from '../../../../hooks/useSelectItems';
import { UserArtistsCheckSuccess } from '../../../../components/UserArtists/UserArtistsCheckSuccess';
import { Description } from '../../../../components/Description';
import { DeleteLoading } from '../../../../components/DeleteLoading';

export const DeezerUserArtistsList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visibleUserArtists = useSelector(getDeezerUserArtistsVisible);
  const deleteLoading = useSelector(getDeezerUserArtistsDeleteLoading);
  const selectedUserArtists = useSelector(getDeezerUserArtistsSelected);
  const selectedUserArtistsSet = useMemo(() => {
    return new Set(selectedUserArtists.map(({ id }) => id));
  }, [selectedUserArtists]);

  const {
    item: detailsUserArtist,
    setItem: setDetailsUserArtist,
    unsetItem: unsetDetailsUserArtist,
  } = useSetItem<DeezerArtist>();

  const {
    items: deleteUserArtists,
    selectItems: setDeleteUserArtists,
    deselectItems: unsetDeleteUserArtists,
    selectItem: setDeleteUserArtist,
  } = useSelectItems<DeezerArtist>();

  const handleDelete = useCallback(() => {
    if (deleteUserArtists) {
      dispatch(
        deezerDeleteUserArtists.request(deleteUserArtists.map(({ id }) => id)),
      );
    }
    unsetDeleteUserArtists();
  }, [dispatch, deleteUserArtists, unsetDeleteUserArtists]);

  const handleSelect = useCallback(
    (artist) => {
      dispatch(deezerSelectUserArtists([artist]));
    },
    [dispatch],
  );

  const handleDeselect = useCallback(
    (artist) => {
      dispatch(deezerDeselectUserArtist(artist));
    },
    [dispatch],
  );

  const handleSelectAll = useCallback(() => {
    dispatch(deezerSelectUserArtists(visibleUserArtists));
  }, [dispatch, visibleUserArtists]);

  const handleDeselectAll = useCallback(() => {
    dispatch(deezerDeselectUserAllArtists());
  }, [dispatch]);

  const handleDeleteSelected = useCallback(() => {
    setDeleteUserArtists(selectedUserArtists);
  }, [setDeleteUserArtists, selectedUserArtists]);

  return (
    <>
      {visibleUserArtists.length > 0 ? (
        <Box>
          <Description>{t('deezer.userArtists.description')}</Description>
          {deleteLoading === LoadingState.Request ? (
            <DeleteLoading />
          ) : (
            <Box>
              <ListActions
                onDelete={handleDeleteSelected}
                onDeselectAll={handleDeselectAll}
                onSelectAll={handleSelectAll}
                selectedCount={selectedUserArtistsSet.size}
                visibleCount={visibleUserArtists.length}
              />
              <List>
                {visibleUserArtists.map((artist) => (
                  <DeezerUserArtist
                    onDetails={setDetailsUserArtist}
                    onDelete={setDeleteUserArtist}
                    key={artist.id}
                    artist={artist}
                    onSelect={handleSelect}
                    onDeselect={handleDeselect}
                    selected={selectedUserArtistsSet.has(artist.id)}
                  />
                ))}
              </List>
            </Box>
          )}
          <ConfirmationDialog
            open={Boolean(deleteUserArtists)}
            onConfirm={handleDelete}
            onClose={unsetDeleteUserArtists}
            title={t('delete.confirmation.title')}
            confirmText={t('delete.confirmation.confirm')}
            cancelText={t('delete.confirmation.cancel')}
          >
            <DialogContentText>
              {t('deezer.userLists.delete.confirmation.content')}
            </DialogContentText>
          </ConfirmationDialog>
          {detailsUserArtist && (
            <DeezerEvidenceDialog
              artist={detailsUserArtist}
              onClose={unsetDetailsUserArtist}
            />
          )}
        </Box>
      ) : (
        <UserArtistsCheckSuccess />
      )}
    </>
  );
};
