import { DeezerLibraryState } from './types';
import { LoadingState } from '../../types';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { getDeezerFilteredLibraryTracks } from './utils';

type Action = ActionType<typeof actions>;

const initialLibraryState: DeezerLibraryState = {
  tracks: [],
  loading: LoadingState.Idle,
  pagination: {
    offset: 0,
    total: 0,
  },
  selectedTracks: [],
  deleteLoading: LoadingState.Idle,
};

export const DeezerLibraryReducer = createReducer<DeezerLibraryState, Action>(
  initialLibraryState,
)
  .handleAction(actions.deezerFetchLibraryTracks.success, (state, action) => ({
    ...state,
    tracks: [
      ...state.tracks,
      ...getDeezerFilteredLibraryTracks(action.payload.tracks),
    ],
    pagination: {
      total: action.payload.total,
      offset: state.pagination.offset + action.payload.tracks.length,
      next: action.payload.next,
    },
  }))
  .handleAction(actions.deezerInitLibrary.request, (state) => ({
    ...state,
    loading: LoadingState.Request,
    tracks: initialLibraryState.tracks,
    pagination: initialLibraryState.pagination,
    error: undefined,
  }))
  .handleAction(actions.deezerInitLibrary.success, (state) => ({
    ...state,
    loading: LoadingState.Success,
  }))
  .handleAction(
    [
      actions.deezerInitLibrary.failure,
      actions.deezerFetchLibraryTracks.failure,
    ],
    (state, action) => ({
      ...state,
      loading: LoadingState.Failure,
      error: action.payload,
    }),
  )
  .handleAction(actions.deezerDeleteLibraryTracks.request, (state) => ({
    ...state,
    deleteLoading: LoadingState.Request,
  }))
  .handleAction(actions.deezerDeleteLibraryTracks.failure, (state) => ({
    ...state,
    deleteLoading: LoadingState.Failure,
  }))
  .handleAction(actions.deezerDeleteLibraryTracks.success, (state, action) => ({
    ...state,
    tracks: state.tracks.filter(({ id }) => !action.payload.includes(id)),
    selectedTracks: state.selectedTracks.filter(
      ({ id }) => !action.payload.includes(id),
    ),
    deleteLoading: LoadingState.Success,
  }))
  .handleAction(actions.deezerSelectLibraryTracks, (state, action) => ({
    ...state,
    selectedTracks: [...state.selectedTracks, ...action.payload],
  }))
  .handleAction(actions.deezerDeselectLibraryTrack, (state, action) => ({
    ...state,
    selectedTracks: state.selectedTracks.filter(
      (track) => track.id !== action.payload.id,
    ),
  }))
  .handleAction(actions.deezerDeselectLibraryAllTracks, (state) => ({
    ...state,
    selectedTracks: [],
  }));
