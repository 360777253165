import { all, call } from 'redux-saga/effects';
import {
  watchSettingsShowSilentArtists,
  watchSettingsHideSilentArtists,
} from './sagas';

export function* settingsSaga() {
  yield all([
    call(watchSettingsShowSilentArtists),
    call(watchSettingsHideSilentArtists),
  ]);
}
