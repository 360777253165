import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import { StorageService } from '../services/storageService';
import {
  SETTING_DISABLED,
  SETTING_ENABLED,
  SETTINGS_SILENT_VISIBLE_KEY,
} from '../constants';
import { settingsShowSilentArtists } from '../settings/actions';
import { getVisibleSilentArtistsConfigList } from '../utils';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  paper: {
    '&.MuiPaper-root': {
      backgroundColor: 'white',
      margin: 0,
      maxWidth: 500,
      width: '100%',
    },
  },
  content: {
    '&.MuiDialogContent-root': {
      color: 'black',
      padding: spacing(2),
    },
  },
  title: {
    '&.MuiDialogTitle-root': {
      color: 'black',
      padding: spacing(2),
    },
  },
  avatar: {
    '&.MuiAvatar-root': {
      width: 50,
      height: 50,
      margin: spacing(1),
    },
  },
}));

export const SilentArtistsConfirmationDialog: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(
    () => !StorageService.getItem(SETTINGS_SILENT_VISIBLE_KEY, ''),
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleCancel = useCallback(() => {
    StorageService.setItem(SETTINGS_SILENT_VISIBLE_KEY, SETTING_DISABLED);
    handleClose();
  }, [handleClose]);

  const handleConfirm = useCallback(() => {
    StorageService.setItem(SETTINGS_SILENT_VISIBLE_KEY, SETTING_ENABLED);
    dispatch(settingsShowSilentArtists());
    handleClose();
  }, [handleClose, dispatch]);

  const silentArtists = useMemo(() => {
    return getVisibleSilentArtistsConfigList().slice(0, 15);
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="silent-artists-dialog-title"
      aria-describedby="silent-artists-dialog-description"
      scroll="paper"
      PaperProps={{
        className: classes.paper,
      }}
      open={open}
    >
      <DialogTitle className={classes.title}>
        {t('silent.artists.dialog.title')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box>{t('silent.artists.dialog.description')}</Box>
        <Box
          flexWrap="wrap"
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={1}
        >
          {silentArtists.map(({ name, image }) => (
            <Tooltip key={name} title={name}>
              <Avatar
                className={classes.avatar}
                alt={name}
                src={image as string}
              />
            </Tooltip>
          ))}
          <Typography>{t('silent.artists.dialog.others')}...</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          {t('silent.artists.dialog.cancel')}
        </Button>
        <Button onClick={handleConfirm}>
          {t('silent.artists.dialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
