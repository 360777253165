import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  SpotifyPlaylist,
  SpotifyPlaylistsFetchRequest,
  SpotifyPlaylistsFetchResponse,
  SpotifyPlaylistTracksFetchRequest,
  SpotifyPlaylistTracksFetchResponse,
} from './types';
import { SpotifyTrack } from '../types';

export const spotifyInitPlaylists = createAsyncAction(
  'spotify/playlists/init/REQUEST',
  'spotify/playlists/init/SUCCESS',
  'spotify/playlists/init/FAILURE',
)<void, void, unknown>();

export const spotifyFetchPlaylists = createAsyncAction(
  'spotify/playlists/fetch/REQUEST',
  'spotify/playlists/fetch/SUCCESS',
  'spotify/playlists/fetch/FAILURE',
)<SpotifyPlaylistsFetchRequest, SpotifyPlaylistsFetchResponse, unknown>();

export const spotifyInitPlaylist = createAsyncAction(
  'spotify/playlist/init/REQUEST',
  'spotify/playlist/init/SUCCESS',
  'spotify/playlist/init/FAILURE',
)<
  SpotifyPlaylist['id'],
  SpotifyPlaylist['id'],
  { playlistId: SpotifyPlaylist['id']; error: unknown }
>();

export const spotifyFetchPlaylistTracks = createAsyncAction(
  'spotify/playlist/tracks/fetch/REQUEST',
  'spotify/playlist/tracks/fetch/SUCCESS',
  'spotify/playlist/tracks/fetch/FAILURE',
)<
  SpotifyPlaylistTracksFetchRequest,
  SpotifyPlaylistTracksFetchResponse & { playlistId: SpotifyPlaylist['id'] },
  { playlistId: SpotifyPlaylist['id']; error: unknown }
>();

export const spotifyDeletePlaylistTracks = createAsyncAction(
  'spotify/playlist/tracks/delete/REQUEST',
  'spotify/playlist/tracks/delete/SUCCESS',
  'spotify/playlist/tracks/delete/FAILURE',
)<
  { playlistId: SpotifyPlaylist['id']; tracks: SpotifyTrack[] },
  { playlistId: SpotifyPlaylist['id']; ids: SpotifyTrack['id'][] },
  { playlistId: SpotifyPlaylist['id']; error: unknown }
>();

export const spotifySelectPlaylistTracks = createAction(
  'spotify/playlist/tracks/select',
)<{ playlistId: SpotifyPlaylist['id']; tracks: SpotifyTrack[] }>();
export const spotifyDeselectPlaylistTrack = createAction(
  'spotify/playlist/tracks/deselect',
)<{ playlistId: SpotifyPlaylist['id']; track: SpotifyTrack }>();
export const spotifyDeselectPlaylistAllTracks = createAction(
  'spotify/playlist/tracks/deselect/all',
)<{ playlistId: SpotifyPlaylist['id'] }>();
