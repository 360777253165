import React, { useCallback } from 'react';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { ReactComponent as DeezerSvg } from '../svg/deezer.svg';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../routes';

export const DeezerButton = () => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate(AppRoute.deezer);
  }, [navigate]);

  return (
    <Tooltip title="Deezer">
      <IconButton onClick={handleNavigate}>
        <SvgIcon component={DeezerSvg} inheritViewBox />
      </IconButton>
    </Tooltip>
  );
};
