import Cookies from 'js-cookie';
import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { SpotifyAuth } from 'react-spotify-auth';
import { SPOTIFY_AUTH_TOKEN_KEY } from '../constants';
import { ONE_HOUR_MS } from '../../constants';
import { AppRoute } from '../../routes';

const SPOTIFY_SCOPES = [
  'user-library-modify',
  'user-library-read',
  'user-follow-modify',
  'user-follow-read',
  'playlist-read-private',
  'playlist-modify-private',
  'playlist-modify-public',
];

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    border: 'none',
    cursor: 'pointer',
    transition: 'transform 1.2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    color: 'black',
    width: '35%',
    maxWidth: 150,
    fontSize: '1rem',
    fontFamily: 'font-family: Roboto, Helvetica, Arial, sans-serif',

    '& span': {
      width: '150%',
    },
  },
  logo: {
    width: '100%',
    marginBottom: theme.spacing(1),

    '& path': {
      fill: '#1DB954',
    },
  },
}));

export interface SpotifyLoginButtonProps {
  onSuccess: () => void;
}

export const SpotifyLoginButton: FC<SpotifyLoginButtonProps> = (props) => {
  const { onSuccess } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (Cookies.get(SPOTIFY_AUTH_TOKEN_KEY)) {
      onSuccess();
    }
  }, [onSuccess]);

  const setToken = useCallback(
    (token: string) => {
      Cookies.set(SPOTIFY_AUTH_TOKEN_KEY, token, {
        expires: new Date(new Date().getTime() + ONE_HOUR_MS),
      });
      onSuccess();
      window.location.href = AppRoute.spotify;
    },
    [onSuccess],
  );

  return (
    <SpotifyAuth
      btnClassName={classes.button}
      logoClassName={classes.logo}
      redirectUri={process.env.REACT_APP_SPOTIFY_REDIRECT_URI}
      clientID={process.env.REACT_APP_SPOTIFY_CLIENT_ID}
      scopes={SPOTIFY_SCOPES}
      onAccessToken={setToken}
      title={t('spotify.login.button.title')}
    />
  );
};
