import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { spotifyInitLibrary } from '../../actions';
import {
  getSpotifyLibraryLoading,
  getSpotifyLibraryPagination,
} from '../../selectors';
import { SpotifyLibraryList } from './SpotifyLibraryList';
import { SpotifyLibrarySettings } from './SpotifyLibrarySettings';
import { PaginatedContent } from '../../../../components/PaginatedContent';

export const SpotifyLibrary: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getSpotifyLibraryLoading);
  const pagination = useSelector(getSpotifyLibraryPagination);

  const handleInit = useCallback(() => {
    dispatch(spotifyInitLibrary.request());
  }, [dispatch]);

  useEffect(() => handleInit(), [handleInit]);

  return (
    <PaginatedContent
      pagination={pagination}
      loading={loading}
      onRetry={handleInit}
    >
      <SpotifyLibrarySettings />
      <SpotifyLibraryList />
    </PaginatedContent>
  );
};
