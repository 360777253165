import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { PlatformOptionSpotify } from './PlatformOptionSpotify';
import { PlatformOptionDeezer } from './PlatformOptionDeezer';
import { makeStyles } from '@mui/styles';
import { PlatformOptionYoutube } from './PlatformOptionYoutube';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 470,
  },
}));

export const PlatformSelect: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.wrapper}>
      <Typography
        fontSize="large"
        fontWeight="bold"
        textTransform="uppercase"
        mb={2}
      >
        {t('platform.select.description')}
      </Typography>
      <PlatformOptionSpotify />
      <PlatformOptionDeezer />
      <PlatformOptionYoutube />
    </Box>
  );
};
