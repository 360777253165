import React, { FC, useMemo } from 'react';
import {
  EvidenceDialog,
  EvidenceDialogEntity,
} from '../../components/EvidenceDialog';
import { SpotifyArtist, SpotifyTrack } from '../types';
import { SPOTIFY_ARTISTS_CONFIG } from '../constants';

interface SpotifyEvidenceDialogProps {
  track?: SpotifyTrack;
  artists: SpotifyArtist[];
  onClose: () => void;
}

export const SpotifyEvidenceDialog: FC<SpotifyEvidenceDialogProps> = (
  props,
) => {
  const { track, onClose, artists } = props;

  const evidenceArtists = useMemo(() => {
    const artistsMap: Map<string, EvidenceDialogEntity> = new Map();

    artists.forEach(({ id, name }) => {
      if (id in SPOTIFY_ARTISTS_CONFIG) {
        const configName = SPOTIFY_ARTISTS_CONFIG[id].name;
        artistsMap.set(configName, {
          id,
          name: [artistsMap.get(configName)?.name, name]
            .filter(Boolean)
            .join(' / '),
          silent: SPOTIFY_ARTISTS_CONFIG[id].silent,
          evidence: SPOTIFY_ARTISTS_CONFIG[id].evidence,
        });
      }
    });

    return Array.from(artistsMap.values());
  }, [artists]);

  return (
    <EvidenceDialog
      trackName={track?.name}
      entities={evidenceArtists}
      onClose={onClose}
      open
    />
  );
};
