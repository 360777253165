import createSagaMiddleware from 'redux-saga';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { UserReducer } from '../user/UserReducer';
import { rootSaga } from './sagas';
import { SpotifyReducer } from '../spotify/store/SpotifyReducer';
import { SettingsReducer } from '../settings/SettingsReducer';
import { DeezerReducer } from '../deezer/store/DeezerReducer';
import { YoutubeReducer } from '../youtube/store/YoutubeReducer';

const sagaMiddleware = createSagaMiddleware();

const enhancer = composeWithDevTools(applyMiddleware(sagaMiddleware));

export const RootReducer = combineReducers({
  spotify: SpotifyReducer,
  deezer: DeezerReducer,
  user: UserReducer,
  settings: SettingsReducer,
  youtube: YoutubeReducer,
});

export type RootState = ReturnType<typeof RootReducer>;

export const store = createStore(RootReducer, enhancer);

sagaMiddleware.run(rootSaga);
