import React, { FC } from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as CheckmarkIcon } from '../svg/checkmark.svg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  icon: {
    '&.MuiSvgIcon-root': {
      width: '100%',
      height: 'auto',
    },
  },
}));

export const Checkmark: FC = () => {
  const classes = useStyles();

  return (
    <SvgIcon
      className={classes.icon}
      fontSize="large"
      component={CheckmarkIcon}
      inheritViewBox
    />
  );
};
