import { DeezerTrack } from '../types';
import { DEEZER_ARTISTS_CONFIG } from '../constants';

export const getDeezerFilteredLibraryTracks = (
  tracks: DeezerTrack[],
): DeezerTrack[] => {
  return tracks.filter((track) => track.artist.id in DEEZER_ARTISTS_CONFIG);
};

export const isDeezerSilentTrack = (track: DeezerTrack): boolean => {
  return DEEZER_ARTISTS_CONFIG[track.artist.id].silent;
};

export const getDeezerNonSilentLibraryTracks = (
  tracks: DeezerTrack[],
): DeezerTrack[] => {
  return tracks.filter((track) => !isDeezerSilentTrack(track));
};
