import { createAction, createAsyncAction } from 'typesafe-actions';
import { DeezerTrack } from '../types';
import {
  DeezerLibraryFetchRequest,
  DeezerLibraryTracksFetchResponse,
} from './types';

export const deezerInitLibrary = createAsyncAction(
  'deezer/library/init/REQUEST',
  'deezer/library/init/SUCCESS',
  'deezer/library/init/FAILURE',
)<void, void, unknown>();

export const deezerFetchLibraryTracks = createAsyncAction(
  'deezer/library/tracks/fetch/REQUEST',
  'deezer/library/tracks/fetch/SUCCESS',
  'deezer/library/tracks/fetch/FAILURE',
)<DeezerLibraryFetchRequest, DeezerLibraryTracksFetchResponse, unknown>();

export const deezerDeleteLibraryTracks = createAsyncAction(
  'deezer/library/tracks/delete/REQUEST',
  'deezer/library/tracks/delete/SUCCESS',
  'deezer/library/tracks/delete/FAILURE',
)<number[], number[], unknown>();

export const deezerSelectLibraryTracks = createAction(
  'deezer/library/tracks/select',
)<DeezerTrack[]>();
export const deezerDeselectLibraryTrack = createAction(
  'deezer/library/tracks/deselect',
)<DeezerTrack>();
export const deezerDeselectLibraryAllTracks = createAction(
  'deezer/library/tracks/deselect/all',
)();
