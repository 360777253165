import { createSelector } from 'reselect';
import { getState } from '../../store/selectors';

const getYoutube = createSelector(getState, ({ youtube }) => youtube);
export const getYoutubeDialogs = createSelector(
  getYoutube,
  ({ dialogs }) => dialogs,
);
export const getYoutubeQuotaLimitDialogOpen = createSelector(
  getYoutubeDialogs,
  ({ quotaLimitDialog }) => quotaLimitDialog,
);
