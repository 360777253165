import React, { FC, memo } from 'react';
import { LibraryRemovableEntity } from '../../../../components/Library/LibraryRemovableEntity';
import { YoutubeSubscription } from '../../types';
import { YOUTUBE_CHANNELS_CONFIG } from '../../../constants';

interface YoutubeSubscriptionItemProps {
  subscription: YoutubeSubscription;
  onDelete: (subscription: YoutubeSubscription) => void;
  onSelect: (subscription: YoutubeSubscription) => void;
  onDeselect: (subscription: YoutubeSubscription) => void;
  onDetails: (subscription: YoutubeSubscription) => void;
  selected: boolean;
}

export const YoutubeSubscriptionItem: FC<YoutubeSubscriptionItemProps> = memo(
  (props) => {
    const {
      subscription,
      onDelete,
      selected,
      onSelect,
      onDeselect,
      onDetails,
    } = props;
    const { snippet } = subscription;

    const configOwner =
      YOUTUBE_CHANNELS_CONFIG[snippet.resourceId.channelId].name;

    return (
      <LibraryRemovableEntity
        entity={subscription}
        coverDescription={snippet.channelTitle}
        owner={configOwner === snippet.title ? '' : configOwner}
        title={snippet.title}
        selected={selected}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onDelete={onDelete}
        onDetails={onDetails}
        coverUrl={snippet.thumbnails['default'].url}
      />
    );
  },
);

YoutubeSubscriptionItem.displayName = 'YoutubeSubscriptionItem';
