const YOUTUBE_SCOPES = ['https://www.googleapis.com/auth/youtube'];

export const getYoutubeAuthLink = () => {
  const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');
  url.searchParams.set(
    'client_id',
    process.env.REACT_APP_YOUTUBE_CLIENT_ID || '',
  );
  url.searchParams.set(
    'redirect_uri',
    process.env.REACT_APP_YOUTUBE_REDIRECT_URI || '',
  );
  url.searchParams.set('response_type', 'token');
  url.searchParams.set('scope', YOUTUBE_SCOPES.join(' '));
  return url;
};
