import { IconButton, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import React, { FC } from 'react';

interface InstagramLinkProps {
  text?: string;
}

export const InstagramLink: FC<InstagramLinkProps> = (props) => (
  <Typography
    component="a"
    href="https://www.instagram.com/devalexua/"
    target="_blank"
    rel="noreferrer"
  >
    <IconButton color="primary" aria-label="upload picture" component="span">
      <InstagramIcon />
    </IconButton>
    {props.text}
  </Typography>
);
