import { NotifiableError } from '@bugsnag/core/types/common';
import {
  youtubeFetchSubscriptions,
  youtubeInitSubscriptions,
  youtubeDeleteSubscriptions,
} from '../actions';
import { youtubeQuotaLimitDialogShow } from '../../dialogs/actions';
import { logEvent } from 'firebase/analytics';
import { ActionType } from 'typesafe-actions';
import { takeLatest, take, call, put, select } from 'redux-saga/effects';
import {
  deleteYoutubeSubscriptionRequest,
  fetchYoutubeSubscriptionsRequest,
} from '../api';
import { YoutubePagination, YoutubeSubscriptionsFetchResponse } from '../types';
import { getYoutubeSubscriptionsPagination } from '../selectors';
import { analytics } from '../../../analytics';
import { FIREBASE_EVENT } from '../../../analytics/events';
import { handleError } from '../../../errors';
import { Platform } from '../../../types';

function* handleYoutubeInitSubscriptions() {
  let pagination: YoutubePagination = yield select(
    getYoutubeSubscriptionsPagination,
  );
  try {
    do {
      yield put(
        youtubeFetchSubscriptions.request({
          pageToken: pagination.nextPageToken,
        }),
      );
      yield take(youtubeFetchSubscriptions.success);
      pagination = yield select(getYoutubeSubscriptionsPagination);
    } while (pagination.nextPageToken);
    yield put(youtubeInitSubscriptions.success());
    logEvent(analytics, FIREBASE_EVENT.YOUTUBE_SUBSCRIPTIONS_INIT_SUCCESS);
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.YOUTUBE_SUBSCRIPTIONS_INIT_FAILURE,
    });
    yield put(youtubeInitSubscriptions.failure(error));
  }
}

export function* watchYoutubeInitSubscriptions() {
  yield takeLatest(
    youtubeInitSubscriptions.request,
    handleYoutubeInitSubscriptions,
  );
}

function* handleYoutubeFetchSubscriptions(
  action: ActionType<typeof youtubeFetchSubscriptions.request>,
) {
  try {
    const data: YoutubeSubscriptionsFetchResponse = yield call(
      fetchYoutubeSubscriptionsRequest,
      action.payload,
    );
    yield put(youtubeFetchSubscriptions.success(data));
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.YOUTUBE_SUBSCRIPTIONS_FETCH_FAILURE,
    });
    yield put(youtubeFetchSubscriptions.failure(error));
  }
}

export function* watchYoutubeFetchSubscriptions() {
  yield takeLatest(
    youtubeFetchSubscriptions.request,
    handleYoutubeFetchSubscriptions,
  );
}

function* handleYoutubeDeleteSubscriptions(
  action: ActionType<typeof youtubeDeleteSubscriptions.request>,
) {
  try {
    for (const subscription of action.payload) {
      yield call(deleteYoutubeSubscriptionRequest, subscription.id);
      logEvent(analytics, FIREBASE_EVENT.YOUTUBE_SUBSCRIPTION_DELETE_SUCCESS, {
        deletedCount: 1,
        platform: Platform.Youtube,
        channelId: subscription.snippet.resourceId.channelId,
      });
    }
    yield put(youtubeDeleteSubscriptions.success(action.payload));
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.YOUTUBE_SUBSCRIPTION_DELETE_FAILURE,
    });
    yield put(youtubeDeleteSubscriptions.failure(error));
  }
}

function* handleYoutubeRequestFailures(
  action: ActionType<
    | typeof youtubeDeleteSubscriptions.failure
    | typeof youtubeFetchSubscriptions.failure
  >,
) {
  const errorResponseErrors = action.payload?.response?.data?.error?.errors;

  if (errorResponseErrors) {
    for (const error of errorResponseErrors) {
      if (error.domain === 'youtube.quota') {
        yield put(youtubeQuotaLimitDialogShow());
        logEvent(analytics, FIREBASE_EVENT.YOUTUBE_DIALOG_QUOTA_LIMIT_SHOW);
      }
    }
  }
}

export function* watchYoutubeRequestFailures() {
  yield takeLatest(
    [youtubeDeleteSubscriptions.failure, youtubeFetchSubscriptions.failure],
    handleYoutubeRequestFailures,
  );
}

export function* watchYoutubeDeleteSubscriptions() {
  yield takeLatest(
    youtubeDeleteSubscriptions.request,
    handleYoutubeDeleteSubscriptions,
  );
}
