import { makeStyles } from '@mui/styles';
import React, { FC, useEffect } from 'react';
import { Box, Button, Link, SvgIcon, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { YOUTUBE_AUTH_TOKEN_KEY } from '../constants';
import { ReactComponent as GoogleSvg } from '../../svg/google.svg';
import { getYoutubeAuthLink } from '../utils';
import { useLocation } from 'react-router-dom';
import { AppRoute } from '../../routes';

export interface YoutubeLoginButtonProps {
  onSuccess: () => void;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  button: {
    '&.MuiButton-root': {
      display: 'flex',
      alignItems: 'center',
      paddingTop: spacing(0.25),
      paddingLeft: spacing(0.25),
      paddingBottom: spacing(0.25),
      boxShadow:
        'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',

      '&:hover': {
        background: '#4285F4',

        '& .MuiTypography-root': {
          color: 'white',
        },
      },
    },
  },
  text: {
    '&.MuiTypography-root': {
      fontSize: 14,
      textTransform: 'uppercase',
      color: 'rgba(0,0,0, 0.54)',
      fontFamily: 'Roboto-Medium',
      fontWeight: 500,
    },
  },
  iconWrapper: {
    background: 'white',
    padding: spacing(1),
    lineHeight: 1,
    marginRight: spacing(1),

    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px',
    },
  },
}));

export const YoutubeLoginButton: FC<YoutubeLoginButtonProps> = (props) => {
  const { onSuccess } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (Cookies.get(YOUTUBE_AUTH_TOKEN_KEY)) {
      onSuccess();
    }
  }, [onSuccess]);

  useEffect(() => {
    const search = location.hash.replace('#', '?');
    const searchParams = new URLSearchParams(search);

    const token = searchParams.get('access_token');
    const expires = searchParams.get('expires_in');

    if (token && expires) {
      Cookies.set(YOUTUBE_AUTH_TOKEN_KEY, token, {
        expires: new Date(new Date().getTime() + parseInt(expires) * 1000),
      });
      onSuccess();
      window.location.href = AppRoute.youtube;
    }
  }, [location, onSuccess]);

  return (
    <Link underline="none" href={getYoutubeAuthLink().href}>
      <Button className={classes.button}>
        <Box className={classes.iconWrapper}>
          <SvgIcon component={GoogleSvg} inheritViewBox />
        </Box>
        <Typography className={classes.text}>
          {t('youtube.login.button.title')}
        </Typography>
      </Button>
    </Link>
  );
};
