import { Tab, Tabs, Theme, Badge } from '@mui/material';
import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';

interface ContentTab<T> {
  label: string;
  value: T;
  badge?: number | string;
}

interface ContentTabsProps<T> {
  value: T;
  onChange: (value: T) => void;
  tabs: Array<ContentTab<T>>;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  tabs: {
    marginBottom: spacing(2),

    '& .MuiTab-root': {
      color: 'gray',
    },
  },
  badge: {
    paddingTop: spacing(1),
    paddingRight: spacing(1),
  },
}));

export const ContentTabs = <T extends string>(props: ContentTabsProps<T>) => {
  const { value, onChange, tabs } = props;

  const classes = useStyles();

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: T) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <Tabs
      className={classes.tabs}
      value={value}
      onChange={handleChange}
      textColor="primary"
      indicatorColor="primary"
    >
      {tabs.map(({ label, value, badge }) => (
        <Tab
          key={label}
          label={
            <Badge badgeContent={badge} className={classes.badge} color="error">
              {label}
            </Badge>
          }
          value={value}
        />
      ))}
    </Tabs>
  );
};
