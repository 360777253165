import React, { FC } from 'react';
import { Box, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

interface ListActionsProps {
  selectedCount: number;
  visibleCount: number;
  onSelectAll: () => void;
  onDeselectAll: () => void;
  onDelete: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&.MuiButton-root': {
      marginLeft: theme.spacing(1),
    },

    '&.MuiButton-root.Mui-disabled': {
      color: 'rgba(150,150,150, 0.8)',
      border: '1px solid rgba(150,150,150, 0.8)',
    },
  },
}));

export const ListActions: FC<ListActionsProps> = (props) => {
  const { selectedCount, visibleCount, onSelectAll, onDeselectAll, onDelete } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="flex-end" pt={2} pb={2}>
      {selectedCount === 0 ? (
        <Button
          className={classes.button}
          onClick={onSelectAll}
          color="error"
          variant="outlined"
        >
          {t('actions.select.all', { total: visibleCount })}
        </Button>
      ) : null}
      {selectedCount > 0 ? (
        <>
          <Button
            className={classes.button}
            onClick={onDeselectAll}
            color="error"
            variant="outlined"
          >
            {t('actions.deselect.all')}
          </Button>
          <Button
            className={classes.button}
            onClick={onDelete}
            color="error"
            variant="outlined"
          >
            {t('actions.delete.all', { total: selectedCount })}
          </Button>
        </>
      ) : null}
    </Box>
  );
};
