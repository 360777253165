import React, { FC } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { Wave } from './Wave';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Email } from './Email';
import { Link } from 'react-router-dom';
import { AppRoute } from '../routes';

const useStyles = makeStyles((theme: Theme) => ({
  waveWrapper: {
    transform: 'rotate(180deg)',
  },
  content: {
    background: 'rgba(39,39,39)',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.light,
  },
  contact: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.primary.light,
    padding: theme.spacing(3),
  },
}));

export const Footer: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Box className={classes.waveWrapper}>
        <Wave />
      </Box>
      <Box className={classes.content}>
        <Box className={classes.contact}>
          <Link to={AppRoute.privacy} className={classes.link}>
            <Typography>{t('privacy.title')}</Typography>
          </Link>
          <Link to={AppRoute.terms} className={classes.link}>
            <Typography>{t('terms.title')}</Typography>
          </Link>
          <Typography>{t('footer.contact')}</Typography>
          <Email />
        </Box>
      </Box>
    </Box>
  );
};
