import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  SpotifyFollowedArtistsFetchRequest,
  SpotifyFollowedArtistsFetchResponse,
} from './types';
import { SpotifyArtist } from '../types';

export const spotifyInitFollowedArtists = createAsyncAction(
  'spotify/followed/artists/init/REQUEST',
  'spotify/followed/artists/init/SUCCESS',
  'spotify/followed/artists/init/FAILURE',
)<void, void, unknown>();

export const spotifyFetchFollowedArtists = createAsyncAction(
  'spotify/followed/artists/fetch/REQUEST',
  'spotify/followed/artists/fetch/SUCCESS',
  'spotify/followed/artists/fetch/FAILURE',
)<
  SpotifyFollowedArtistsFetchRequest,
  SpotifyFollowedArtistsFetchResponse,
  unknown
>();

export const spotifyDeleteFollowedArtists = createAsyncAction(
  'spotify/followed/artists/delete/REQUEST',
  'spotify/followed/artists/delete/SUCCESS',
  'spotify/followed/artists/delete/FAILURE',
)<string[], string[], unknown>();

export const spotifySelectFollowedArtists = createAction(
  'spotify/followed/artists/select',
)<SpotifyArtist[]>();
export const spotifyDeselectFollowedArtists = createAction(
  'spotify/followed/artists/deselect',
)<SpotifyArtist>();
export const spotifyDeselectFollowedArtistsAll = createAction(
  'spotify/followed/artists/deselect/all',
)();
