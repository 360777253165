import React, { FC, useCallback } from 'react';
import { MainPage } from './MainPage';
import { userLogin } from '../user/actions';
import { Platform } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLoggedPlatform } from '../user/selectors';
import { DeezerLoginButton } from '../deezer/components/DeezerLoginButton';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { DeezerContent } from '../deezer/components/DeezerContent';

export const DeezerPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedPlatform = useSelector(getUserLoggedPlatform);

  const handleLoginSuccess = useCallback(() => {
    dispatch(userLogin(Platform.Deezer));
  }, [dispatch]);

  return (
    <MainPage title={t('deezer.title')} description={t('deezer.description')}>
      {loggedPlatform === Platform.Deezer ? (
        <DeezerContent />
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <DeezerLoginButton onSuccess={handleLoginSuccess} />
          <Typography m={2} textAlign="center" color="red" lineHeight="1">
            {t('deezer.login.hint')}
          </Typography>
        </Box>
      )}
    </MainPage>
  );
};
