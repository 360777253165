import Bugsnag, { NotifiableError } from '@bugsnag/js';
import { analytics } from './analytics';
import { logEvent } from 'firebase/analytics';
import { FIREBASE_EVENT } from './analytics/events';

interface ErrorHandlerOptions {
  firebaseEvent?: FIREBASE_EVENT;
}

export const handleError = (
  error: NotifiableError,
  options?: ErrorHandlerOptions,
) => {
  if (options?.firebaseEvent) {
    logEvent(analytics, FIREBASE_EVENT.LIBRARY_INIT_FAILURE, { error });
  }
  Bugsnag.notify(error);
};
