import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  SpotifyLibraryFetchRequest,
  SpotifyLibraryFetchResponse,
} from './types';
import { SpotifyTrack } from '../types';

export const spotifyInitLibrary = createAsyncAction(
  'spotify/library/init/REQUEST',
  'spotify/library/init/SUCCESS',
  'spotify/library/init/FAILURE',
)<void, void, unknown>();

export const spotifyFetchLibraryTracks = createAsyncAction(
  'spotify/library/tracks/fetch/REQUEST',
  'spotify/library/tracks/fetch/SUCCESS',
  'spotify/library/tracks/fetch/FAILURE',
)<SpotifyLibraryFetchRequest, SpotifyLibraryFetchResponse, unknown>();

export const spotifyDeleteLibraryTracks = createAsyncAction(
  'spotify/library/tracks/delete/REQUEST',
  'spotify/library/tracks/delete/SUCCESS',
  'spotify/library/tracks/delete/FAILURE',
)<string[], string[], unknown>();

export const spotifySelectLibraryTracks = createAction(
  'spotify/library/tracks/select',
)<SpotifyTrack[]>();
export const spotifyDeselectLibraryTrack = createAction(
  'spotify/library/tracks/deselect',
)<SpotifyTrack>();
export const spotifyDeselectLibraryAllTracks = createAction(
  'spotify/library/tracks/deselect/all',
)();
