import React, { FC } from 'react';
import { Settings } from '../../../../components/Settings';
import { useSelector } from 'react-redux';
import { getYoutubeSubscriptionsSilent } from '../../selectors';

export const YoutubeSubscriptionsSettings: FC = () => {
  const silentSubscriptions = useSelector(getYoutubeSubscriptionsSilent);

  return <Settings silentTotal={silentSubscriptions.length} />;
};
