import React, { FC } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { Email } from './Email';
import { InstagramLink } from './InstagramLink';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

interface ErrorMessageProps {
  message: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    '&.MuiTypography-root': {
      lineHeight: 1,
    },
  },
  errorWrapper: {
    border: '1px solid gray',
    padding: theme.spacing(1),
    wordBreak: 'break-word',
  },
  errorMessage: {
    '&.MuiTypography-root': {
      lineHeight: 1,
      fontSize: 13,
    },
  },
}));

export const ErrorMessage: FC<ErrorMessageProps> = (props) => {
  const { message } = props;
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.description}>
        {t('error.description')}
      </Typography>
      <ul>
        <li>
          <Email />
        </li>
        <li>
          <InstagramLink text="Instagram" />
        </li>
      </ul>
      <Box className={classes.errorWrapper}>
        <Box mb={1}>
          <Typography>{t('error')}:</Typography>
        </Box>
        <Box>
          <Typography className={classes.errorMessage}>{message}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
