import React, { FC, useCallback } from 'react';
import { SpotifyPlaylist } from '../../types';
import { SpotifyTracksList } from '../../../components/SpotifyTracksList';
import { useDispatch, useSelector } from 'react-redux';
import { getSpotifyPlaylistStateById } from '../../selectors';
import { Dialog, DialogContent, DialogTitle, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';
import {
  spotifyDeletePlaylistTracks,
  spotifyDeselectPlaylistAllTracks,
  spotifyDeselectPlaylistTrack,
  spotifyInitPlaylist,
  spotifySelectPlaylistTracks,
} from '../../actions';
import { CheckSuccess } from '../../../../components/CheckSuccess';
import { SpotifyTrack } from '../../../types';
import { isLoadingSuccess } from '../../../../utils';
import { Loader } from '../../../../components/Loader';

interface SpotifyPlaylistTracksDialogProps {
  playlist: SpotifyPlaylist;
  onClose: () => void;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  paper: {
    '&.MuiPaper-root': {
      backgroundColor: 'white',
      margin: 0,
      maxWidth: 550,
      width: '100%',
    },
  },
  content: {
    '&.MuiDialogContent-root': {
      color: 'black',
      padding: spacing(2),
    },
  },
  title: {
    '&.MuiDialogTitle-root': {
      color: 'black',
      padding: spacing(2),
    },
  },
}));

export const SpotifyPlaylistTracksDialog: FC<
  SpotifyPlaylistTracksDialogProps
> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { onClose, playlist } = props;
  const classes = useStyles();

  const { deleteLoading, visibleTracks, selectedTracks, loading } = useSelector(
    getSpotifyPlaylistStateById(playlist.id),
  );

  const handleDelete = useCallback(
    (tracks: SpotifyTrack[]) => {
      dispatch(
        spotifyDeletePlaylistTracks.request({
          playlistId: playlist.id,
          tracks,
        }),
      );
    },
    [dispatch, playlist],
  );

  const handleSelect = useCallback(
    (tracks) => {
      dispatch(
        spotifySelectPlaylistTracks({ playlistId: playlist.id, tracks }),
      );
    },
    [dispatch, playlist],
  );

  const handleDeselect = useCallback(
    (track) => {
      dispatch(
        spotifyDeselectPlaylistTrack({ playlistId: playlist.id, track }),
      );
    },
    [dispatch, playlist],
  );

  const handleDeselectAll = useCallback(() => {
    dispatch(spotifyDeselectPlaylistAllTracks({ playlistId: playlist.id }));
  }, [dispatch, playlist]);

  const handleInit = useCallback(() => {
    dispatch(spotifyInitPlaylist.request(playlist.id));
  }, [dispatch]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="evidence-dialog-title"
      aria-describedby="evidence-dialog-description"
      scroll="paper"
      PaperProps={{
        className: classes.paper,
      }}
      open
    >
      <DialogTitle className={classes.title}>{playlist.name}</DialogTitle>
      <DialogContent className={classes.content}>
        {!isLoadingSuccess(loading) ? (
          <Loader loading={loading} retry={handleInit} />
        ) : (
          <SpotifyTracksList
            visibleTracks={visibleTracks}
            selectedTracks={selectedTracks}
            deleteLoading={deleteLoading}
            onDelete={handleDelete}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            onDeselectAll={handleDeselectAll}
            deleteConfirmation={t(
              'spotify.playlist.tracks.delete.confirmation.content',
              { name: playlist.name },
            )}
            description={t('spotify.playlist.tracks.description', {
              name: playlist.name,
            })}
            EmptyListComponent={
              <CheckSuccess
                text={t('playlist.tracks.list.success', {
                  name: playlist.name,
                })}
              />
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};
