import React, { FC, useCallback } from 'react';
import { MainPage } from './MainPage';
import { userLogin } from '../user/actions';
import { Platform } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLoggedPlatform } from '../user/selectors';
import { useTranslation } from 'react-i18next';
import { YoutubeLoginButton } from '../youtube/components/YoutubeLoginButton';
import { YoutubeContent } from '../youtube/components/YoutubeContent';

export const YoutubePage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedPlatform = useSelector(getUserLoggedPlatform);

  const handleYoutubeLoginSuccess = useCallback(() => {
    dispatch(userLogin(Platform.Youtube));
  }, [dispatch]);

  return (
    <MainPage title={t('youtube.title')} description={t('youtube.description')}>
      {loggedPlatform === Platform.Youtube ? (
        <YoutubeContent />
      ) : (
        <YoutubeLoginButton onSuccess={handleYoutubeLoginSuccess} />
      )}
    </MainPage>
  );
};
