import React, { FC } from 'react';
import { EvidenceDialog } from '../../../../components/EvidenceDialog';
import { YOUTUBE_CHANNELS_CONFIG } from '../../../constants';
import { YoutubeSubscription } from '../../types';
import { EntityType } from '../../../../types';

interface YoutubeSubscriptionEvidenceDialogProps {
  subscription: YoutubeSubscription;
  onClose: () => void;
}

export const YoutubeSubscriptionEvidenceDialog: FC<
  YoutubeSubscriptionEvidenceDialogProps
> = (props) => {
  const {
    subscription: { snippet },
    onClose,
  } = props;

  const {
    resourceId: { channelId },
    title,
  } = snippet;

  return (
    <EvidenceDialog
      entities={[
        {
          id: channelId,
          name: title,
          silent: YOUTUBE_CHANNELS_CONFIG[channelId].silent,
          evidence: YOUTUBE_CHANNELS_CONFIG[channelId].evidence,
          owner: YOUTUBE_CHANNELS_CONFIG[channelId].name,
        },
      ]}
      onClose={onClose}
      entityType={EntityType.Channel}
      open
    />
  );
};
