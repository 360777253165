import React, { FC, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSpotifyFollowedArtistsLoading,
  getSpotifyFollowedArtistsPagination,
} from '../../selectors';
import { PaginatedContent } from '../../../../components/PaginatedContent';
import { spotifyInitFollowedArtists } from '../../actions';
import { SpotifyFollowedArtistsSettings } from './SpotifyFollowedArtistsSettings';
import { SpotifyFollowedArtistsList } from './SpotifyFollowedArtistsList';

export const SpotifyFollowedArtists: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getSpotifyFollowedArtistsLoading);
  const pagination = useSelector(getSpotifyFollowedArtistsPagination);

  const handleInit = useCallback(() => {
    dispatch(spotifyInitFollowedArtists.request());
  }, [dispatch]);

  useEffect(() => handleInit(), [handleInit]);

  return (
    <PaginatedContent
      pagination={pagination}
      loading={loading}
      onRetry={handleInit}
    >
      <SpotifyFollowedArtistsSettings />
      <SpotifyFollowedArtistsList />
    </PaginatedContent>
  );
};
