import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Avatar, Theme, Typography, Badge } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SpotifyPlaylist } from '../../types';
import { spotifyImageByIndex } from '../../../utils';
import { getSpotifyPlaylistStateById } from '../../selectors';
import { Progress } from '../../../../components/Progress';
import { spotifyInitPlaylist } from '../../actions';
import { LoadingState } from '../../../../types';
import { Checkmark } from '../../../../components/Checkmark';

interface SpotifyPlaylistItemProps {
  playlist: SpotifyPlaylist;
  onSelect: (playlist: SpotifyPlaylist) => void;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  avatar: {
    marginRight: spacing(2),
  },
  wrapper: {
    cursor: 'pointer',
    border: `${spacing(1)} solid transparent`,
    boxSizing: 'border-box',
    width: '100%',

    '&:hover': {
      backgroundColor: 'rgba(39,39,39,0.05)',
    },
  },
  titleWrapper: {
    overflowWrap: 'break-word',
    maxWidth: 'calc(100% - 60px)',
  },
  title: {
    '&.MuiTypography-root': {
      lineHeight: 1,
    },
  },
  content: {
    width: '100%',
  },

  checkmarkWrapper: {
    width: 20,
  },

  badge: {
    marginRight: spacing(1.2),

    '& .MuiBadge-badge': {
      transform: 'scale(1) translate(50%, -50%)',
    },
  },
}));

export const SpotifyPlaylistItem: FC<SpotifyPlaylistItemProps> = (props) => {
  const { playlist, onSelect } = props;

  const { name, images, id } = playlist;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const classes = useStyles();

  const {
    pagination: { total, offset },
    loading,
    visibleTracks,
  } = useSelector(getSpotifyPlaylistStateById(id));

  useEffect(() => {
    dispatch(spotifyInitPlaylist.request(id));
  }, []);

  const handleSelect = useCallback(() => {
    onSelect(playlist);
  }, [onSelect, playlist]);

  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.wrapper}
      onClick={handleSelect}
    >
      <Avatar
        className={classes.avatar}
        variant="square"
        src={spotifyImageByIndex(images, 0)}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.content}
      >
        <Box className={classes.titleWrapper}>
          <Typography className={classes.title}>{name}</Typography>
        </Box>
        <Box>
          {loading === LoadingState.Request ? (
            <Progress current={offset} total={total} size={16} />
          ) : (
            <Box>
              {visibleTracks.length === 0 &&
              loading === LoadingState.Success ? (
                <Box className={classes.checkmarkWrapper}>
                  <Checkmark />
                </Box>
              ) : (
                <Badge
                  badgeContent={visibleTracks.length || t('error')}
                  className={classes.badge}
                  color="error"
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
