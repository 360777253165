import React, { FC, useCallback, useMemo } from 'react';
import { Box, List } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import {
  getSpotifyFollowedArtistsDeleteLoading,
  getSpotifyFollowedArtistsSelected,
  getSpotifyFollowedArtistsVisible,
} from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { SpotifyFollowedArtist } from './SpotifyFollowedArtist';
import { useTranslation } from 'react-i18next';
import {
  spotifyDeleteFollowedArtists,
  spotifyDeselectFollowedArtists,
  spotifyDeselectFollowedArtistsAll,
  spotifySelectFollowedArtists,
} from '../../actions';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { SpotifyArtist } from '../../../types';
import { LoadingState } from '../../../../types';
import { ListActions } from '../../../../components/ListActions';
import { useSetItem } from '../../../../hooks/useSetItem';
import { useSelectItems } from '../../../../hooks/useSelectItems';
import { UserArtistsCheckSuccess } from '../../../../components/UserArtists/UserArtistsCheckSuccess';
import { Description } from '../../../../components/Description';
import { DeleteLoading } from '../../../../components/DeleteLoading';
import { SpotifyEvidenceDialog } from '../../../components/SpotifyEvidenceDialog';

export const SpotifyFollowedArtistsList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteLoading = useSelector(getSpotifyFollowedArtistsDeleteLoading);
  const visibleFollowedArtists = useSelector(getSpotifyFollowedArtistsVisible);
  const selectedFollowedArtists = useSelector(
    getSpotifyFollowedArtistsSelected,
  );
  const selectedFollowedArtistsSet = useMemo(() => {
    return new Set(selectedFollowedArtists.map(({ id }) => id));
  }, [selectedFollowedArtists]);

  const {
    item: detailsFollowedArtist,
    setItem: setDetailsFollowedArtist,
    unsetItem: unsetDetailsFollowedArtist,
  } = useSetItem<SpotifyArtist>();

  const {
    items: deleteFollowedArtists,
    selectItems: setDeleteFollowedArtists,
    deselectItems: unsetDeleteFollowedArtists,
    selectItem: setDeleteFollowedArtist,
  } = useSelectItems<SpotifyArtist>();

  const handleDelete = useCallback(() => {
    if (deleteFollowedArtists) {
      dispatch(
        spotifyDeleteFollowedArtists.request(
          deleteFollowedArtists.map(({ id }) => id),
        ),
      );
    }
    unsetDeleteFollowedArtists();
  }, [dispatch, deleteFollowedArtists, unsetDeleteFollowedArtists]);

  const handleSelect = useCallback(
    (artist) => {
      dispatch(spotifySelectFollowedArtists([artist]));
    },
    [dispatch],
  );

  const handleDeselect = useCallback(
    (artist) => {
      dispatch(spotifyDeselectFollowedArtists(artist));
    },
    [dispatch],
  );

  const handleSelectAll = useCallback(() => {
    dispatch(spotifySelectFollowedArtists(visibleFollowedArtists));
  }, [dispatch, visibleFollowedArtists]);

  const handleDeselectAll = useCallback(() => {
    dispatch(spotifyDeselectFollowedArtistsAll());
  }, [dispatch]);

  const handleDeleteSelected = useCallback(() => {
    setDeleteFollowedArtists(selectedFollowedArtists);
  }, [setDeleteFollowedArtists, selectedFollowedArtists]);

  return (
    <>
      {visibleFollowedArtists.length > 0 ? (
        <Box>
          <Description>{t('spotify.followedArtists.description')}</Description>
          {deleteLoading === LoadingState.Request ? (
            <DeleteLoading />
          ) : (
            <Box>
              <ListActions
                onDelete={handleDeleteSelected}
                onDeselectAll={handleDeselectAll}
                onSelectAll={handleSelectAll}
                selectedCount={selectedFollowedArtistsSet.size}
                visibleCount={visibleFollowedArtists.length}
              />
              <List>
                {visibleFollowedArtists.map((artist) => (
                  <SpotifyFollowedArtist
                    onDetails={setDetailsFollowedArtist}
                    onDelete={setDeleteFollowedArtist}
                    key={artist.id}
                    artist={artist}
                    onSelect={handleSelect}
                    onDeselect={handleDeselect}
                    selected={selectedFollowedArtistsSet.has(artist.id)}
                  />
                ))}
              </List>
            </Box>
          )}
          <ConfirmationDialog
            open={Boolean(deleteFollowedArtists)}
            onConfirm={handleDelete}
            onClose={unsetDeleteFollowedArtists}
            title={t('delete.confirmation.title')}
            confirmText={t('delete.confirmation.confirm')}
            cancelText={t('delete.confirmation.cancel')}
          >
            <DialogContentText>
              {t('spotify.followedArtists.delete.confirmation.content')}
            </DialogContentText>
          </ConfirmationDialog>
          {detailsFollowedArtist && (
            <SpotifyEvidenceDialog
              artists={[detailsFollowedArtist]}
              onClose={unsetDetailsFollowedArtist}
            />
          )}
        </Box>
      ) : (
        <UserArtistsCheckSuccess />
      )}
    </>
  );
};
