import { handleError } from '../errors';
import { NotifiableError } from '@bugsnag/core/types/common';

export class StorageService {
  static setItem = (key: string, value: string) => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      handleError(error as NotifiableError);
    }
  };

  static getItem = (key: string, fallbackValue: string) => {
    try {
      return localStorage.getItem(key) || fallbackValue;
    } catch (error) {
      return fallbackValue;
    }
  };
}
