import { createAction, createAsyncAction } from 'typesafe-actions';
import { DeezerArtist } from '../types';
import {
  DeezerUserArtistsFetchRequest,
  DeezerUserArtistsFetchResponse,
} from './types';

export const deezerInitUserArtists = createAsyncAction(
  'deezer/user/artists/init/REQUEST',
  'deezer/user/artists/init/SUCCESS',
  'deezer/user/artists/init/FAILURE',
)<void, void, unknown>();

export const deezerFetchUserArtists = createAsyncAction(
  'deezer/user/artists/fetch/REQUEST',
  'deezer/user/artists/fetch/SUCCESS',
  'deezer/user/artists/fetch/FAILURE',
)<DeezerUserArtistsFetchRequest, DeezerUserArtistsFetchResponse, unknown>();

export const deezerDeleteUserArtists = createAsyncAction(
  'deezer/user/artists/delete/REQUEST',
  'deezer/user/artists/delete/SUCCESS',
  'deezer/user/artists/delete/FAILURE',
)<number[], number[], unknown>();

export const deezerSelectUserArtists = createAction(
  'deezer/user/artists/select',
)<DeezerArtist[]>();
export const deezerDeselectUserArtist = createAction(
  'deezer/user/artists/deselect',
)<DeezerArtist>();
export const deezerDeselectUserAllArtists = createAction(
  'deezer/user/artists/deselect/all',
)();
