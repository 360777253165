import React, { FC, useMemo } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { ArtistAvatarList } from '../components/ArtistAvatarList';
import { useSelector } from 'react-redux';
import { HowItWorks } from '../components/HowItWorks';
import { Page } from './Page';
import { getVisibleArtistsConfigList } from '../utils';
import { getUserLoggedPlatform } from '../user/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  artistList: {
    marginTop: theme.spacing(5),

    '@media (min-width: 600px)': {
      marginTop: theme.spacing(9),
    },
  },
  contentWrapper: {
    minHeight: '60vh',
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  sectionImage: {
    maxWidth: 250,
    margin: theme.spacing(2),
  },
  sectionTitle: {
    '&.MuiTypography-root': {
      color: theme.palette.grey['600'],
      fontWeight: 'bold',
      textTransform: 'uppercase',
      margin: theme.spacing(2),
      width: 400,
    },
  },
}));

const ARTISTS_VISIBLE_COUNT = 45;

interface MainPageProps {
  title: string;
  description: string;
}

export const MainPage: FC<MainPageProps> = (props) => {
  const { children, title, description } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const loggedPlatform = useSelector(getUserLoggedPlatform);

  const artistsWithImages = useMemo(() => {
    return getVisibleArtistsConfigList().slice(0, ARTISTS_VISIBLE_COUNT);
  }, []);

  return (
    <Page title={title} description={description}>
      <Box className={classes.contentWrapper}>
        <Box className={classes.content}>{children}</Box>
        {loggedPlatform ? null : <HowItWorks />}
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          className={classes.artistList}
        >
          <Typography
            fontSize="large"
            fontWeight="bold"
            textTransform="uppercase"
            textAlign="center"
            m={1.5}
          >
            {t('page.main.artists.title')}:
          </Typography>
          <ArtistAvatarList artists={artistsWithImages} />
        </Box>
      </Box>
    </Page>
  );
};
