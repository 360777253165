import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  link: {
    '&.MuiTypography-root': {
      textDecoration: 'unset',
      outline: 'none',
      color: 'inherit',
    },
  },
}));

export const Email = () => {
  const classes = useStyles();

  return (
    <Typography
      className={classes.link}
      component="a"
      href="mailto:support@shamevoices.com"
    >
      support@shamevoices.com
    </Typography>
  );
};
