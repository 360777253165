import { Box, CircularProgress, Typography, Button } from '@mui/material';
import React from 'react';
import { LoadingState } from '../types';
import { useTranslation } from 'react-i18next';

export const Loader: React.FC<{
  loading: LoadingState;
  retry?: () => void;
}> = ({ loading, retry }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {loading === LoadingState.Failure ? (
        <Box flexDirection="column" display="flex" textAlign="center">
          <Typography>{t('loader.error')}</Typography>
          {retry ? <Button onClick={retry}>{t('loader.retry')}</Button> : null}
        </Box>
      ) : (
        <CircularProgress color="inherit" />
      )}
    </Box>
  );
};
