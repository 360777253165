import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { Checkmark } from './Checkmark';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { InfoButton } from './InfoButton';
import InstagramIcon from '@mui/icons-material/Instagram';

const useStyles = makeStyles(() => ({
  checkmarkWrapper: {
    width: '35%',
    maxWidth: 150,
  },
}));

interface CheckSuccessProps {
  text: string;
}

export const CheckSuccess: FC<CheckSuccessProps> = (props) => {
  const { text } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box className={classes.checkmarkWrapper}>
        <Checkmark />
      </Box>
      <Typography m={1} textAlign="center">
        {text}
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography mr={1}>{t('share')}</Typography>
        <InfoButton icon={<InstagramIcon color="primary" />} />
      </Box>
    </Box>
  );
};
