import React, { FC } from 'react';
import { Settings } from '../../../../components/Settings';
import { useSelector } from 'react-redux';
import { getSpotifyFollowedArtistsSilent } from '../../selectors';

export const SpotifyFollowedArtistsSettings: FC = () => {
  const silentArtists = useSelector(getSpotifyFollowedArtistsSilent);

  return <Settings silentTotal={silentArtists.length} />;
};
