import { takeLatest } from 'redux-saga/effects';
import {
  settingsHideSilentArtists,
  settingsShowSilentArtists,
} from '../actions';
import { StorageService } from '../../services/storageService';
import {
  SETTING_ENABLED,
  SETTING_DISABLED,
  SETTINGS_SILENT_VISIBLE_KEY,
} from '../../constants';

function* handleSettingsHideSilentArtists() {
  StorageService.setItem(SETTINGS_SILENT_VISIBLE_KEY, SETTING_DISABLED);
}

export function* watchSettingsHideSilentArtists() {
  yield takeLatest(settingsHideSilentArtists, handleSettingsHideSilentArtists);
}

function* handleSettingsShowSilentArtists() {
  StorageService.setItem(SETTINGS_SILENT_VISIBLE_KEY, SETTING_ENABLED);
}

export function* watchSettingsShowSilentArtists() {
  yield takeLatest(settingsShowSilentArtists, handleSettingsShowSilentArtists);
}
