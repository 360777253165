import React, { FC, useCallback, useMemo } from 'react';
import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Page } from './Page';
import { ArtistAvatarList } from '../components/ArtistAvatarList';
import { getVisibleArtistsConfigList } from '../utils';
import { ArrowLeft } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../routes';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '&.MuiButton-root': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export const ArtistsPage: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const artists = useMemo(getVisibleArtistsConfigList, []);

  const handleNavigateHome = useCallback(() => {
    navigate(AppRoute.home);
  }, [navigate]);

  return (
    <Page
      title={t('page.artists.title')}
      description={t('page.artists.description')}
    >
      <Button className={classes.button} onClick={handleNavigateHome}>
        <ArrowLeft />
        {t('artists.navigate.main')}
      </Button>
      <ArtistAvatarList artists={artists} />
    </Page>
  );
};
