import React, { FC, useCallback } from 'react';
import { MainPage } from './MainPage';
import { SpotifyLoginButton } from '../spotify/components/SpotifyLoginButton';
import { userLogin } from '../user/actions';
import { Platform } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLoggedPlatform } from '../user/selectors';
import { useTranslation } from 'react-i18next';
import { SpotifyContent } from '../spotify/components/SpotifyContent';

export const SpotifyPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedPlatform = useSelector(getUserLoggedPlatform);

  const handleSpotifyLoginSuccess = useCallback(() => {
    dispatch(userLogin(Platform.Spotify));
  }, [dispatch]);

  return (
    <MainPage title={t('spotify.title')} description={t('spotify.description')}>
      {loggedPlatform === Platform.Spotify ? (
        <SpotifyContent />
      ) : (
        <SpotifyLoginButton onSuccess={handleSpotifyLoginSuccess} />
      )}
    </MainPage>
  );
};
