import React, { FC, memo } from 'react';
import { DeezerTrack } from '../../../types';
import { LibraryRemovableEntity } from '../../../../components/Library/LibraryRemovableEntity';

interface DeezerLibraryTrackProps {
  track: DeezerTrack;
  onDelete: (track: DeezerTrack) => void;
  onSelect: (track: DeezerTrack) => void;
  onDeselect: (track: DeezerTrack) => void;
  onDetails: (track: DeezerTrack) => void;
  selected: boolean;
}

export const DeezerLibraryTrack: FC<DeezerLibraryTrackProps> = memo((props) => {
  const { track, onDelete, selected, onSelect, onDeselect, onDetails } = props;
  const { title, artist, album } = track;

  return (
    <LibraryRemovableEntity
      entity={track}
      coverDescription={album.title}
      owner={artist.name}
      title={title}
      selected={selected}
      onSelect={onSelect}
      onDeselect={onDeselect}
      onDelete={onDelete}
      onDetails={onDetails}
      coverUrl={album.cover}
    />
  );
});

DeezerLibraryTrack.displayName = 'DeezerLibraryTrack';
