export enum FIREBASE_EVENT {
  LOGIN_SUCCESS = 'login_success',

  LIBRARY_TRACK_DELETE_SUCCESS = 'library_track_delete_success',
  LIBRARY_TRACK_DELETE_FAILURE = 'library_track_delete_failure',
  LIBRARY_INIT_SUCCESS = 'library_init_success',
  LIBRARY_INIT_FAILURE = 'library_init_failure',
  LIBRARY_FETCH_TRACKS_FAILURE = 'library_tracks_fetch_failure',

  SPOTIFY_FOLLOWED_ARTISTS_DELETE_SUCCESS = 'spotify_followed_artists_delete_success',
  SPOTIFY_FOLLOWED_ARTISTS_DELETE_FAILURE = 'spotify_followed_artists_delete_failure',
  SPOTIFY_FOLLOWED_ARTISTS_INIT_SUCCESS = 'spotify_followed_artists_init_success',
  SPOTIFY_FOLLOWED_ARTISTS_INIT_FAILURE = 'spotify_followed_artists_init_failure',
  SPOTIFY_FOLLOWED_ARTISTS_FETCH_FAILURE = 'spotify_followed_artists_fetch_failure',

  SPOTIFY_USER_INIT_SUCCESS = 'spotify_user_init_success',
  SPOTIFY_USER_INIT_FAILURE = 'spotify_user_init_failure',

  SPOTIFY_PLAYLISTS_INIT_SUCCESS = 'spotify_playlists_init_success',
  SPOTIFY_PLAYLISTS_INIT_FAILURE = 'spotify_playlists_init_failure',
  SPOTIFY_PLAYLISTS_FETCH_FAILURE = 'spotify_playlists_fetch_failure',

  SPOTIFY_PLAYLIST_INIT_SUCCESS = 'spotify_playlist_init_success',
  SPOTIFY_PLAYLIST_INIT_FAILURE = 'spotify_playlist_init_failure',
  SPOTIFY_PLAYLIST_TRACKS_FETCH_FAILURE = 'spotify_playlist_tracks_fetch_failure',

  SPOTIFY_PLAYLIST_TRACK_DELETE_SUCCESS = 'spotify_playlist_track_delete_success',
  SPOTIFY_PLAYLIST_TRACK_DELETE_FAILURE = 'spotify_playlist_track_delete_failure',

  DEEZER_USER_ARTIST_DELETE_SUCCESS = 'deezer_user_artist_delete_success',
  DEEZER_USER_ARTIST_DELETE_FAILURE = 'deezer_user_artist_delete_failure',
  DEEZER_USER_ARTISTS_INIT_SUCCESS = 'deezer_user_artists_init_success',
  DEEZER_USER_ARTISTS_INIT_FAILURE = 'deezer_user_artists_init_failure',
  DEEZER_USER_ARTISTS_FETCH_FAILURE = 'deezer_user_artists_fetch_failure',

  YOUTUBE_SUBSCRIPTION_DELETE_SUCCESS = 'youtube_subscription_delete_success',
  YOUTUBE_SUBSCRIPTION_DELETE_FAILURE = 'youtube_subscription_delete_failure',
  YOUTUBE_SUBSCRIPTIONS_INIT_SUCCESS = 'youtube_subscriptions_init_success',
  YOUTUBE_SUBSCRIPTIONS_INIT_FAILURE = 'youtube_subscriptions_init_failure',
  YOUTUBE_SUBSCRIPTIONS_FETCH_FAILURE = 'youtube_subscriptions_fetch_failure',

  YOUTUBE_DIALOG_QUOTA_LIMIT_SHOW = 'youtube_dialog_quota_limit_show',
}
