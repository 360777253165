import React, { FC, memo } from 'react';
import { SpotifyArtist } from '../../../types';
import { UserArtist } from '../../../../components/UserArtists/UserArtist';
import { spotifyImageByIndex } from '../../../utils';

interface SpotifyFollowedArtist {
  artist: SpotifyArtist;
  onDelete: (artist: SpotifyArtist) => void;
  onSelect: (artist: SpotifyArtist) => void;
  onDeselect: (artist: SpotifyArtist) => void;
  onDetails: (artist: SpotifyArtist) => void;
  selected: boolean;
}

export const SpotifyFollowedArtist: FC<SpotifyFollowedArtist> = memo(
  (props) => {
    const { artist, onDelete, selected, onSelect, onDeselect, onDetails } =
      props;
    const { name, images } = artist;

    return (
      <UserArtist
        artist={artist}
        picture={spotifyImageByIndex(images, 0)}
        name={name}
        selected={selected}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onDelete={onDelete}
        onDetails={onDetails}
      />
    );
  },
);

SpotifyFollowedArtist.displayName = 'SpotifyFollowedArtist';
