import { useEffect } from 'react';
import { noop } from 'lodash';

const useScript = (url: string, onLoad = noop) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.onload = onLoad;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, onLoad]);
};

export default useScript;
