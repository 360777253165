import { all, call } from 'redux-saga/effects';
import {
  watchYoutubeDeleteSubscriptions,
  watchYoutubeFetchSubscriptions,
  watchYoutubeInitSubscriptions,
  watchYoutubeRequestFailures,
} from './sagas';

export function* youtubeSubscriptionsSaga() {
  yield all([
    call(watchYoutubeInitSubscriptions),
    call(watchYoutubeFetchSubscriptions),
    call(watchYoutubeDeleteSubscriptions),
    call(watchYoutubeRequestFailures),
  ]);
}
