import {
  spotifyInitPlaylists,
  spotifyFetchPlaylists,
  spotifyInitPlaylist,
  spotifyFetchPlaylistTracks,
  spotifyDeletePlaylistTracks,
} from '../actions';
import { ActionType } from 'typesafe-actions';
import {
  takeLatest,
  take,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import {
  deleteSpotifyPlaylistTracksRequest,
  fetchSpotifyPlaylistsRequest,
  fetchSpotifyPlaylistTracksRequest,
} from '../api';
import {
  SPOTIFY_PLAYLIST_TRACKS_CHUNK_SIZE,
  SPOTIFY_PLAYLIST_TRACKS_DELETE_CHUNK_SIZE,
  SPOTIFY_PLAYLISTS_CHUNK_SIZE,
} from '../constants';
import {
  SpotifyPlaylistsFetchResponse,
  SpotifyPlaylistState,
  SpotifyPlaylistTracksFetchResponse,
} from '../types';
import {
  getSpotifyPlaylistsPagination,
  getSpotifyPlaylistStateById,
} from '../selectors';
import { SpotifyPagination } from '../../types';
import { fetchPlaylistTrackSuccessPattern } from './utils';
import { chunk } from 'lodash';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../../analytics';
import { FIREBASE_EVENT } from '../../../analytics/events';
import { Platform } from '../../../types';
import { handleError } from '../../../errors';
import { NotifiableError } from '@bugsnag/core/types/common';

function* handleSpotifyInitPlaylists() {
  let pagination: SpotifyPagination = yield select(
    getSpotifyPlaylistsPagination,
  );
  try {
    do {
      yield put(
        spotifyFetchPlaylists.request({
          limit: SPOTIFY_PLAYLISTS_CHUNK_SIZE,
          offset: pagination.offset,
        }),
      );
      yield take(spotifyFetchPlaylists.success);
      pagination = yield select(getSpotifyPlaylistsPagination);
    } while (pagination.offset < pagination.total);
    yield put(spotifyInitPlaylists.success());
    logEvent(analytics, FIREBASE_EVENT.SPOTIFY_PLAYLISTS_INIT_SUCCESS);
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.SPOTIFY_PLAYLISTS_INIT_FAILURE,
    });
    yield put(spotifyInitPlaylists.failure(error));
  }
}

export function* watchSpotifyInitPlaylists() {
  yield takeLatest(spotifyInitPlaylists.request, handleSpotifyInitPlaylists);
}

function* handleSpotifyFetchPlaylists(
  action: ActionType<typeof spotifyFetchPlaylists.request>,
) {
  try {
    const data: SpotifyPlaylistsFetchResponse = yield call(
      fetchSpotifyPlaylistsRequest,
      action.payload,
    );
    yield put(spotifyFetchPlaylists.success(data));
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.SPOTIFY_PLAYLISTS_FETCH_FAILURE,
    });
    yield put(spotifyFetchPlaylists.failure(error));
  }
}

export function* watchSpotifyFetchPlaylists() {
  yield takeLatest(spotifyFetchPlaylists.request, handleSpotifyFetchPlaylists);
}

function* handleSpotifyInitPlaylist(
  action: ActionType<typeof spotifyInitPlaylist.request>,
) {
  let playlistState: SpotifyPlaylistState = yield select(
    getSpotifyPlaylistStateById(action.payload),
  );
  let pagination = playlistState.pagination;
  try {
    do {
      yield put(
        spotifyFetchPlaylistTracks.request({
          playlistId: action.payload,
          limit: SPOTIFY_PLAYLIST_TRACKS_CHUNK_SIZE,
          offset: pagination.offset,
        }),
      );
      yield take(fetchPlaylistTrackSuccessPattern(action.payload));
      playlistState = yield select(getSpotifyPlaylistStateById(action.payload));
      pagination = playlistState.pagination;
    } while (pagination.offset < pagination.total);
    yield put(spotifyInitPlaylist.success(action.payload));
    logEvent(analytics, FIREBASE_EVENT.SPOTIFY_PLAYLIST_INIT_SUCCESS);
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.SPOTIFY_PLAYLIST_INIT_FAILURE,
    });
    yield put(
      spotifyInitPlaylist.failure({
        playlistId: action.payload,
        error,
      }),
    );
  }
}

export function* watchSpotifyInitPlaylist() {
  yield takeEvery(spotifyInitPlaylist.request, handleSpotifyInitPlaylist);
}

function* handleSpotifyFetchPlaylistTracks(
  action: ActionType<typeof spotifyFetchPlaylistTracks.request>,
) {
  try {
    const data: SpotifyPlaylistTracksFetchResponse = yield call(
      fetchSpotifyPlaylistTracksRequest,
      action.payload,
    );
    yield put(
      spotifyFetchPlaylistTracks.success({
        ...data,
        playlistId: action.payload.playlistId,
      }),
    );
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.SPOTIFY_PLAYLIST_TRACKS_FETCH_FAILURE,
    });
    yield put(
      spotifyFetchPlaylistTracks.failure({
        playlistId: action.payload.playlistId,
        error,
      }),
    );
  }
}

export function* watchSpotifyFetchPlaylistTracks() {
  yield takeEvery(
    spotifyFetchPlaylistTracks.request,
    handleSpotifyFetchPlaylistTracks,
  );
}

function* handleSpotifyDeletePlaylistTracks(
  action: ActionType<typeof spotifyDeletePlaylistTracks.request>,
) {
  try {
    const trackChunks = chunk(
      action.payload.tracks,
      SPOTIFY_PLAYLIST_TRACKS_DELETE_CHUNK_SIZE,
    );

    for (const chunk of trackChunks) {
      yield call(
        deleteSpotifyPlaylistTracksRequest,
        action.payload.playlistId,
        chunk.map(({ uri }) => ({ uri })),
      );
      logEvent(
        analytics,
        FIREBASE_EVENT.SPOTIFY_PLAYLIST_TRACK_DELETE_SUCCESS,
        {
          deletedCount: chunk.length,
          platform: Platform.Spotify,
        },
      );
    }
    yield put(
      spotifyDeletePlaylistTracks.success({
        playlistId: action.payload.playlistId,
        ids: action.payload.tracks.map(({ id }) => id),
      }),
    );
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.SPOTIFY_PLAYLIST_TRACK_DELETE_FAILURE,
    });
    yield put(
      spotifyDeletePlaylistTracks.failure({
        playlistId: action.payload.playlistId,
        error,
      }),
    );
  }
}

export function* watchSpotifyDeletePlaylistTracks() {
  yield takeLatest(
    spotifyDeletePlaylistTracks.request,
    handleSpotifyDeletePlaylistTracks,
  );
}
