import {
  SpotifyLibraryFetchRequest,
  SpotifyLibraryFetchResponse,
} from './types';
import { SpotifyApi } from '../SpotifyApi';
import { SpotifyTrack } from '../types';

export const fetchSpotifyLibraryRequest = async ({
  limit,
  offset,
}: SpotifyLibraryFetchRequest): Promise<SpotifyLibraryFetchResponse> => {
  const response = await SpotifyApi.get(
    'https://api.spotify.com/v1/me/tracks',
    {
      params: {
        limit,
        offset,
      },
    },
  );

  const { items, total } = response.data;

  return {
    tracks: items.map(({ track }: { track: SpotifyTrack }) => track),
    total,
    limit,
    offset,
  };
};

export const deleteSpotifyLibraryTracksRequest = async (ids: string[]) => {
  const response = await SpotifyApi.delete(
    'https://api.spotify.com/v1/me/tracks',
    {
      data: {
        ids,
      },
    },
  );
  return response.data;
};
