import React, { FC } from 'react';
import { Box, SvgIcon, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowRight } from '@mui/icons-material';

interface PlatformOptionProps {
  name: string;
  icon: React.ElementType;
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: theme.spacing(1),

    '&:hover': {
      backgroundColor: theme.palette.grey['100'],
    },

    '& svg': {
      width: 50,
      height: 50,
    },
  },
  platform: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      width: 80,
      height: 80,
      marginRight: theme.spacing(4),
    },
  },
}));

export const PlatformOption: FC<PlatformOptionProps> = (props) => {
  const { name, icon, onClick } = props;

  const classes = useStyles();

  return (
    <Box className={classes.wrapper} onClick={onClick}>
      <Box className={classes.platform}>
        <SvgIcon component={icon} inheritViewBox />
        <Typography fontSize="large" fontWeight="bold">
          {name}
        </Typography>
      </Box>
      <ArrowRight />
    </Box>
  );
};
