import React, { FC, useCallback, useMemo } from 'react';
import { Box, List } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import {
  getYoutubeSubscriptionsDeleteLoading,
  getYoutubeSubscriptionsSelected,
  getYoutubeSubscriptionsVisible,
} from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { YoutubeSubscriptionItem } from './YoutubeSubscriptionItem';
import { useTranslation } from 'react-i18next';
import {
  youtubeDeleteSubscriptions,
  youtubeDeselectSubscriptionsAll,
  youtubeDeselectSubscription,
  youtubeSelectSubscriptions,
} from '../../actions';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { LoadingState } from '../../../../types';
import { ListActions } from '../../../../components/ListActions';
import { useSetItem } from '../../../../hooks/useSetItem';
import { useSelectItems } from '../../../../hooks/useSelectItems';
import { Description } from '../../../../components/Description';
import { DeleteLoading } from '../../../../components/DeleteLoading';
import { YoutubeSubscription } from '../../types';
import { YoutubeSubscriptionEvidenceDialog } from './YoutubeEvidenceDialog';
import { CheckSuccess } from '../../../../components/CheckSuccess';

export const YoutubeSubscriptionsList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visibleSubscriptions = useSelector(getYoutubeSubscriptionsVisible);
  const deleteLoading = useSelector(getYoutubeSubscriptionsDeleteLoading);
  const selectedSubscriptions = useSelector(getYoutubeSubscriptionsSelected);
  const selectedSubscriptionsSet = useMemo(() => {
    return new Set(selectedSubscriptions.map(({ id }) => id));
  }, [selectedSubscriptions]);

  const {
    item: detailsSubscription,
    setItem: setDetailsSubscription,
    unsetItem: unsetDetailsSubscription,
  } = useSetItem<YoutubeSubscription>();

  const {
    items: deleteSubscriptions,
    selectItems: setDeleteSubscriptions,
    deselectItems: unsetDeleteSubscriptions,
    selectItem: setDeleteSubscription,
  } = useSelectItems<YoutubeSubscription>();

  const handleDelete = useCallback(() => {
    if (deleteSubscriptions) {
      dispatch(youtubeDeleteSubscriptions.request(deleteSubscriptions));
    }
    unsetDeleteSubscriptions();
  }, [dispatch, deleteSubscriptions, unsetDeleteSubscriptions]);

  const handleSelect = useCallback(
    (subscription) => {
      dispatch(youtubeSelectSubscriptions([subscription]));
    },
    [dispatch],
  );

  const handleDeselect = useCallback(
    (subscription) => {
      dispatch(youtubeDeselectSubscription(subscription));
    },
    [dispatch],
  );

  const handleSelectAll = useCallback(() => {
    dispatch(youtubeSelectSubscriptions(visibleSubscriptions));
  }, [dispatch, visibleSubscriptions]);

  const handleDeselectAll = useCallback(() => {
    dispatch(youtubeDeselectSubscriptionsAll());
  }, [dispatch]);

  const handleDeleteSelected = useCallback(() => {
    setDeleteSubscriptions(selectedSubscriptions);
  }, [setDeleteSubscriptions, selectedSubscriptions]);

  return (
    <>
      {visibleSubscriptions.length > 0 ? (
        <Box>
          <Description>{t('youtube.subscriptions.description')}</Description>
          {deleteLoading === LoadingState.Request ? (
            <DeleteLoading />
          ) : (
            <Box>
              <ListActions
                onDelete={handleDeleteSelected}
                onDeselectAll={handleDeselectAll}
                onSelectAll={handleSelectAll}
                selectedCount={selectedSubscriptionsSet.size}
                visibleCount={visibleSubscriptions.length}
              />
              <List>
                {visibleSubscriptions.map((subscription) => (
                  <YoutubeSubscriptionItem
                    onDetails={setDetailsSubscription}
                    onDelete={setDeleteSubscription}
                    key={subscription.id}
                    subscription={subscription}
                    onSelect={handleSelect}
                    onDeselect={handleDeselect}
                    selected={selectedSubscriptionsSet.has(subscription.id)}
                  />
                ))}
              </List>
            </Box>
          )}
          <ConfirmationDialog
            open={Boolean(deleteSubscriptions)}
            onConfirm={handleDelete}
            onClose={unsetDeleteSubscriptions}
            title={t('delete.confirmation.title')}
            confirmText={t('delete.confirmation.confirm')}
            cancelText={t('delete.confirmation.cancel')}
          >
            <DialogContentText>
              {t('youtube.subscriptions.delete.confirmation.content')}
            </DialogContentText>
          </ConfirmationDialog>
          {detailsSubscription && (
            <YoutubeSubscriptionEvidenceDialog
              subscription={detailsSubscription}
              onClose={unsetDetailsSubscription}
            />
          )}
        </Box>
      ) : (
        <CheckSuccess text={t('youtube.subscriptions.list.success')} />
      )}
    </>
  );
};
