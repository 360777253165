import { useCallback, useState } from 'react';

export const useSetItem = <T>() => {
  const [item, setItem] = useState<T | null>(null);

  const unsetItem = useCallback(() => setItem(null), [setItem]);

  return {
    item,
    setItem,
    unsetItem,
  };
};
