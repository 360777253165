import React, { FC, useState, useRef, useCallback } from 'react';
import { Box, Avatar, Typography, Theme, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EntityEvidenceContent } from './EntityEvidenceContent';
import InfoIcon from '@mui/icons-material/Info';
import { ArtistConfig } from '../types';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

interface ArtistAvatarProps {
  artistConfig: ArtistConfig;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: 170,
  },
  avatarWrapper: {
    position: 'relative',
    margin: theme.spacing(2),
    cursor: 'pointer',
    transition: 'transform 1.2s',
    minWidth: 130,

    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  avatar: {
    '&.MuiAvatar-root': {
      width: 100,
      height: 100,
    },
  },
  name: {
    '&.MuiTypography-root': {
      textAlign: 'center',
      marginTop: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  infoIcon: {
    position: 'absolute',
    right: 0,
    top: theme.spacing(10),
  },
  silentIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

export const ArtistAvatar: FC<ArtistAvatarProps> = (props) => {
  const {
    artistConfig: { image, name, evidence, spotify, silent },
  } = props;
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = useCallback(() => {
    setOpenPopover(true);
  }, [setOpenPopover]);

  const handleClosePopover = useCallback(() => {
    setOpenPopover(false);
  }, [setOpenPopover]);

  return (
    <Box className={classes.wrapper}>
      <Box
        ref={anchorRef}
        className={classes.avatarWrapper}
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={handleOpenPopover}
      >
        <Avatar className={classes.avatar} alt={name} src={image as string} />
        {silent ? null : (
          <PriorityHighIcon color="error" className={classes.silentIcon} />
        )}
        <InfoIcon className={classes.infoIcon} />
        <Typography className={classes.name}>{name}</Typography>
      </Box>
      <Popover
        PaperProps={{
          className: classes.paper,
        }}
        id={`evidence-popup-${spotify.ids[0]}`}
        open={openPopover}
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <EntityEvidenceContent
          entityName={name}
          evidence={evidence}
          silent={silent}
        />
      </Popover>
    </Box>
  );
};
