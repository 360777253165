import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { store } from './store';
import { createTheme, ThemeProvider } from '@mui/material';
import { ArtistsPage } from './pages/ArtistsPage';
import { SpotifyPage } from './pages/SpotifyPage';
import { DeezerPage } from './pages/DeezerPage';
import { HomePage } from './pages/HomePage';
import { AppRoute } from './routes';
import { YoutubePage } from './pages/YoutubePage';
import { PrivacyPolicyPage } from './pages/PrivacyPage';
import { TermsPage } from './pages/TermsPage';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export const App: FC = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path={AppRoute.artists} element={<ArtistsPage />} />
            <Route path={AppRoute.spotify} element={<SpotifyPage />} />
            <Route path={AppRoute.deezer} element={<DeezerPage />} />
            <Route path={AppRoute.youtube} element={<YoutubePage />} />
            <Route path={AppRoute.terms} element={<TermsPage />} />
            <Route path={AppRoute.privacy} element={<PrivacyPolicyPage />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
