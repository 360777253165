import React, { FC } from 'react';
import { Settings } from '../../../../components/Settings';
import { useSelector } from 'react-redux';
import { getSpotifyPlaylistsTracksTotal } from '../../selectors';

export const SpotifyPlaylistsSettings: FC = () => {
  const { silentTotal } = useSelector(getSpotifyPlaylistsTracksTotal);

  return <Settings silentTotal={silentTotal} />;
};
