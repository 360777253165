import { SpotifyFollowedArtistsState } from './types';
import { LoadingState } from '../../types';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { getSpotifyFilteredArtists } from './utils';

type Action = ActionType<typeof actions>;

const initialFollowedArtistsState: SpotifyFollowedArtistsState = {
  artists: [],
  loading: LoadingState.Idle,
  pagination: {
    total: 0,
    offset: 0,
    after: null,
  },
  selectedArtists: [],
  deleteLoading: LoadingState.Idle,
};

export const SpotifyFollowedArtistsReducer = createReducer<
  SpotifyFollowedArtistsState,
  Action
>(initialFollowedArtistsState)
  .handleAction(
    actions.spotifyFetchFollowedArtists.success,
    (state, action) => ({
      ...state,
      artists: [
        ...state.artists,
        ...getSpotifyFilteredArtists(action.payload.artists),
      ],
      pagination: {
        total: action.payload.total,
        offset: state.pagination.offset + action.payload.artists.length,
        after: action.payload.after,
      },
    }),
  )
  .handleAction(actions.spotifyInitFollowedArtists.request, (state) => ({
    ...state,
    loading: LoadingState.Request,
    artists: initialFollowedArtistsState.artists,
    pagination: initialFollowedArtistsState.pagination,
    error: undefined,
  }))
  .handleAction(actions.spotifyInitFollowedArtists.success, (state) => ({
    ...state,
    loading: LoadingState.Success,
  }))
  .handleAction(
    [
      actions.spotifyInitFollowedArtists.failure,
      actions.spotifyFetchFollowedArtists.failure,
    ],
    (state, action) => ({
      ...state,
      loading: LoadingState.Failure,
      error: action.payload,
    }),
  )
  .handleAction(actions.spotifyDeleteFollowedArtists.request, (state) => ({
    ...state,
    deleteLoading: LoadingState.Request,
  }))
  .handleAction(actions.spotifyDeleteFollowedArtists.failure, (state) => ({
    ...state,
    deleteLoading: LoadingState.Failure,
  }))
  .handleAction(
    actions.spotifyDeleteFollowedArtists.success,
    (state, action) => ({
      ...state,
      artists: state.artists.filter(({ id }) => !action.payload.includes(id)),
      selectedArtists: state.selectedArtists.filter(
        ({ id }) => !action.payload.includes(id),
      ),
      deleteLoading: LoadingState.Success,
    }),
  )
  .handleAction(actions.spotifySelectFollowedArtists, (state, action) => ({
    ...state,
    selectedArtists: [...state.selectedArtists, ...action.payload],
  }))
  .handleAction(actions.spotifyDeselectFollowedArtists, (state, action) => ({
    ...state,
    selectedArtists: state.selectedArtists.filter(
      (artist) => artist.id !== action.payload.id,
    ),
  }))
  .handleAction(actions.spotifyDeselectFollowedArtistsAll, (state) => ({
    ...state,
    selectedArtists: [],
  }));
