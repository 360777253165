import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Link } from '@mui/material';
import { Page } from './Page';

export const PrivacyPolicyPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('privacy.title')} description={t('privacy.description')}>
      <Box>
        <Typography>
          {t('privacy.google.description')}{' '}
          <Link target="_blank" href="https://policies.google.com/privacy">
            {t('privacy.google.link')}
          </Link>
        </Typography>
      </Box>
      <Box>
        <Typography fontWeight="bold">{t('privacy.a.title')}</Typography>
        <ol>
          <Typography component="li">{t('privacy.a.li.one')}</Typography>
          <Typography component="li">{t('privacy.a.li.two')}</Typography>
        </ol>
      </Box>
      <Box>
        <Typography fontWeight="bold">{t('privacy.b.title')}</Typography>
        <Typography component="p">{t('privacy.b.description')}</Typography>
        <ol>
          <Typography component="li">{t('privacy.b.li.one')}</Typography>
          <Typography component="li">{t('privacy.b.li.two')}</Typography>
        </ol>
      </Box>
      <Box>
        <Typography fontWeight="bold">{t('privacy.c.title')}</Typography>
        <Typography component="p">{t('privacy.c.description')}</Typography>
        <ol>
          <Typography component="li">{t('privacy.c.li.one')}</Typography>
          <Typography component="li">{t('privacy.c.li.two')}</Typography>
          <Typography component="li">{t('privacy.c.li.three')}</Typography>
        </ol>
        <Typography component="p">{t('privacy.c.description.two')}</Typography>
      </Box>
      <Box>
        <Typography fontWeight="bold">{t('privacy.d.title')}</Typography>
        <Typography component="p">{t('privacy.d.description')}</Typography>
      </Box>
      <Box>
        <Typography fontWeight="bold">{t('privacy.e.title')}</Typography>
        <Typography component="p">
          {t('privacy.e.description')}{' '}
          <Link target="_blank" href="https://myaccount.google.com/permissions">
            {t('privacy.e.link')}
          </Link>
        </Typography>
      </Box>
      <Box>
        <Typography fontWeight="bold">{t('privacy.f.title')}</Typography>
        <Typography component="p">{t('privacy.f.description')}</Typography>
      </Box>
    </Page>
  );
};
