import React, { useCallback } from 'react';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { ReactComponent as YoutubeSvg } from '../svg/youtube.svg';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../routes';

export const YoutubeButton = () => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate(AppRoute.youtube);
  }, [navigate]);

  return (
    <Tooltip title="YouTube">
      <IconButton onClick={handleNavigate}>
        <SvgIcon component={YoutubeSvg} inheritViewBox />
      </IconButton>
    </Tooltip>
  );
};
