import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from '@mui/material';
import { userLogout } from '../user/actions';

export const LogoutButton: FC = () => {
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(userLogout());
  }, [dispatch]);

  return (
    <IconButton onClick={handleLogout}>
      <LogoutIcon />
    </IconButton>
  );
};
