import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { LanguageSelect } from './LanguageSelect';
import { makeStyles } from '@mui/styles';
import { Logo } from './Logo';
import { Box, Theme } from '@mui/material';
import { LogoutButton } from './LogoutButton';
import { InfoButton } from './InfoButton';
import { getUserLoggedPlatform } from '../user/selectors';
import { DeezerButton } from './DeezerButton';
import { SpotifyButton } from './SpotifyButton';
import { YoutubeButton } from './YoutubeButton';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    '&.MuiToolbar-root': {
      justifyContent: 'space-between',
      paddingRight: 0,
      paddingLeft: 0,
      paddingBottom: theme.spacing(5),
    },
  },
}));

export const Header: FC = () => {
  const classes = useStyles();
  const loggedPlatform = useSelector(getUserLoggedPlatform);

  return (
    <AppBar position="static" color="primary">
      <Container maxWidth={false}>
        <Toolbar className={classes.toolbar}>
          <Logo />
          <Box display="flex" alignItems="center">
            <YoutubeButton />
            <SpotifyButton />
            <DeezerButton />
            <LanguageSelect />
            {loggedPlatform && <LogoutButton />}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
