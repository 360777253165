import React, { FC, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getYoutubeQuotaLimitDialogOpen } from '../selectors';
import { useSelector, useDispatch } from 'react-redux';
import { youtubeQuotaLimitDialogClose } from '../actions';

export const YoutubeQuotaLimitDialog: FC = () => {
  const { t } = useTranslation();
  const open = useSelector(getYoutubeQuotaLimitDialogOpen);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(youtubeQuotaLimitDialogClose());
  }, [dispatch]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="youtube-quota-limit-dialog-title"
      aria-describedby="youtube-quota-limit-dialog-description"
      scroll="paper"
    >
      <DialogTitle id="youtube-quota-limit-dialog-title">
        {t('youtube.dialogs.quota.limit.title')}
      </DialogTitle>
      <DialogContent>
        <Typography>{t('youtube.dialogs.quota.limit.content')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};
