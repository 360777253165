import React, { FC, useCallback } from 'react';
import { Box, SvgIcon } from '@mui/material';
import { ReactComponent as LogoSvg } from '../svg/ukraine.svg';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../routes';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const Logo: FC = () => {
  const navigate = useNavigate();

  const classes = useStyles();

  const handleNavigate = useCallback(() => {
    navigate(AppRoute.home);
  }, [navigate]);

  return (
    <Box onClick={handleNavigate} className={classes.wrapper}>
      <SvgIcon fontSize="large" component={LogoSvg} inheritViewBox />
    </Box>
  );
};
