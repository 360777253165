import { createSelector } from 'reselect';
import { getState } from '../../store/selectors';
import {
  getYoutubeNonSilentSubscriptions,
  isYoutubeSilentSubscription,
} from './utils';
import { getSettingsSilentVisible } from '../../settings/selectors';

const getYoutube = createSelector(getState, ({ youtube }) => youtube);
export const getYoutubeSubscriptions = createSelector(
  getYoutube,
  ({ subscriptions }) => subscriptions,
);
export const getYoutubeSubscriptionsPagination = createSelector(
  getYoutubeSubscriptions,
  ({ pagination }) => pagination,
);

export const getYoutubeSubscriptionsList = createSelector(
  getYoutubeSubscriptions,
  ({ subscriptions }) => subscriptions,
);

export const getYoutubeSubscriptionsSilent = createSelector(
  getYoutubeSubscriptionsList,
  (subscriptions) => subscriptions.filter(isYoutubeSilentSubscription),
);

export const getYoutubeSubscriptionsLoading = createSelector(
  getYoutubeSubscriptions,
  ({ loading }) => loading,
);

export const getYoutubeSubscriptionsDeleteLoading = createSelector(
  getYoutubeSubscriptions,
  ({ deleteLoading }) => deleteLoading,
);

export const getYoutubeSubscriptionsSelected = createSelector(
  getYoutubeSubscriptions,
  getSettingsSilentVisible,
  ({ selectedSubscriptions }, silentVisible) => {
    return silentVisible
      ? selectedSubscriptions
      : getYoutubeNonSilentSubscriptions(selectedSubscriptions);
  },
);

export const getYoutubeSubscriptionsVisible = createSelector(
  getYoutubeSubscriptionsList,
  getSettingsSilentVisible,
  (subscriptions, silentVisible) => {
    return silentVisible
      ? subscriptions
      : getYoutubeNonSilentSubscriptions(subscriptions);
  },
);
