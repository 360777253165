import { createSelector } from 'reselect';
import { getState } from '../../store/selectors';

const getSpotify = createSelector(getState, ({ spotify }) => spotify);
export const getSpotifyUser = createSelector(
  getSpotify,
  ({ user }) => user.user,
);
export const getSpotifyUserLoading = createSelector(
  getSpotify,
  ({ user }) => user.loading,
);
