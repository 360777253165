import React, { FC, useEffect } from 'react';
import { Box, IconButton, Link, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { DEEZER_AUTH_TOKEN_KEY } from '../constants';
import { ReactComponent as DeezerSvg } from '../../svg/deezer.svg';
import { getDeezerAuthLink } from '../utils';
import { useLocation } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { useLoginButtonStyles } from '../../components/styles/loginButtonStyles';

export interface DeezerLoginButtonProps {
  onSuccess: () => void;
}

export const DeezerLoginButton: FC<DeezerLoginButtonProps> = (props) => {
  const { onSuccess } = props;
  const classes = useLoginButtonStyles();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (Cookies.get(DEEZER_AUTH_TOKEN_KEY)) {
      onSuccess();
    }
  }, [onSuccess]);

  useEffect(() => {
    const search = location.hash.replace('#', '?');
    const searchParams = new URLSearchParams(search);

    const token = searchParams.get('access_token');
    const expires = searchParams.get('expires');

    if (token && expires) {
      Cookies.set(DEEZER_AUTH_TOKEN_KEY, token, {
        expires: new Date(new Date().getTime() + parseInt(expires) * 1000),
      });
      onSuccess();
      window.location.href = AppRoute.deezer;
    }
  }, [location, onSuccess]);

  return (
    <Link className={classes.wrapper} href={getDeezerAuthLink().href}>
      <IconButton>
        <Box className={classes.logoWrapper}>
          <SvgIcon component={DeezerSvg} inheritViewBox />
        </Box>
      </IconButton>
      <Typography className={classes.title}>
        {t('deezer.login.button.title')}
      </Typography>
    </Link>
  );
};
