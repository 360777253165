import { NotifiableError } from '@bugsnag/core/types/common';
import {
  spotifyDeleteFollowedArtists,
  spotifyFetchFollowedArtists,
  spotifyInitFollowedArtists,
} from '../actions';
import { logEvent } from 'firebase/analytics';
import { ActionType } from 'typesafe-actions';
import { takeLatest, take, call, put, select } from 'redux-saga/effects';
import { chunk } from 'lodash';
import {
  deleteSpotifyFollowedArtistsRequest,
  fetchSpotifyFollowedArtistsRequest,
} from '../api';
import {
  SPOTIFY_FOLLOWED_ARTISTS_CHUNK_SIZE,
  SPOTIFY_FOLLOWED_ARTISTS_DELETE_CHUNK_SIZE,
} from '../constants';
import {
  SpotifyFollowedArtistsFetchResponse,
  SpotifyFollowedArtistsPagination,
} from '../types';
import { getSpotifyFollowedArtistsPagination } from '../selectors';
import { analytics } from '../../../analytics';
import { FIREBASE_EVENT } from '../../../analytics/events';
import { handleError } from '../../../errors';
import { Platform } from '../../../types';

function* handleSpotifyInitFollowedArtists() {
  let after = null;
  try {
    do {
      yield put(
        spotifyFetchFollowedArtists.request({
          limit: SPOTIFY_FOLLOWED_ARTISTS_CHUNK_SIZE,
          after,
        }),
      );
      yield take(spotifyFetchFollowedArtists.success);
      const pagination: SpotifyFollowedArtistsPagination = yield select(
        getSpotifyFollowedArtistsPagination,
      );
      after = pagination.after;
    } while (after);
    yield put(spotifyInitFollowedArtists.success());
    logEvent(analytics, FIREBASE_EVENT.SPOTIFY_FOLLOWED_ARTISTS_INIT_SUCCESS);
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.SPOTIFY_FOLLOWED_ARTISTS_INIT_FAILURE,
    });
    yield put(spotifyInitFollowedArtists.failure(error));
  }
}

export function* watchSpotifyInitFollowedArtists() {
  yield takeLatest(
    spotifyInitFollowedArtists.request,
    handleSpotifyInitFollowedArtists,
  );
}

function* handleSpotifyFetchFollowedArtists(
  action: ActionType<typeof spotifyFetchFollowedArtists.request>,
) {
  try {
    const data: SpotifyFollowedArtistsFetchResponse = yield call(
      fetchSpotifyFollowedArtistsRequest,
      action.payload,
    );

    yield put(spotifyFetchFollowedArtists.success(data));
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.SPOTIFY_FOLLOWED_ARTISTS_FETCH_FAILURE,
    });
    yield put(spotifyFetchFollowedArtists.failure(error));
  }
}

export function* watchSpotifyFetchFollowedArtists() {
  yield takeLatest(
    spotifyFetchFollowedArtists.request,
    handleSpotifyFetchFollowedArtists,
  );
}

function* handleSpotifyDeleteFollowedArtists(
  action: ActionType<typeof spotifyDeleteFollowedArtists.request>,
) {
  try {
    const artistChunks = chunk(
      action.payload,
      SPOTIFY_FOLLOWED_ARTISTS_DELETE_CHUNK_SIZE,
    );

    for (const chunk of artistChunks) {
      yield call(deleteSpotifyFollowedArtistsRequest, chunk);
      logEvent(
        analytics,
        FIREBASE_EVENT.SPOTIFY_FOLLOWED_ARTISTS_DELETE_SUCCESS,
        {
          deletedCount: chunk.length,
          platform: Platform.Spotify,
        },
      );
    }
    yield put(spotifyDeleteFollowedArtists.success(action.payload));
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.SPOTIFY_FOLLOWED_ARTISTS_DELETE_FAILURE,
    });
    yield put(spotifyDeleteFollowedArtists.failure(error));
  }
}

export function* watchSpotifyDeleteFollowedArtists() {
  yield takeLatest(
    spotifyDeleteFollowedArtists.request,
    handleSpotifyDeleteFollowedArtists,
  );
}
