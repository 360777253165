const DEEZER_PERMISSIONS = ['manage_library', 'delete_library'];

export const getDeezerAuthLink = () => {
  const url = new URL('https://connect.deezer.com/oauth/auth.php');
  url.searchParams.set('app_id', process.env.REACT_APP_DEEZER_APP_ID || '');
  url.searchParams.set(
    'redirect_uri',
    process.env.REACT_APP_DEEZER_REDIRECT_URI || '',
  );
  url.searchParams.set('perms', DEEZER_PERMISSIONS.join(','));
  url.searchParams.set('response_type', 'token');
  return url;
};
