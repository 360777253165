import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useLoginButtonStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'transform 1.2s',
    fontSize: '1rem',

    '&.MuiLink-root': {
      textDecoration: 'none',
    },

    '&:hover': {
      transform: 'scale(1.2)',
    },
    width: '40%',
    maxWidth: 150,
  },
  logoWrapper: {
    width: '100%',

    '& svg': {
      width: '100%',
      height: '100%',
      marginBottom: theme.spacing(1),
    },
  },
  title: {
    textAlign: 'center',
    color: 'black',

    '&.MuiTypography-root': {
      lineHeight: 'normal',
    },
  },
}));
