import React, { FC } from 'react';
import { Settings } from '../../../../components/Settings';
import { useSelector } from 'react-redux';
import { getSpotifyLibrarySilentTracks } from '../../selectors';

export const SpotifyLibrarySettings: FC = () => {
  const silentTracks = useSelector(getSpotifyLibrarySilentTracks);

  return <Settings silentTotal={silentTracks.length} />;
};
