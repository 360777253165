import React, { FC, useEffect } from 'react';
import { Header } from '../components/Header';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Wave } from '../components/Wave';
import { Footer } from '../components/Footer';
import MetaTags from 'react-meta-tags';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    '&.MuiTypography-root': {
      position: 'absolute',
      top: theme.spacing(8),
      left: theme.spacing(2),
      color: theme.palette.primary.light,
      fontSize: '3.7vw',
      lineHeight: '3.7vw',
      width: '55%',
      textTransform: 'uppercase',

      '@media (min-width: 600px)': {
        fontSize: '3.2vw',
        lineHeight: '3.2vw',
        top: theme.spacing(11),
        left: theme.spacing(4),
      },
    },
  },
  content: {
    padding: theme.spacing(2),

    '@media (min-width: 600px)': {
      padding: theme.spacing(4),
    },
  },
}));

interface PageProps {
  title: string;
  description: string;
}

export const Page: FC<PageProps> = (props) => {
  const { children, title, description } = props;
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <MetaTags>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
      </MetaTags>
      <Header />
      <Wave />
      <Typography className={classes.title}>{title}</Typography>
      <Box className={classes.content}>{children}</Box>
      <Footer />
    </Box>
  );
};
