import React, { FC, useCallback, useMemo } from 'react';
import { Box, List } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogContentText from '@mui/material/DialogContentText';
import { SpotifyLibraryTrack } from '../library/components/SpotifyLibrary/SpotifyLibraryTrack';
import { SpotifyTrack } from '../types';
import { useSetItem } from '../../hooks/useSetItem';
import { useSelectItems } from '../../hooks/useSelectItems';
import { Description } from '../../components/Description';
import { DeleteLoading } from '../../components/DeleteLoading';
import { LoadingState } from '../../types';
import { ListActions } from '../../components/ListActions';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';
import { SpotifyEvidenceDialog } from './SpotifyEvidenceDialog';

interface SpotifyTracksListProps {
  visibleTracks: SpotifyTrack[];
  selectedTracks: SpotifyTrack[];
  deleteLoading: LoadingState;
  onDelete: (tracks: SpotifyTrack[]) => void;
  onSelect: (tracks: SpotifyTrack[]) => void;
  onDeselect: (track: SpotifyTrack) => void;
  onDeselectAll: () => void;
  description: string;
  deleteConfirmation: string;
  EmptyListComponent: React.ReactNode;
}

export const SpotifyTracksList: FC<SpotifyTracksListProps> = (props) => {
  const {
    visibleTracks,
    selectedTracks,
    deleteLoading,
    onDelete,
    onSelect,
    onDeselect,
    onDeselectAll,
    description,
    EmptyListComponent,
    deleteConfirmation,
  } = props;
  const { t } = useTranslation();
  const selectedTracksSet = useMemo(() => {
    return new Set(selectedTracks.map(({ id }) => id));
  }, [selectedTracks]);

  const {
    item: detailsTrack,
    setItem: setDetailsTrack,
    unsetItem: unsetDetailsTrack,
  } = useSetItem<SpotifyTrack>();

  const {
    items: deleteTracks,
    selectItems: setDeleteTracks,
    deselectItems: unsetDeleteTracks,
    selectItem: setDeleteTrack,
  } = useSelectItems<SpotifyTrack>();

  const handleDelete = useCallback(() => {
    if (deleteTracks) {
      onDelete(deleteTracks);
    }
    unsetDeleteTracks();
  }, [onDelete, deleteTracks, unsetDeleteTracks]);

  const handleSelect = useCallback(
    (track) => {
      onSelect([track]);
    },
    [onSelect],
  );

  const handleDeselect = useCallback(
    (track) => {
      onDeselect(track);
    },
    [onDeselect],
  );

  const handleSelectAll = useCallback(() => {
    onSelect(visibleTracks);
  }, [visibleTracks]);

  const handleDeleteSelected = useCallback(() => {
    setDeleteTracks(selectedTracks);
  }, [setDeleteTracks, selectedTracks]);

  return (
    <>
      {visibleTracks.length > 0 ? (
        <Box>
          <Description>{description}</Description>
          {deleteLoading === LoadingState.Request ? (
            <DeleteLoading />
          ) : (
            <Box>
              <ListActions
                onDelete={handleDeleteSelected}
                onDeselectAll={onDeselectAll}
                onSelectAll={handleSelectAll}
                selectedCount={selectedTracksSet.size}
                visibleCount={visibleTracks.length}
              />
              <List>
                {visibleTracks.map((track) => (
                  <SpotifyLibraryTrack
                    onDetails={setDetailsTrack}
                    onDelete={setDeleteTrack}
                    key={track.id}
                    track={track}
                    onSelect={handleSelect}
                    onDeselect={handleDeselect}
                    selected={selectedTracksSet.has(track.id)}
                  />
                ))}
              </List>
            </Box>
          )}
          <ConfirmationDialog
            open={Boolean(deleteTracks)}
            onConfirm={handleDelete}
            onClose={unsetDeleteTracks}
            title={t('delete.confirmation.title')}
            confirmText={t('delete.confirmation.confirm')}
            cancelText={t('delete.confirmation.cancel')}
          >
            <DialogContentText>{deleteConfirmation}</DialogContentText>
          </ConfirmationDialog>
          {detailsTrack && (
            <SpotifyEvidenceDialog
              track={detailsTrack}
              artists={detailsTrack.artists}
              onClose={unsetDetailsTrack}
            />
          )}
        </Box>
      ) : (
        EmptyListComponent
      )}
    </>
  );
};
