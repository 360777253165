import {
  DeezerLibraryFetchRequest,
  DeezerLibraryTracksFetchResponse,
} from './types';
import { DeezerApi } from '../DeezerApi';

export const fetchDeezerLibraryRequest = async (
  request: DeezerLibraryFetchRequest,
): Promise<DeezerLibraryTracksFetchResponse> => {
  const url = request.url || 'https://api.deezer.com/user/me/tracks';
  const response = await DeezerApi.get(url);

  const { total, data, next } = response.data;

  return {
    total,
    next,
    tracks: data,
  };
};

export const deleteDeezerLibraryTracksRequest = async (id: number) => {
  const url = new URL('https://api.deezer.com/user/me/tracks');
  url.searchParams.append('track_id', id.toString());

  const response = await DeezerApi.delete(url.href);

  return response.data;
};
