import { all, call } from 'redux-saga/effects';
import { userSaga } from '../user/sagas';
import { deezerSaga } from '../deezer/store/sagas';
import { spotifySaga } from '../spotify/store/sagas';
import { settingsSaga } from '../settings/sagas';
import { youtubeSaga } from '../youtube/store/sagas';

export function* rootSaga() {
  yield all([
    call(spotifySaga),
    call(userSaga),
    call(deezerSaga),
    call(youtubeSaga),
    call(settingsSaga),
  ]);
}
