import React, { FC } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { LANGUAGE_KEY } from '../constants';
import { StorageService } from '../services/storageService';

const languages = [
  {
    title: 'УКР',
    value: 'ua',
  },
  {
    title: 'ENG',
    value: 'en',
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    marginTop: theme.spacing(4),
  },
  button: {
    '&.MuiButton-root': {
      color: theme.palette.primary.light,
    },
  },
}));

export const LanguageSelect: FC = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    setAnchorElUser(null);
  };

  const handleChangeLanguage = async (value: string) => {
    handleClose();
    await i18n.changeLanguage(value);
    StorageService.setItem(LANGUAGE_KEY, value);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const currentLanguageTitle = languages.find(
    ({ value }) => value === i18n.language,
  )?.title;

  return (
    <Box>
      <Tooltip title={t('language.change.tooltip') as string}>
        <Button className={classes.button} onClick={handleOpen}>
          {currentLanguageTitle}
        </Button>
      </Tooltip>
      <Menu
        className={classes.menu}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleClose}
      >
        {languages.map(({ title, value }) => (
          <MenuItem key={value} onClick={() => handleChangeLanguage(value)}>
            <Typography textAlign="center">{title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
