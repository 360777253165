import React, { FC } from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EntityEvidence, EntityType } from '../types';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';

interface EntityEvidenceContentProps {
  evidence: EntityEvidence;
  entityName: string;
  silent: boolean;
  entityType?: EntityType;
  owner?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    '& a': {
      color: theme.palette.primary.light,
    },
  },
  content: {
    maxWidth: 350,
  },
}));

export const EntityEvidenceContent: FC<EntityEvidenceContentProps> = (
  props,
) => {
  const { evidence, silent, entityName, entityType, owner } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const hasEvidence = !isEmpty(evidence);

  return (
    <Box className={classes.wrapper}>
      <Typography color="primary" mb={1} fontSize="small">
        {t(entityType || EntityType.Artist, { name: entityName })}{' '}
        {owner && owner !== entityName ? t('owned', { name: owner }) : null}
      </Typography>
      {silent && (
        <Typography fontSize="small" mb={1}>
          {t('evidence.silent')}
        </Typography>
      )}
      {evidence.propaganda && (
        <Typography fontSize="small" mb={1}>
          {t('evidence.propaganda')}
        </Typography>
      )}
      {hasEvidence ? (
        <Box>
          {evidence.links ? (
            <Box>
              <Typography fontSize="small">{t('links')}</Typography>
              <ol className={classes.content}>
                {evidence.links?.map(({ href, label }, index) => (
                  <li key={index}>
                    <Typography
                      component="a"
                      href={href}
                      target="_blank"
                      rel="noreferrer"
                      fontSize="small"
                    >
                      {t(label)}
                    </Typography>
                  </li>
                ))}
              </ol>
            </Box>
          ) : null}
          {evidence.quotes ? (
            <Box>
              <Typography fontSize="small">{t('quotes')}</Typography>
              <ol className={classes.content}>
                {evidence.quotes?.map((text, idx) => (
                  <li key={idx}>
                    <Typography fontSize="small">«{text}»</Typography>
                  </li>
                ))}
              </ol>
            </Box>
          ) : null}
          {evidence.concerts ? (
            <Box>
              <Typography fontSize="small">{t('concerts')}</Typography>
              <ol className={classes.content}>
                {evidence.concerts?.map((text, idx) => (
                  <li key={idx}>
                    <Typography fontSize="small">{text}</Typography>
                  </li>
                ))}
              </ol>
            </Box>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};
