import { all, call } from 'redux-saga/effects';
import {
  watchSpotifyDeleteLibraryTracks,
  watchSpotifyFetchLibraryTracks,
  watchSpotifyInitLibrary,
} from './sagas';

export function* spotifyLibrarySaga() {
  yield all([
    call(watchSpotifyInitLibrary),
    call(watchSpotifyFetchLibraryTracks),
    call(watchSpotifyDeleteLibraryTracks),
  ]);
}
