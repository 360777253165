import React, { FC, useCallback, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import { ArtistAvatar } from './ArtistAvatar';
import { ArrowRight } from '@mui/icons-material';
import { getVisibleArtistsConfigList } from '../utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArtistConfig } from '../types';
import { AppRoute } from '../routes';

export interface ArtistAvatarListProps {
  artists: Array<ArtistConfig>;
}

export const ArtistAvatarList: FC<ArtistAvatarListProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { artists } = props;

  const moreVisible = useMemo(() => {
    return artists.length !== getVisibleArtistsConfigList().length;
  }, [artists.length]);

  const handleMore = useCallback(() => {
    navigate(AppRoute.artists);
  }, [navigate]);

  return (
    <Box
      mt={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
    >
      {artists.map((artist) => (
        <ArtistAvatar key={artist.spotify.ids[0]} artistConfig={artist} />
      ))}
      {moreVisible ? (
        <Button onClick={handleMore}>
          <ArrowRight />
          {t('artists.more')}
        </Button>
      ) : null}
    </Box>
  );
};
