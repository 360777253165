import { createSelector } from 'reselect';
import { getDeezerNonSilentLibraryTracks, isDeezerSilentTrack } from './utils';
import { getState } from '../../store/selectors';
import { getSettingsSilentVisible } from '../../settings/selectors';

const getDeezer = createSelector(getState, ({ deezer }) => deezer);

export const getDeezerLibrary = createSelector(
  getDeezer,
  ({ library }) => library,
);
export const getDeezerLibraryPagination = createSelector(
  getDeezerLibrary,
  ({ pagination }) => pagination,
);

export const getDeezerLibraryTracks = createSelector(
  getDeezerLibrary,
  ({ tracks }) => tracks,
);

export const getDeezerLibraryVisibleTracks = createSelector(
  getDeezerLibraryTracks,
  getSettingsSilentVisible,
  (tracks, silentVisible) => {
    return silentVisible ? tracks : getDeezerNonSilentLibraryTracks(tracks);
  },
);

export const getDeezerLibrarySilentTracks = createSelector(
  getDeezerLibrary,
  ({ tracks }) => tracks.filter(isDeezerSilentTrack),
);

export const getDeezerLibraryLoading = createSelector(
  getDeezerLibrary,
  ({ loading }) => loading,
);

export const getDeezerLibraryDeleteLoading = createSelector(
  getDeezerLibrary,
  ({ deleteLoading }) => deleteLoading,
);

export const getDeezerLibrarySelectedTracks = createSelector(
  getDeezerLibrary,
  getSettingsSilentVisible,
  ({ selectedTracks }, silentVisible) => {
    return silentVisible
      ? selectedTracks
      : getDeezerNonSilentLibraryTracks(selectedTracks);
  },
);
