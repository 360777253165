import { DeezerApi } from '../DeezerApi';
import {
  DeezerUserArtistsFetchRequest,
  DeezerUserArtistsFetchResponse,
} from './types';

export const fetchDeezerUserArtistsRequest = async (
  request: DeezerUserArtistsFetchRequest,
): Promise<DeezerUserArtistsFetchResponse> => {
  const url = request.url || 'https://api.deezer.com/user/me/artists';
  const response = await DeezerApi.get(url);

  const { total, data, next } = response.data;

  return {
    total,
    next,
    artists: data,
  };
};

export const deleteDeezerUserArtistsRequest = async (id: number) => {
  const url = new URL('https://api.deezer.com/user/me/artists');
  url.searchParams.append('artist_id', id.toString());

  const response = await DeezerApi.delete(url.href);

  return response.data;
};
