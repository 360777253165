import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Link } from '@mui/material';
import { Page } from './Page';
import { AppRoute } from '../routes';

export const TermsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('terms.title')} description={t('terms.description')}>
      <Typography fontWeight="bold">{t('terms.last.updated')}</Typography>
      <Typography component="p">
        {t('terms.general.description')}{' '}
        <Link target="_blank" href="https://www.youtube.com/t/terms">
          {t('terms.youtube.link')}
        </Link>
      </Typography>
      <Box>
        <Typography fontWeight="bold">{t('terms.services.title')}</Typography>
        <Typography component="p">
          {t('terms.services.description.one')}
        </Typography>
        <ol>
          <Typography component="li">{t('terms.services.spotify')}</Typography>
          <Typography component="li">{t('terms.services.deezer')}</Typography>
          <Typography component="li">{t('terms.services.youtube')}</Typography>
        </ol>
        <Typography component="p">
          {t('terms.services.description.two')}
        </Typography>
      </Box>
      <Box>
        <Typography fontWeight="bold">{t('terms.users.title')}</Typography>
        <Typography component="p">{t('terms.users.description')}</Typography>
      </Box>
      <Box>
        <Typography fontWeight="bold">
          {t('terms.personal.data.title')}
        </Typography>
        <Typography component="p">
          <Link target="_blank" href={AppRoute.privacy}>
            {t('privacy.title')}
          </Link>{' '}
          {t('terms.personal.data.description')}
        </Typography>
      </Box>
    </Page>
  );
};
