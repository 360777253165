import { SpotifyApi } from '../SpotifyApi';
import {
  SpotifyPlaylistsFetchRequest,
  SpotifyPlaylistsFetchResponse,
  SpotifyPlaylistTracksFetchRequest,
  SpotifyPlaylistTracksFetchResponse,
} from './types';
import { SpotifyTrack } from '../types';

export const fetchSpotifyPlaylistsRequest = async ({
  limit,
  offset,
}: SpotifyPlaylistsFetchRequest): Promise<SpotifyPlaylistsFetchResponse> => {
  const response = await SpotifyApi.get(
    'https://api.spotify.com/v1/me/playlists',
    {
      params: {
        limit,
        offset,
      },
    },
  );

  const { items, total } = response.data;

  return {
    playlists: items,
    total,
    limit,
    offset,
  };
};

export const fetchSpotifyPlaylistTracksRequest = async ({
  playlistId,
  limit,
  offset,
}: SpotifyPlaylistTracksFetchRequest): Promise<SpotifyPlaylistTracksFetchResponse> => {
  const response = await SpotifyApi.get(
    `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
    {
      params: {
        limit,
        offset,
      },
    },
  );

  const { items, total } = response.data;

  return {
    tracks: items.map(({ track }: { track: SpotifyTrack }) => track),
    total,
    limit,
    offset,
  };
};

export const deleteSpotifyPlaylistTracksRequest = async (
  playlistId: string,
  trackUris: { uri: string }[],
) => {
  const response = await SpotifyApi.delete(
    `https://api.spotify.com/v1/playlists/${playlistId}/tracks`,
    {
      data: {
        tracks: trackUris,
      },
    },
  );
  return response.data;
};
