import React, { FC } from 'react';
import { Settings } from '../../../../components/Settings';
import { useSelector } from 'react-redux';
import { getDeezerLibrarySilentTracks } from '../../selectors';

export const DeezerLibrarySettings: FC = () => {
  const silentTracks = useSelector(getDeezerLibrarySilentTracks);

  return <Settings silentTotal={silentTracks.length} />;
};
