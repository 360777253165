import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const DeleteLoading = () => {
  const { t } = useTranslation();
  return (
    <Box
      flexDirection="column"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="inherit" />
      <Typography mt={1}>{t('delete.loading')}</Typography>
    </Box>
  );
};
