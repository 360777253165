import { createSelector } from 'reselect';
import { getSpotifyNonSilentArtists, isSpotifySilentArtist } from './utils';
import { getState } from '../../store/selectors';
import { getSettingsSilentVisible } from '../../settings/selectors';

const getSpotify = createSelector(getState, ({ spotify }) => spotify);
export const getSpotifyFollowedArtists = createSelector(
  getSpotify,
  ({ followedArtists }) => followedArtists,
);
export const getSpotifyFollowedArtistsPagination = createSelector(
  getSpotifyFollowedArtists,
  ({ pagination }) => pagination,
);

export const getSpotifyFollowedArtistsList = createSelector(
  getSpotifyFollowedArtists,
  ({ artists }) => artists,
);
export const getSpotifyFollowedArtistsVisible = createSelector(
  getSpotifyFollowedArtistsList,
  getSettingsSilentVisible,
  (artists, silentVisible) => {
    return silentVisible ? artists : getSpotifyNonSilentArtists(artists);
  },
);
export const getSpotifyFollowedArtistsSilent = createSelector(
  getSpotifyFollowedArtistsList,
  (artists) => artists.filter(isSpotifySilentArtist),
);

export const getSpotifyFollowedArtistsLoading = createSelector(
  getSpotifyFollowedArtists,
  ({ loading }) => loading,
);
export const getSpotifyFollowedArtistsDeleteLoading = createSelector(
  getSpotifyFollowedArtists,
  ({ deleteLoading }) => deleteLoading,
);
export const getSpotifyFollowedArtistsSelected = createSelector(
  getSpotifyFollowedArtists,
  getSettingsSilentVisible,
  ({ selectedArtists }, silentVisible) => {
    return silentVisible
      ? selectedArtists
      : getSpotifyNonSilentArtists(selectedArtists);
  },
);
