import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { SpotifyUserState } from './types';
import { LoadingState } from '../../types';

type Action = ActionType<typeof actions>;

const initialUserState: SpotifyUserState = {
  user: null,
  loading: LoadingState.Idle,
};

export const SpotifyUserReducer = createReducer<SpotifyUserState, Action>(
  initialUserState,
)
  .handleAction(actions.spotifyInitUser.success, (state, action) => ({
    user: action.payload,
    loading: LoadingState.Success,
  }))
  .handleAction(actions.spotifyInitUser.request, (state) => ({
    ...state,
    loading: LoadingState.Request,
  }))
  .handleAction(actions.spotifyInitUser.failure, (state) => ({
    ...state,
    loading: LoadingState.Failure,
  }));
