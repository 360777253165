import { all, call } from 'redux-saga/effects';
import { spotifyLibrarySaga } from '../library/sagas';
import { spotifyFollowedArtistsSaga } from '../followedArtists/sagas';
import { spotifyUserSaga } from '../user/sagas';
import { spotifyPlaylistsSaga } from '../playlists/sagas';

export function* spotifySaga() {
  yield all([
    call(spotifyLibrarySaga),
    call(spotifyFollowedArtistsSaga),
    call(spotifyUserSaga),
    call(spotifyPlaylistsSaga),
  ]);
}
