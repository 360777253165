import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  YoutubeSubscription,
  YoutubeSubscriptionsFetchRequest,
  YoutubeSubscriptionsFetchResponse,
} from './types';

export const youtubeInitSubscriptions = createAsyncAction(
  'youtube/subscriptions/init/REQUEST',
  'youtube/subscriptions/init/SUCCESS',
  'youtube/subscriptions/init/FAILURE',
)<void, void, unknown>();

export const youtubeFetchSubscriptions = createAsyncAction(
  'youtube/subscriptions/fetch/REQUEST',
  'youtube/subscriptions/fetch/SUCCESS',
  'youtube/subscriptions/fetch/FAILURE',
)<
  YoutubeSubscriptionsFetchRequest,
  YoutubeSubscriptionsFetchResponse,
  unknown
>();

export const youtubeDeleteSubscriptions = createAsyncAction(
  'youtube/subscriptions/delete/REQUEST',
  'youtube/subscriptions/delete/SUCCESS',
  'youtube/subscriptions/delete/FAILURE',
)<Array<YoutubeSubscription>, Array<YoutubeSubscription>, unknown>();

export const youtubeSelectSubscriptions = createAction(
  'youtube/subscriptions/select',
)<YoutubeSubscription[]>();
export const youtubeDeselectSubscription = createAction(
  'youtube/subscriptions/deselect',
)<YoutubeSubscription>();
export const youtubeDeselectSubscriptionsAll = createAction(
  'youtube/subscriptions/deselect/all',
)();
