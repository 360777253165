import React, { FC, useCallback, useEffect } from 'react';
import { PlatformOption } from './PlatformOption';
import Cookies from 'js-cookie';
import { YOUTUBE_AUTH_TOKEN_KEY } from '../../youtube/constants';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { ReactComponent as YoutubeSvg } from '../../svg/youtube.svg';

export const PlatformOptionYoutube: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get(YOUTUBE_AUTH_TOKEN_KEY)) {
      navigate(AppRoute.youtube, { replace: true });
    }
  }, []);

  const handleClick = useCallback(() => {
    navigate(AppRoute.youtube);
  }, [navigate]);

  return (
    <PlatformOption
      name="YouTube Videos"
      icon={YoutubeSvg}
      onClick={handleClick}
    />
  );
};
