import { createSelector } from 'reselect';
import { getState } from '../../store/selectors';
import { getSettingsSilentVisible } from '../../settings/selectors';
import {
  getSpotifyNonSilentLibraryTracks,
  isSpotifySilentTrack,
} from '../utils';

const getSpotify = createSelector(getState, ({ spotify }) => spotify);
export const getSpotifyLibrary = createSelector(
  getSpotify,
  ({ library }) => library,
);
export const getSpotifyLibraryPagination = createSelector(
  getSpotifyLibrary,
  ({ pagination }) => pagination,
);

export const getSpotifyLibraryTracks = createSelector(
  getSpotifyLibrary,
  ({ tracks }) => tracks,
);
export const getSpotifyLibraryVisibleTracks = createSelector(
  getSpotifyLibraryTracks,
  getSettingsSilentVisible,
  (tracks, silentVisible) => {
    return silentVisible ? tracks : getSpotifyNonSilentLibraryTracks(tracks);
  },
);
export const getSpotifyLibrarySilentTracks = createSelector(
  getSpotifyLibraryTracks,
  (tracks) => tracks.filter(isSpotifySilentTrack),
);

export const getSpotifyLibraryLoading = createSelector(
  getSpotifyLibrary,
  ({ loading }) => loading,
);
export const getSpotifyLibraryDeleteLoading = createSelector(
  getSpotifyLibrary,
  ({ deleteLoading }) => deleteLoading,
);
export const getSpotifyLibrarySelectedTracks = createSelector(
  getSpotifyLibrary,
  getSettingsSilentVisible,
  ({ selectedTracks }, silentVisible) => {
    return silentVisible
      ? selectedTracks
      : getSpotifyNonSilentLibraryTracks(selectedTracks);
  },
);
