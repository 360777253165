import React, { FC, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSpotifyPlaylistsLoading,
  getSpotifyPlaylistsPagination,
} from '../../selectors';
import { PaginatedContent } from '../../../../components/PaginatedContent';
import { spotifyInitPlaylists } from '../../actions';
import { SpotifyPlaylistList } from './SpotifyPlaylistList';
import { SpotifyPlaylistsSettings } from './SpotifyPlaylistsSettings';

export const SpotifyPlaylists: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getSpotifyPlaylistsLoading);
  const pagination = useSelector(getSpotifyPlaylistsPagination);

  const handleInit = useCallback(() => {
    dispatch(spotifyInitPlaylists.request());
  }, [dispatch]);

  useEffect(() => handleInit(), [handleInit]);

  return (
    <PaginatedContent
      pagination={pagination}
      loading={loading}
      onRetry={handleInit}
    >
      <SpotifyPlaylistsSettings />
      <SpotifyPlaylistList />
    </PaginatedContent>
  );
};
