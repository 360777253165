import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { PlaylistsCheckSuccess } from '../../../../components/Playlists/PlaylistsCheckSuccess';
import { Description } from '../../../../components/Description';
import { getSpotifyVisiblePlaylists } from '../../selectors';
import { SpotifyPlaylistItem } from './SpotifyPlaylistItem';
import { SpotifyPlaylistTracksDialog } from './SpotifyPlaylistTracksDialog';
import { SpotifyPlaylist } from '../../types';

export const SpotifyPlaylistList: FC = () => {
  const visiblePlaylists = useSelector(getSpotifyVisiblePlaylists);
  const { t } = useTranslation();
  const [playlist, setPlaylist] = useState<SpotifyPlaylist | null>(null);

  const handleSelectPlaylist = useCallback(
    (playlist) => {
      setPlaylist(playlist);
    },
    [setPlaylist],
  );

  const handleDeselectPlaylist = useCallback(
    () => setPlaylist(null),
    [setPlaylist],
  );

  return (
    <>
      {visiblePlaylists.length > 0 ? (
        <Box>
          <Description>{t('spotify.playlists.description')}</Description>
          {visiblePlaylists.map((playlist) => (
            <SpotifyPlaylistItem
              key={playlist.id}
              playlist={playlist}
              onSelect={handleSelectPlaylist}
            />
          ))}
          {playlist ? (
            <SpotifyPlaylistTracksDialog
              onClose={handleDeselectPlaylist}
              playlist={playlist}
            />
          ) : null}
        </Box>
      ) : (
        <PlaylistsCheckSuccess />
      )}
    </>
  );
};
