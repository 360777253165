import { userLogin, userLogout } from './actions';
import { logEvent } from 'firebase/analytics';
import { takeLatest, all, call } from 'redux-saga/effects';
import { analytics } from '../analytics';
import { FIREBASE_EVENT } from '../analytics/events';
import Cookies from 'js-cookie';
import { SPOTIFY_AUTH_TOKEN_KEY } from '../spotify/constants';
import { DEEZER_AUTH_TOKEN_KEY } from '../deezer/constants';
import { YOUTUBE_AUTH_TOKEN_KEY } from '../youtube/constants';

export function handleLogin() {
  logEvent(analytics, FIREBASE_EVENT.LOGIN_SUCCESS);
}

export function* watchUserLogin() {
  yield takeLatest(userLogin, handleLogin);
}

export function handleLogout() {
  Cookies.remove(SPOTIFY_AUTH_TOKEN_KEY);
  Cookies.remove(DEEZER_AUTH_TOKEN_KEY);
  Cookies.remove(YOUTUBE_AUTH_TOKEN_KEY);
}

export function* watchUserLogout() {
  yield takeLatest(userLogout, handleLogout);
}

export function* userSaga() {
  yield all([call(watchUserLogin), call(watchUserLogout)]);
}
