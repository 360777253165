import { YoutubeApi } from '../YoutubeApi';
import {
  YoutubeSubscription,
  YoutubeSubscriptionsFetchRequest,
  YoutubeSubscriptionsFetchResponse,
} from './types';
import {
  YOUTUBE_SUBSCRIPTIONS_CHUNK_SIZE,
  YOUTUBE_SUBSCRIPTIONS_URL,
} from './constants';

export const fetchYoutubeSubscriptionsRequest = async (
  request: YoutubeSubscriptionsFetchRequest,
): Promise<YoutubeSubscriptionsFetchResponse> => {
  const url = new URL(YOUTUBE_SUBSCRIPTIONS_URL);
  url.searchParams.append('part', 'id');
  url.searchParams.append('part', 'snippet');
  url.searchParams.append('mine', 'true');
  url.searchParams.append(
    'maxResults',
    YOUTUBE_SUBSCRIPTIONS_CHUNK_SIZE.toString(),
  );

  if (request.pageToken) {
    url.searchParams.append('pageToken', request.pageToken);
  }

  const response = await YoutubeApi.get(url.href);

  return response.data;
};

export const deleteYoutubeSubscriptionRequest = async (
  id: YoutubeSubscription['id'],
) => {
  const url = new URL(YOUTUBE_SUBSCRIPTIONS_URL);
  url.searchParams.append('id', id);

  const response = await YoutubeApi.delete(url.href);

  return response.data;
};
