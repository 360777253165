import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { UserState } from './types';

type Action = ActionType<typeof actions>;

const initialUserState: UserState = {};

export const UserReducer = createReducer<UserState, Action>(initialUserState)
  .handleAction(actions.userLogin, (state, action) => ({
    ...state,
    loggedPlatform: action.payload,
  }))
  .handleAction(actions.userLogout, (state) => ({
    ...state,
    loggedPlatform: undefined,
  }));
