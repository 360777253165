import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingState } from '../types';
import { Progress } from './Progress';
import { Loader } from './Loader';

interface ContentListProps {
  pagination: {
    total: number;
    offset: number;
  };
  loading: LoadingState;
  onRetry?: () => void;
}

export const PaginatedContent: FC<ContentListProps> = (props) => {
  const { pagination, loading, children, onRetry } = props;
  const { total, offset } = pagination;
  const { t } = useTranslation();

  if (loading === LoadingState.Failure) {
    return <Loader loading={loading} retry={onRetry} />;
  }

  return (
    <>
      {loading === LoadingState.Request ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Progress current={offset} total={total} />
          <Typography mt={1}>{t('analyze.loading')}</Typography>
        </Box>
      ) : (
        children
      )}
    </>
  );
};
