import React, { FC, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getYoutubeSubscriptionsPagination,
  getYoutubeSubscriptionsLoading,
} from '../../selectors';
import { PaginatedContent } from '../../../../components/PaginatedContent';
import { youtubeInitSubscriptions } from '../../actions';
import { YoutubeSubscriptionsList } from './YoutubeSubscriptionsList';
import { YoutubeSubscriptionsSettings } from './YoutubeSubscriptionsSettings';

export const YoutubeSubscriptions: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getYoutubeSubscriptionsLoading);
  const pagination = useSelector(getYoutubeSubscriptionsPagination);

  const handleInit = useCallback(() => {
    dispatch(youtubeInitSubscriptions.request());
  }, [dispatch]);

  useEffect(() => handleInit(), [handleInit]);

  return (
    <PaginatedContent
      pagination={pagination}
      loading={loading}
      onRetry={handleInit}
    >
      <YoutubeSubscriptionsSettings />
      <YoutubeSubscriptionsList />
    </PaginatedContent>
  );
};
