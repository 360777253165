import React, { FC, useCallback, useMemo } from 'react';
import { Box, List } from '@mui/material';
import {
  getDeezerLibraryDeleteLoading,
  getDeezerLibrarySelectedTracks,
  getDeezerLibraryVisibleTracks,
} from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { DeezerLibraryTrack } from './DeezerLibraryTrack';
import { useTranslation } from 'react-i18next';
import {
  deezerDeleteLibraryTracks,
  deezerDeselectLibraryAllTracks,
  deezerDeselectLibraryTrack,
  deezerSelectLibraryTracks,
} from '../../actions';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { DeezerTrack } from '../../../types';
import { LoadingState } from '../../../../types';
import DialogContentText from '@mui/material/DialogContentText';
import { LibraryCheckSuccess } from '../../../../components/Library/LibraryCheckSuccess';
import { ListActions } from '../../../../components/ListActions';
import { DeezerEvidenceDialog } from '../../../components/DeezerEvidenceDialog';
import { useSetItem } from '../../../../hooks/useSetItem';
import { useSelectItems } from '../../../../hooks/useSelectItems';
import { Description } from '../../../../components/Description';
import { DeleteLoading } from '../../../../components/DeleteLoading';

export const DeezerLibraryList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visibleTracks = useSelector(getDeezerLibraryVisibleTracks);
  const deleteLoading = useSelector(getDeezerLibraryDeleteLoading);
  const selectedTracks = useSelector(getDeezerLibrarySelectedTracks);
  const selectedTracksSet = useMemo(() => {
    return new Set(selectedTracks.map(({ id }) => id));
  }, [selectedTracks]);

  const {
    item: detailsTrack,
    setItem: setDetailsTrack,
    unsetItem: unsetDetailsTrack,
  } = useSetItem<DeezerTrack>();

  const {
    items: deleteTracks,
    selectItems: setDeleteTracks,
    deselectItems: unsetDeleteTracks,
    selectItem: setDeleteTrack,
  } = useSelectItems<DeezerTrack>();

  const handleDelete = useCallback(() => {
    if (deleteTracks) {
      dispatch(
        deezerDeleteLibraryTracks.request(deleteTracks.map(({ id }) => id)),
      );
    }
    unsetDeleteTracks();
  }, [dispatch, deleteTracks, unsetDeleteTracks]);

  const handleSelect = useCallback(
    (track) => {
      dispatch(deezerSelectLibraryTracks([track]));
    },
    [dispatch],
  );

  const handleDeselect = useCallback(
    (track) => {
      dispatch(deezerDeselectLibraryTrack(track));
    },
    [dispatch],
  );

  const handleSelectAll = useCallback(() => {
    dispatch(deezerSelectLibraryTracks(visibleTracks));
  }, [dispatch, visibleTracks]);

  const handleDeselectAll = useCallback(() => {
    dispatch(deezerDeselectLibraryAllTracks());
  }, [dispatch]);

  const handleDeleteSelected = useCallback(() => {
    setDeleteTracks(selectedTracks);
  }, [setDeleteTracks, selectedTracks]);

  return (
    <>
      {visibleTracks.length > 0 ? (
        <Box>
          <Description>{t('deezer.library.description')}</Description>
          {deleteLoading === LoadingState.Request ? (
            <DeleteLoading />
          ) : (
            <Box>
              <ListActions
                onDelete={handleDeleteSelected}
                onDeselectAll={handleDeselectAll}
                onSelectAll={handleSelectAll}
                selectedCount={selectedTracksSet.size}
                visibleCount={visibleTracks.length}
              />
              <List>
                {visibleTracks.map((track) => (
                  <DeezerLibraryTrack
                    onDetails={setDetailsTrack}
                    onDelete={setDeleteTrack}
                    key={track.id}
                    track={track}
                    onSelect={handleSelect}
                    onDeselect={handleDeselect}
                    selected={selectedTracksSet.has(track.id)}
                  />
                ))}
              </List>
            </Box>
          )}
          <ConfirmationDialog
            open={Boolean(deleteTracks)}
            onConfirm={handleDelete}
            onClose={unsetDeleteTracks}
            title={t('delete.confirmation.title')}
            confirmText={t('delete.confirmation.confirm')}
            cancelText={t('delete.confirmation.cancel')}
          >
            <DialogContentText>
              {t('deezer.library.delete.confirmation.content')}
            </DialogContentText>
          </ConfirmationDialog>
          {detailsTrack && (
            <DeezerEvidenceDialog
              track={detailsTrack}
              artist={detailsTrack.artist}
              onClose={unsetDetailsTrack}
            />
          )}
        </Box>
      ) : (
        <LibraryCheckSuccess />
      )}
    </>
  );
};
