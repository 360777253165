import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { SettingsState } from './types';
import { SETTINGS_SILENT_VISIBLE_KEY } from '../constants';
import { getStorageSettings } from './utils';

type Action = ActionType<typeof actions>;

const initialSettingsState: SettingsState = {
  silentVisible: getStorageSettings(SETTINGS_SILENT_VISIBLE_KEY),
};

export const SettingsReducer = createReducer<SettingsState, Action>(
  initialSettingsState,
)
  .handleAction(actions.settingsShowSilentArtists, (state) => ({
    ...state,
    silentVisible: true,
  }))
  .handleAction(actions.settingsHideSilentArtists, (state) => ({
    ...state,
    silentVisible: false,
  }));
