import React, { useCallback } from 'react';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { ReactComponent as SpotifySvg } from '../svg/spotify.svg';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../routes';

export const SpotifyButton = () => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => {
    navigate(AppRoute.spotify);
  }, [navigate]);

  return (
    <Tooltip title="Spotify">
      <IconButton onClick={handleNavigate}>
        <SvgIcon component={SpotifySvg} inheritViewBox />
      </IconButton>
    </Tooltip>
  );
};
