import React, { FC } from 'react';
import { DeezerArtist, DeezerTrack } from '../types';
import { EvidenceDialog } from '../../components/EvidenceDialog';
import { DEEZER_ARTISTS_CONFIG } from '../constants';

interface DeezerEvidenceDialogProps {
  track?: DeezerTrack;
  artist: DeezerArtist;
  onClose: () => void;
}

export const DeezerEvidenceDialog: FC<DeezerEvidenceDialogProps> = (props) => {
  const { artist, track, onClose } = props;

  return (
    <EvidenceDialog
      trackName={track?.title}
      entities={[
        {
          id: artist.id,
          name: artist.name,
          silent: DEEZER_ARTISTS_CONFIG[artist.id].silent,
          evidence: DEEZER_ARTISTS_CONFIG[artist.id].evidence,
        },
      ]}
      onClose={onClose}
      open
    />
  );
};
