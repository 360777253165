import { combineReducers } from 'redux';
import { SpotifyLibraryReducer } from '../library/SpotifyLibraryReducer';
import { SpotifyFollowedArtistsReducer } from '../followedArtists/SpotifyFollowedArtistsReducer';
import { SpotifyUserReducer } from '../user/SpotifyUserReducer';
import { SpotifyPlaylistsReducer } from '../playlists/SpotifyPlaylistsReducer';

export const SpotifyReducer = combineReducers({
  library: SpotifyLibraryReducer,
  followedArtists: SpotifyFollowedArtistsReducer,
  user: SpotifyUserReducer,
  playlists: SpotifyPlaylistsReducer,
});
