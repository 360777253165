import artistsConfig from './artists.json';
import bloggersConfig from './bloggers.json';
import { ArtistConfig, BloggerConfig, LoadingState, Platform } from './types';

export const mapPlatformIdsToBlogger = (platform: Platform.Youtube) => {
  return mapPlatformIdsToMediaPerson(
    platform,
    bloggersConfig as typeof artistsConfig,
  );
};

export const mapPlatformIdsToMediaPerson = (
  platform: Platform,
  config = artistsConfig,
): Record<string, ArtistConfig> | Record<string, BloggerConfig> => {
  return config.reduce((result, currentArtist) => {
    const platformData = currentArtist[platform];
    const artistValues = (platformData.ids as Array<string | number>).reduce(
      (previous: object, id: string | number) => {
        return {
          ...previous,
          [id]: currentArtist,
        };
      },
      {},
    );
    return {
      ...result,
      ...artistValues,
    };
  }, {});
};

export const getVisibleArtistsConfigList = (): Array<ArtistConfig> => {
  return artistsConfig.filter(({ image }) => Boolean(image));
};

export const getVisibleSilentArtistsConfigList = (): Array<ArtistConfig> => {
  return getVisibleArtistsConfigList().filter(({ silent }) => silent);
};

export const isLoadingOrIdle = (loading: LoadingState) => {
  return loading === LoadingState.Idle || loading === LoadingState.Request;
};

export const isLoadingOrFailed = (loading: LoadingState) => {
  return loading === LoadingState.Failure || loading === LoadingState.Request;
};

export const isLoadingSuccess = (loading: LoadingState) => {
  return loading === LoadingState.Success;
};
