import React, { FC, useCallback } from 'react';
import {
  getSpotifyLibraryDeleteLoading,
  getSpotifyLibrarySelectedTracks,
  getSpotifyLibraryVisibleTracks,
} from '../../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  spotifyDeleteLibraryTracks,
  spotifyDeselectLibraryAllTracks,
  spotifyDeselectLibraryTrack,
  spotifySelectLibraryTracks,
} from '../../actions';
import { SpotifyTracksList } from '../../../components/SpotifyTracksList';
import { LibraryCheckSuccess } from '../../../../components/Library/LibraryCheckSuccess';
import { SpotifyTrack } from '../../../types';

export const SpotifyLibraryList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const visibleTracks = useSelector(getSpotifyLibraryVisibleTracks);
  const deleteLoading = useSelector(getSpotifyLibraryDeleteLoading);
  const selectedTracks = useSelector(getSpotifyLibrarySelectedTracks);

  const handleDelete = useCallback(
    (tracks: SpotifyTrack[]) => {
      dispatch(spotifyDeleteLibraryTracks.request(tracks.map(({ id }) => id)));
    },
    [dispatch],
  );

  const handleSelect = useCallback(
    (tracks) => {
      dispatch(spotifySelectLibraryTracks(tracks));
    },
    [dispatch],
  );

  const handleDeselect = useCallback(
    (track) => {
      dispatch(spotifyDeselectLibraryTrack(track));
    },
    [dispatch],
  );

  const handleDeselectAll = useCallback(() => {
    dispatch(spotifyDeselectLibraryAllTracks());
  }, [dispatch]);

  return (
    <SpotifyTracksList
      description={t('spotify.library.description')}
      visibleTracks={visibleTracks}
      selectedTracks={selectedTracks}
      deleteLoading={deleteLoading}
      onDelete={handleDelete}
      onSelect={handleSelect}
      onDeselect={handleDeselect}
      onDeselectAll={handleDeselectAll}
      EmptyListComponent={<LibraryCheckSuccess />}
      deleteConfirmation={t('spotify.library.delete.confirmation.content')}
    />
  );
};
