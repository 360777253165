import Cookies from 'js-cookie';
import axios, { AxiosRequestConfig } from 'axios';
import { YOUTUBE_AUTH_TOKEN_KEY } from './constants';

export class YoutubeApi {
  private static getDefaultHeaders = () => {
    const token = Cookies.get(YOUTUBE_AUTH_TOKEN_KEY);
    return {
      Authorization: `Bearer ${token}`,
    };
  };

  private static async request(
    method: 'get' | 'delete',
    url: string,
    config?: AxiosRequestConfig,
  ) {
    return await axios[method](url, {
      ...config,
      headers: {
        ...(config?.headers || {}),
        ...YoutubeApi.getDefaultHeaders(),
      },
    });
  }

  static async get(url: string, config?: AxiosRequestConfig) {
    return YoutubeApi.request('get', url, config);
  }

  static async delete(url: string, config?: AxiosRequestConfig) {
    return YoutubeApi.request('delete', url, config);
  }
}
