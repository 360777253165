import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDeezerUserArtistsLoading,
  getDeezerUserArtistsPagination,
} from '../../selectors';
import { PaginatedContent } from '../../../../components/PaginatedContent';
import { deezerInitUserArtists } from '../../actions';
import { DeezerUserArtistsSettings } from './DeezerUserArtistsSettings';
import { DeezerUserArtistsList } from './DeezerUserArtistsList';

export const DeezerUserArtists: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getDeezerUserArtistsLoading);
  const pagination = useSelector(getDeezerUserArtistsPagination);

  const handleInit = useCallback(() => {
    dispatch(deezerInitUserArtists.request());
  }, [dispatch]);

  useEffect(() => handleInit(), [handleInit]);

  return (
    <PaginatedContent
      pagination={pagination}
      loading={loading}
      onRetry={handleInit}
    >
      <DeezerUserArtistsSettings />
      <DeezerUserArtistsList />
    </PaginatedContent>
  );
};
