import { YoutubeSubscriptionsState } from './types';
import { LoadingState } from '../../types';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { getYoutubeFilteredSubscriptions } from './utils';

type Action = ActionType<typeof actions>;

const initialSubscriptionsState: YoutubeSubscriptionsState = {
  subscriptions: [],
  loading: LoadingState.Idle,
  pagination: {
    total: 0,
    offset: 0,
  },
  selectedSubscriptions: [],
  deleteLoading: LoadingState.Idle,
};

export const YoutubeSubscriptionsReducer = createReducer<
  YoutubeSubscriptionsState,
  Action
>(initialSubscriptionsState)
  .handleAction(actions.youtubeFetchSubscriptions.success, (state, action) => ({
    ...state,
    subscriptions: [
      ...state.subscriptions,
      ...getYoutubeFilteredSubscriptions(action.payload.items),
    ],
    pagination: {
      total: action.payload.pageInfo.totalResults,
      offset: state.pagination.offset + action.payload.items.length,
      nextPageToken: action.payload.nextPageToken,
    },
  }))
  .handleAction(actions.youtubeInitSubscriptions.request, (state) => ({
    ...state,
    loading: LoadingState.Request,
    subscriptions: initialSubscriptionsState.subscriptions,
    pagination: initialSubscriptionsState.pagination,
    error: undefined,
  }))
  .handleAction(actions.youtubeInitSubscriptions.success, (state) => ({
    ...state,
    loading: LoadingState.Success,
  }))
  .handleAction(
    [
      actions.youtubeInitSubscriptions.failure,
      actions.youtubeFetchSubscriptions.failure,
    ],
    (state, action) => ({
      ...state,
      loading: LoadingState.Failure,
      error: action.payload,
    }),
  )
  .handleAction(actions.youtubeDeleteSubscriptions.request, (state) => ({
    ...state,
    deleteLoading: LoadingState.Request,
  }))
  .handleAction(actions.youtubeDeleteSubscriptions.failure, (state) => ({
    ...state,
    deleteLoading: LoadingState.Failure,
  }))
  .handleAction(actions.youtubeDeleteSubscriptions.success, (state, action) => {
    const subscriptionIds = action.payload.map(({ id }) => id);
    return {
      ...state,
      subscriptions: state.subscriptions.filter(
        ({ id }) => !subscriptionIds.includes(id),
      ),
      selectedSubscriptions: state.selectedSubscriptions.filter(
        ({ id }) => !subscriptionIds.includes(id),
      ),
      deleteLoading: LoadingState.Success,
    };
  })
  .handleAction(actions.youtubeSelectSubscriptions, (state, action) => ({
    ...state,
    selectedSubscriptions: [...state.selectedSubscriptions, ...action.payload],
  }))
  .handleAction(actions.youtubeDeselectSubscription, (state, action) => ({
    ...state,
    selectedSubscriptions: state.selectedSubscriptions.filter(
      (subscription) => subscription.id !== action.payload.id,
    ),
  }))
  .handleAction(actions.youtubeDeselectSubscriptionsAll, (state) => ({
    ...state,
    selectedSubscriptions: [],
  }));
