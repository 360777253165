import React, { FC, useCallback } from 'react';
import { Box, Switch, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getSettingsSilentVisible } from '../settings/selectors';
import {
  settingsHideSilentArtists,
  settingsShowSilentArtists,
} from '../settings/actions';

const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.grey.A400,
    },
  },
}));

interface SettingsProps {
  silentTotal: number;
}

export const Settings: FC<SettingsProps> = (props) => {
  const { silentTotal } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const silentVisible = useSelector(getSettingsSilentVisible);

  const handleSilentToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        dispatch(settingsShowSilentArtists());
      } else {
        dispatch(settingsHideSilentArtists());
      }
    },
    [dispatch],
  );

  if (!silentTotal) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" mb={2} mt={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Box display="column">
          <Typography fontWeight="bold">{t('silent.hint')}</Typography>
          {silentTotal ? (
            <Typography fontSize="small" color="gray">
              {t('settings.silent.hint', {
                count: silentTotal,
              })}
            </Typography>
          ) : null}
        </Box>
        <Switch
          className={classes.switch}
          checked={silentVisible}
          onChange={handleSilentToggle}
          color="error"
        />
      </Box>
    </Box>
  );
};
