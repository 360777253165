import React, { useCallback } from 'react';
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { useListItemStyles } from '../styles/listItemStyles';

interface LibraryRemovableEntityProps<T> {
  title: string;
  owner: string;
  selected: boolean;
  onDelete: (entity: T) => void;
  onSelect: (entity: T) => void;
  onDeselect: (entity: T) => void;
  onDetails: (entity: T) => void;
  coverUrl: string;
  coverDescription: string;
  entity: T;
}

export const LibraryRemovableEntity = <T extends object>(
  props: LibraryRemovableEntityProps<T>,
) => {
  const {
    onDelete,
    selected,
    onSelect,
    onDeselect,
    onDetails,
    title,
    owner,
    coverDescription,
    coverUrl,
    entity,
  } = props;
  const listItemClasses = useListItemStyles();

  const handleSelectChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      checked ? onSelect(entity) : onDeselect(entity);
    },
    [onSelect, onDeselect, entity],
  );
  const handleDelete = useCallback(() => onDelete(entity), [entity, onDelete]);
  const handleDetails = useCallback(
    () => onDetails(entity),
    [entity, onDetails],
  );

  return (
    <ListItem
      alignItems="flex-start"
      className={listItemClasses.wrapper}
      secondaryAction={
        <Box alignItems="center">
          <IconButton edge="end" aria-label="delete" onClick={handleDelete}>
            <DeleteIcon color="error" />
          </IconButton>
          <Checkbox
            className={listItemClasses.checkBox}
            color="error"
            checked={selected}
            onChange={handleSelectChange}
          />
        </Box>
      }
    >
      <Box display="flex" alignItems="center" mt={1}>
        <IconButton onClick={handleDetails}>
          <InfoIcon color="primary" />
        </IconButton>
      </Box>
      <ListItemAvatar>
        <Avatar variant="square" alt={coverDescription} src={coverUrl} />
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={owner}
        secondaryTypographyProps={{
          color: 'error',
        }}
      />
    </ListItem>
  );
};
