import React, { useCallback } from 'react';
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { useListItemStyles } from '../styles/listItemStyles';

interface UserArtistProps<T> {
  name: string;
  selected: boolean;
  onDelete: (artist: T) => void;
  onSelect: (artist: T) => void;
  onDeselect: (artist: T) => void;
  onDetails: (artist: T) => void;
  picture: string;
  artist: T;
}

export const UserArtist = <T extends object>(props: UserArtistProps<T>) => {
  const {
    onDelete,
    selected,
    onSelect,
    onDeselect,
    onDetails,
    artist,
    name,
    picture,
  } = props;
  const listItemClasses = useListItemStyles();

  const handleSelectChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      checked ? onSelect(artist) : onDeselect(artist);
    },
    [onSelect, onDeselect, artist],
  );
  const handleDelete = useCallback(() => onDelete(artist), [artist, onDelete]);
  const handleDetails = useCallback(
    () => onDetails(artist),
    [artist, onDetails],
  );

  return (
    <ListItem
      alignItems="flex-start"
      className={listItemClasses.wrapper}
      secondaryAction={
        <Box alignItems="center">
          <IconButton edge="end" aria-label="delete" onClick={handleDelete}>
            <DeleteIcon color="error" />
          </IconButton>
          <Checkbox
            className={listItemClasses.checkBox}
            color="error"
            checked={selected}
            onChange={handleSelectChange}
          />
        </Box>
      }
    >
      <Box display="flex" alignItems="center" mt={1}>
        <IconButton onClick={handleDetails}>
          <InfoIcon color="primary" />
        </IconButton>
      </Box>
      <ListItemAvatar>
        <Avatar variant="square" alt={name} src={picture} />
      </ListItemAvatar>
      <ListItemText primary={name} />
    </ListItem>
  );
};
