import React, { FC, memo, useMemo } from 'react';
import { LibraryRemovableEntity } from '../../../../components/Library/LibraryRemovableEntity';
import { SpotifyTrack } from '../../../types';
import { spotifyImageByIndex } from '../../../utils';

interface SpotifyLibraryTrackProps {
  track: SpotifyTrack;
  onDelete: (track: SpotifyTrack) => void;
  onSelect: (track: SpotifyTrack) => void;
  onDeselect: (track: SpotifyTrack) => void;
  onDetails: (track: SpotifyTrack) => void;
  selected: boolean;
}

export const SpotifyLibraryTrack: FC<SpotifyLibraryTrackProps> = memo(
  (props) => {
    const { track, onDelete, selected, onSelect, onDeselect, onDetails } =
      props;
    const { name, album, artists } = track;

    const artistsName = useMemo(() => {
      return artists.map(({ name }) => name).join(', ');
    }, [artists]);

    return (
      <LibraryRemovableEntity
        entity={track}
        coverDescription={album.name}
        owner={artistsName}
        title={name}
        selected={selected}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onDelete={onDelete}
        onDetails={onDetails}
        coverUrl={spotifyImageByIndex(album.images, album.images.length - 1)}
      />
    );
  },
);

SpotifyLibraryTrack.displayName = 'SpotifyLibraryTrack';
