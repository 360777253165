import artists from './artists.json';
import bloggers from './bloggers.json';

export enum Platform {
  Spotify = 'spotify',
  Deezer = 'deezer',
  Youtube = 'youtube',
}

export enum LoadingState {
  Idle = 'Idle',
  Request = 'Request',
  Success = 'Success',
  Failure = 'Failure',
}

export interface EntityEvidence {
  links?: {
    href: string;
    label: string;
  }[];
  quotes?: string[];
  concerts?: string[];
  propaganda?: boolean;
}

export enum EntityType {
  Channel = 'channel',
  Artist = 'artist',
}

export type ArtistConfig = typeof artists[number];
export type BloggerConfig = typeof bloggers[number];
export type MediaPersonConfig = Partial<ArtistConfig & BloggerConfig>;
