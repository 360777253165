import { SpotifyApi } from '../SpotifyApi';
import {
  SpotifyFollowedArtistsFetchRequest,
  SpotifyFollowedArtistsFetchResponse,
} from './types';

const ENTITY_TYPE = 'artist';

export const fetchSpotifyFollowedArtistsRequest = async ({
  limit,
  after,
}: SpotifyFollowedArtistsFetchRequest): Promise<SpotifyFollowedArtistsFetchResponse> => {
  const response = await SpotifyApi.get(
    'https://api.spotify.com/v1/me/following',
    {
      params: {
        limit,
        after,
        type: ENTITY_TYPE,
      },
    },
  );

  const {
    artists: { items, total, cursors },
  } = response.data;

  return {
    total,
    after: cursors.after,
    artists: items,
  };
};

export const deleteSpotifyFollowedArtistsRequest = async (ids: string[]) => {
  const response = await SpotifyApi.delete(
    'https://api.spotify.com/v1/me/following',
    {
      params: {
        type: ENTITY_TYPE,
      },
      data: {
        ids,
      },
    },
  );
  return response.data;
};
