import { YoutubeDialogsState } from './types';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';

type Action = ActionType<typeof actions>;

const initialDialogsState: YoutubeDialogsState = {
  quotaLimitDialog: false,
};

export const YoutubeDialogsReducer = createReducer<YoutubeDialogsState, Action>(
  initialDialogsState,
)
  .handleAction(actions.youtubeQuotaLimitDialogShow, (state) => ({
    ...state,
    quotaLimitDialog: true,
  }))
  .handleAction(actions.youtubeQuotaLimitDialogClose, (state) => ({
    ...state,
    quotaLimitDialog: false,
  }));
