import { all, call } from 'redux-saga/effects';
import {
  watchSpotifyFetchPlaylists,
  watchSpotifyInitPlaylist,
  watchSpotifyInitPlaylists,
  watchSpotifyFetchPlaylistTracks,
  watchSpotifyDeletePlaylistTracks,
} from './sagas';

export function* spotifyPlaylistsSaga() {
  yield all([
    call(watchSpotifyInitPlaylists),
    call(watchSpotifyFetchPlaylists),
    call(watchSpotifyInitPlaylist),
    call(watchSpotifyFetchPlaylistTracks),
    call(watchSpotifyDeletePlaylistTracks),
  ]);
}
