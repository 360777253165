import React, { FC, useCallback, useEffect } from 'react';
import { DeezerLibrarySettings } from './DeezerLibrarySettings';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDeezerLibraryLoading,
  getDeezerLibraryPagination,
} from '../../selectors';
import { DeezerLibraryList } from './DeezerLibraryList';
import { PaginatedContent } from '../../../../components/PaginatedContent';
import { deezerInitLibrary } from '../../actions';

export const DeezerLibrary: FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getDeezerLibraryLoading);
  const pagination = useSelector(getDeezerLibraryPagination);

  const handleInit = useCallback(() => {
    dispatch(deezerInitLibrary.request());
  }, [dispatch]);

  useEffect(() => handleInit(), [handleInit]);

  return (
    <PaginatedContent
      pagination={pagination}
      loading={loading}
      onRetry={handleInit}
    >
      <DeezerLibrarySettings />
      <DeezerLibraryList />
    </PaginatedContent>
  );
};
