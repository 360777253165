import React, { FC, useState } from 'react';
import { Content } from '../../components/Content';
import { DeezerLibrary } from '../library/components/DeezerLibrary';
import { DeezerUserArtists } from '../userArtists/components/DeezerUserArtists';
import { ContentTabs } from '../../components/ContentTabs/ContentTabs';
import { useTranslation } from 'react-i18next';
import { ContentTabPanel } from '../../components/ContentTabs/ContentTabPanel';
import { useSelector } from 'react-redux';
import { getDeezerLibraryVisibleTracks } from '../library/selectors';
import { getDeezerUserArtistsVisible } from '../userArtists/selectors';
import { SilentArtistsConfirmationDialog } from '../../components/SilentArtistsConfirmationDialog';

enum DeezerTabs {
  Library = 'library',
  UserArtists = 'userArtists',
}

export const DeezerContent: FC = () => {
  const [tab, setTab] = useState(DeezerTabs.Library);
  const { t } = useTranslation();

  const libraryTracks = useSelector(getDeezerLibraryVisibleTracks);
  const userArtists = useSelector(getDeezerUserArtistsVisible);

  return (
    <Content>
      <ContentTabs
        value={tab}
        onChange={setTab}
        tabs={[
          {
            label: t('tabs.library'),
            value: DeezerTabs.Library,
            badge: libraryTracks.length,
          },
          {
            label: t('tabs.artists'),
            value: DeezerTabs.UserArtists,
            badge: userArtists.length,
          },
        ]}
      />
      <ContentTabPanel value={DeezerTabs.Library} currentValue={tab}>
        <DeezerLibrary />
      </ContentTabPanel>
      <ContentTabPanel value={DeezerTabs.UserArtists} currentValue={tab}>
        <DeezerUserArtists />
      </ContentTabPanel>
      <SilentArtistsConfirmationDialog />
    </Content>
  );
};
