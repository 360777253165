import { SpotifyLibraryState } from './types';
import { LoadingState } from '../../types';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { getSpotifyFilteredLibraryTracks } from '../utils';

type Action = ActionType<typeof actions>;

const initialLibraryState: SpotifyLibraryState = {
  tracks: [],
  loading: LoadingState.Idle,
  pagination: {
    total: 0,
    offset: 0,
  },
  selectedTracks: [],
  deleteLoading: LoadingState.Idle,
};

export const SpotifyLibraryReducer = createReducer<SpotifyLibraryState, Action>(
  initialLibraryState,
)
  .handleAction(actions.spotifyFetchLibraryTracks.success, (state, action) => ({
    ...state,
    tracks: [
      ...state.tracks,
      ...getSpotifyFilteredLibraryTracks(action.payload.tracks),
    ],
    pagination: {
      total: action.payload.total,
      offset: state.pagination.offset + action.payload.tracks.length,
    },
  }))
  .handleAction(actions.spotifyInitLibrary.request, (state) => ({
    ...state,
    loading: LoadingState.Request,
    tracks: initialLibraryState.tracks,
    pagination: initialLibraryState.pagination,
    error: undefined,
  }))
  .handleAction(actions.spotifyInitLibrary.success, (state) => ({
    ...state,
    loading: LoadingState.Success,
  }))
  .handleAction(
    [
      actions.spotifyInitLibrary.failure,
      actions.spotifyFetchLibraryTracks.failure,
    ],
    (state, action) => ({
      ...state,
      loading: LoadingState.Failure,
      error: action.payload,
    }),
  )
  .handleAction(actions.spotifyDeleteLibraryTracks.request, (state) => ({
    ...state,
    deleteLoading: LoadingState.Request,
  }))
  .handleAction(actions.spotifyDeleteLibraryTracks.failure, (state) => ({
    ...state,
    deleteLoading: LoadingState.Failure,
  }))
  .handleAction(
    actions.spotifyDeleteLibraryTracks.success,
    (state, action) => ({
      ...state,
      tracks: state.tracks.filter(({ id }) => !action.payload.includes(id)),
      selectedTracks: state.selectedTracks.filter(
        ({ id }) => !action.payload.includes(id),
      ),
      deleteLoading: LoadingState.Success,
    }),
  )
  .handleAction(actions.spotifySelectLibraryTracks, (state, action) => ({
    ...state,
    selectedTracks: [...state.selectedTracks, ...action.payload],
  }))
  .handleAction(actions.spotifyDeselectLibraryTrack, (state, action) => ({
    ...state,
    selectedTracks: state.selectedTracks.filter(
      (track) => track.id !== action.payload.id,
    ),
  }))
  .handleAction(actions.spotifyDeselectLibraryAllTracks, (state) => ({
    ...state,
    selectedTracks: [],
  }));
