import { LoadingState } from '../../types';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { DeezerUserArtistsState } from './types';
import { getDeezerFilteredUserArtists } from './utils';

type Action = ActionType<typeof actions>;

const initialUserArtistsState: DeezerUserArtistsState = {
  artists: [],
  loading: LoadingState.Idle,
  pagination: {
    offset: 0,
    total: 0,
  },
  selectedArtists: [],
  deleteLoading: LoadingState.Idle,
};

export const DeezerUserArtistsReducer = createReducer<
  DeezerUserArtistsState,
  Action
>(initialUserArtistsState)
  .handleAction(actions.deezerFetchUserArtists.success, (state, action) => ({
    ...state,
    artists: [
      ...state.artists,
      ...getDeezerFilteredUserArtists(action.payload.artists),
    ],
    pagination: {
      total: action.payload.total,
      offset: state.pagination.offset + action.payload.artists.length,
      next: action.payload.next,
    },
  }))
  .handleAction(actions.deezerInitUserArtists.request, (state) => ({
    ...state,
    loading: LoadingState.Request,
    artists: initialUserArtistsState.artists,
    pagination: initialUserArtistsState.pagination,
    error: undefined,
  }))
  .handleAction(actions.deezerInitUserArtists.success, (state) => ({
    ...state,
    loading: LoadingState.Success,
  }))
  .handleAction(
    [
      actions.deezerInitUserArtists.failure,
      actions.deezerFetchUserArtists.failure,
    ],
    (state, action) => ({
      ...state,
      loading: LoadingState.Failure,
      error: action.payload,
    }),
  )
  .handleAction(actions.deezerDeleteUserArtists.request, (state) => ({
    ...state,
    deleteLoading: LoadingState.Request,
  }))
  .handleAction(actions.deezerDeleteUserArtists.failure, (state) => ({
    ...state,
    deleteLoading: LoadingState.Failure,
  }))
  .handleAction(actions.deezerDeleteUserArtists.success, (state, action) => ({
    ...state,
    artists: state.artists.filter(({ id }) => !action.payload.includes(id)),
    selectedArtists: state.selectedArtists.filter(
      ({ id }) => !action.payload.includes(id),
    ),
    deleteLoading: LoadingState.Success,
  }))
  .handleAction(actions.deezerSelectUserArtists, (state, action) => ({
    ...state,
    selectedArtists: [...state.selectedArtists, ...action.payload],
  }))
  .handleAction(actions.deezerDeselectUserArtist, (state, action) => ({
    ...state,
    selectedArtists: state.selectedArtists.filter(
      (artist) => artist.id !== action.payload.id,
    ),
  }))
  .handleAction(actions.deezerDeselectUserAllArtists, (state) => ({
    ...state,
    selectedArtists: [],
  }));
