import axios, { AxiosRequestConfig } from 'axios';
import { DEEZER_AUTH_TOKEN_KEY } from './constants';
import Cookies from 'js-cookie';

export class DeezerApi {
  private static async request(
    method: 'get' | 'delete',
    url: string,
    config?: AxiosRequestConfig,
  ) {
    const finalUrl = new URL(url);

    if (!finalUrl.searchParams.has('access_token')) {
      finalUrl.searchParams.set(
        'access_token',
        Cookies.get(DEEZER_AUTH_TOKEN_KEY) || '',
      );
    }

    return await axios[method](
      `${process.env.REACT_APP_PROXY}?url=${encodeURIComponent(finalUrl.href)}`,
      config,
    );
  }

  static async get(url: string, config?: AxiosRequestConfig) {
    return DeezerApi.request('get', url, config);
  }

  static async delete(url: string, config?: AxiosRequestConfig) {
    return DeezerApi.request('delete', url, config);
  }
}
