import { all, call } from 'redux-saga/effects';
import {
  watchDeezerDeleteUserArtists,
  watchDeezerFetchUserArtists,
  watchDeezerInitUserArtists,
} from './sagas';

export function* deezerUserArtistsSaga() {
  yield all([
    call(watchDeezerDeleteUserArtists),
    call(watchDeezerFetchUserArtists),
    call(watchDeezerInitUserArtists),
  ]);
}
