import { createSelector } from 'reselect';
import {
  getDeezerNonSilentUserArtists,
  isDeezerSilentUserArtist,
} from './utils';
import { getState } from '../../store/selectors';
import { getSettingsSilentVisible } from '../../settings/selectors';

const getDeezer = createSelector(getState, ({ deezer }) => deezer);

export const getDeezerUserArtists = createSelector(
  getDeezer,
  ({ userArtists }) => userArtists,
);
export const getDeezerUserArtistsPagination = createSelector(
  getDeezerUserArtists,
  ({ pagination }) => pagination,
);

export const getDeezerUserArtistsList = createSelector(
  getDeezerUserArtists,
  ({ artists }) => artists,
);

export const getDeezerUserArtistsVisible = createSelector(
  getDeezerUserArtistsList,
  getSettingsSilentVisible,
  (artists, silentVisible) => {
    return silentVisible ? artists : getDeezerNonSilentUserArtists(artists);
  },
);

export const getDeezerUserArtistsSilent = createSelector(
  getDeezerUserArtists,
  ({ artists }) => artists.filter(isDeezerSilentUserArtist),
);

export const getDeezerUserArtistsLoading = createSelector(
  getDeezerUserArtists,
  ({ loading }) => loading,
);

export const getDeezerUserArtistsDeleteLoading = createSelector(
  getDeezerUserArtists,
  ({ deleteLoading }) => deleteLoading,
);

export const getDeezerUserArtistsSelected = createSelector(
  getDeezerUserArtists,
  getSettingsSilentVisible,
  ({ selectedArtists }, silentVisible) => {
    return silentVisible
      ? selectedArtists
      : getDeezerNonSilentUserArtists(selectedArtists);
  },
);
