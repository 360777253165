import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Content } from '../../components/Content';
import { ContentTabs } from '../../components/ContentTabs/ContentTabs';
import { ContentTabPanel } from '../../components/ContentTabs/ContentTabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { getSpotifyLibraryVisibleTracks } from '../library/selectors';
import { getSpotifyFollowedArtistsVisible } from '../followedArtists/selectors';
import { SpotifyLibrary } from '../library/components/SpotifyLibrary';
import { SpotifyFollowedArtists } from '../followedArtists/components/SpotifyFollowedArtists';
import { SpotifyPlaylists } from '../playlists/components/SpotifyPlaylists';
import { spotifyInitUser } from '../user/actions';
import { getSpotifyUserLoading } from '../user/selectors';
import { isLoadingSuccess } from '../../utils';
import { Loader } from '../../components/Loader';
import { getSpotifyPlaylistsTracksTotal } from '../playlists/selectors';
import { SilentArtistsConfirmationDialog } from '../../components/SilentArtistsConfirmationDialog';

enum SpotifyTabs {
  Library = 'library',
  FollowedArtists = 'followedArtists',
  Playlists = 'playlists',
}

export const SpotifyContent: FC = () => {
  const [tab, setTab] = useState(SpotifyTabs.Library);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const libraryTracks = useSelector(getSpotifyLibraryVisibleTracks);
  const followedArtists = useSelector(getSpotifyFollowedArtistsVisible);
  const playlistsTracks = useSelector(getSpotifyPlaylistsTracksTotal);
  const userLoading = useSelector(getSpotifyUserLoading);

  const handleInit = useCallback(() => {
    dispatch(spotifyInitUser.request());
  }, [dispatch]);

  useEffect(() => {
    handleInit();
  }, [handleInit]);

  return (
    <Content>
      {!isLoadingSuccess(userLoading) ? (
        <Loader loading={userLoading} retry={handleInit} />
      ) : (
        <>
          <ContentTabs
            value={tab}
            onChange={setTab}
            tabs={[
              {
                label: t('tabs.library'),
                value: SpotifyTabs.Library,
                badge: libraryTracks.length,
              },
              {
                label: t('tabs.artists'),
                value: SpotifyTabs.FollowedArtists,
                badge: followedArtists.length,
              },
              {
                label: t('tabs.playlists'),
                value: SpotifyTabs.Playlists,
                badge: playlistsTracks.visibleTotal,
              },
            ]}
          />
          <ContentTabPanel value={SpotifyTabs.Library} currentValue={tab}>
            <SpotifyLibrary />
          </ContentTabPanel>
          <ContentTabPanel
            value={SpotifyTabs.FollowedArtists}
            currentValue={tab}
          >
            <SpotifyFollowedArtists />
          </ContentTabPanel>
          <ContentTabPanel value={SpotifyTabs.Playlists} currentValue={tab}>
            <SpotifyPlaylists />
          </ContentTabPanel>
        </>
      )}
      <SilentArtistsConfirmationDialog />
    </Content>
  );
};
