import { NotifiableError } from '@bugsnag/core/types/common';
import {
  spotifyDeleteLibraryTracks,
  spotifyFetchLibraryTracks,
  spotifyInitLibrary,
} from '../actions';
import { logEvent } from 'firebase/analytics';
import { ActionType } from 'typesafe-actions';
import { takeLatest, take, call, put, select } from 'redux-saga/effects';
import { chunk } from 'lodash';
import {
  deleteSpotifyLibraryTracksRequest,
  fetchSpotifyLibraryRequest,
} from '../api';
import {
  SPOTIFY_LIBRARY_CHUNK_SIZE,
  SPOTIFY_LIBRARY_DELETE_CHUNK_SIZE,
} from '../constants';
import { SpotifyLibraryFetchResponse } from '../types';
import { getSpotifyLibraryPagination } from '../selectors';
import { analytics } from '../../../analytics';
import { FIREBASE_EVENT } from '../../../analytics/events';
import { handleError } from '../../../errors';
import { Platform } from '../../../types';
import { SpotifyPagination } from '../../types';

function* handleSpotifyInitLibrary() {
  let pagination: SpotifyPagination = yield select(getSpotifyLibraryPagination);
  try {
    do {
      yield put(
        spotifyFetchLibraryTracks.request({
          limit: SPOTIFY_LIBRARY_CHUNK_SIZE,
          offset: pagination.offset,
        }),
      );
      yield take(spotifyFetchLibraryTracks.success);
      pagination = yield select(getSpotifyLibraryPagination);
    } while (pagination.offset < pagination.total);
    yield put(spotifyInitLibrary.success());
    logEvent(analytics, FIREBASE_EVENT.LIBRARY_INIT_SUCCESS);
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.LIBRARY_INIT_FAILURE,
    });
    yield put(spotifyInitLibrary.failure(error));
  }
}

export function* watchSpotifyInitLibrary() {
  yield takeLatest(spotifyInitLibrary.request, handleSpotifyInitLibrary);
}

function* handleSpotifyFetchLibraryTracks(
  action: ActionType<typeof spotifyFetchLibraryTracks.request>,
) {
  try {
    const data: SpotifyLibraryFetchResponse = yield call(
      fetchSpotifyLibraryRequest,
      action.payload,
    );
    yield put(spotifyFetchLibraryTracks.success(data));
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.LIBRARY_FETCH_TRACKS_FAILURE,
    });
    yield put(spotifyFetchLibraryTracks.failure(error));
  }
}

export function* watchSpotifyFetchLibraryTracks() {
  yield takeLatest(
    spotifyFetchLibraryTracks.request,
    handleSpotifyFetchLibraryTracks,
  );
}

function* handleSpotifyDeleteLibraryTracks(
  action: ActionType<typeof spotifyDeleteLibraryTracks.request>,
) {
  try {
    const trackChunks = chunk(
      action.payload,
      SPOTIFY_LIBRARY_DELETE_CHUNK_SIZE,
    );

    for (const chunk of trackChunks) {
      yield call(deleteSpotifyLibraryTracksRequest, chunk);
      logEvent(analytics, FIREBASE_EVENT.LIBRARY_TRACK_DELETE_SUCCESS, {
        deletedCount: chunk.length,
        platform: Platform.Spotify,
      });
    }
    yield put(spotifyDeleteLibraryTracks.success(action.payload));
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.LIBRARY_TRACK_DELETE_FAILURE,
    });
    yield put(spotifyDeleteLibraryTracks.failure(error));
  }
}

export function* watchSpotifyDeleteLibraryTracks() {
  yield takeLatest(
    spotifyDeleteLibraryTracks.request,
    handleSpotifyDeleteLibraryTracks,
  );
}
