import React, { FC } from 'react';
import { Content } from '../../components/Content';
import { SilentArtistsConfirmationDialog } from '../../components/SilentArtistsConfirmationDialog';
import { YoutubeSubscriptions } from '../subscriptions/components/YoutubeSubscriptions';
import { YoutubeQuotaLimitDialog } from '../dialogs/components/YoutubeQuotaLimitDialog';

export const YoutubeContent: FC = () => {
  return (
    <Content>
      <YoutubeSubscriptions />
      <SilentArtistsConfirmationDialog />
      <YoutubeQuotaLimitDialog />
    </Content>
  );
};
