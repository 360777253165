import { intersection } from 'lodash';
import { SpotifyImage, SpotifyTrack } from './types';
import { EMPTY_IMAGE_URL } from '../constants';
import { SPOTIFY_ARTISTS_CONFIG } from './constants';

export const spotifyImageByIndex = (
  images: SpotifyImage[],
  index: number | undefined,
): string => {
  if (index === undefined || images.length <= 0 || index > images.length - 1) {
    return EMPTY_IMAGE_URL;
  }

  return images[index].url;
};

export const getSpotifyFilteredLibraryTracks = (
  tracks: SpotifyTrack[],
): SpotifyTrack[] => {
  return tracks.filter((track) => {
    const artistIds = Object.keys(SPOTIFY_ARTISTS_CONFIG);
    return (
      intersection(
        artistIds,
        (track.artists || []).map(({ id }) => id),
      ).length > 0
    );
  });
};

export const isSpotifySilentTrack = (track: SpotifyTrack): boolean => {
  const silentFlags = track.artists
    .filter(({ id }) => id in SPOTIFY_ARTISTS_CONFIG)
    .map(({ id }) => SPOTIFY_ARTISTS_CONFIG[id].silent);

  return silentFlags.length === 0 ? false : !silentFlags.includes(false);
};

export const getSpotifyNonSilentLibraryTracks = (
  tracks: SpotifyTrack[],
): SpotifyTrack[] => {
  return tracks.filter((track) => !isSpotifySilentTrack(track));
};
