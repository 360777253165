import { Box } from '@mui/material';
import React from 'react';
import { useErrorBoundary } from 'use-error-boundary';
import { ErrorMessage } from '../ErrorMessage';

interface ContentTabPanelProps<T> {
  value: T;
  currentValue: T;
  children: React.ReactNode;
}

export const ContentTabPanel = <T extends string>(
  props: ContentTabPanelProps<T>,
) => {
  const { value, currentValue, children } = props;
  const { ErrorBoundary, didCatch, error } = useErrorBoundary();

  return (
    <Box hidden={value !== currentValue}>
      <>
        {didCatch ? (
          <ErrorMessage message={error.message} />
        ) : (
          <ErrorBoundary>{children}</ErrorBoundary>
        )}
      </>
    </Box>
  );
};
