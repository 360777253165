import { Box, Theme, Typography } from '@mui/material';
import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(9),
    width: '100%',
    overflow: 'hidden',
  },
  section: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: theme.spacing(2),
  },
  sectionImage: {
    maxWidth: 250,
    margin: theme.spacing(2),
  },
  sectionTitle: {
    '&.MuiTypography-root': {
      color: theme.palette.grey['600'],
      fontWeight: 'bold',
      textTransform: 'uppercase',
      margin: theme.spacing(2),
      width: 300,
      textAlign: 'center',
    },
  },
}));

export const HowItWorks: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.wrapper}>
      <Typography
        fontSize="large"
        fontWeight="bold"
        textTransform="uppercase"
        textAlign="center"
      >
        {t('page.main.steps')}
      </Typography>
      <Box className={classes.section}>
        <Typography fontSize="large" className={classes.sectionTitle}>
          1. {t('page.main.step1')}
        </Typography>
        <img
          alt={t('page.main.step1')}
          className={classes.sectionImage}
          src="/images/screenList.png"
        />
      </Box>
      <Box className={classes.section}>
        <Typography fontSize="large" className={classes.sectionTitle}>
          2. {t('page.main.step2')}
        </Typography>
        <img
          alt={t('page.main.step2')}
          className={classes.sectionImage}
          src="/images/screenConfirm.png"
        />
      </Box>
      <Box className={classes.section}>
        <Typography fontSize="large" className={classes.sectionTitle}>
          3. {t('page.main.step3')}
        </Typography>
        <img
          alt={t('page.main.step3')}
          className={classes.sectionImage}
          src="/images/screenSuccess.png"
        />
      </Box>
    </Box>
  );
};
