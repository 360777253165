import { DeezerArtist } from '../types';
import { DEEZER_ARTISTS_CONFIG } from '../constants';

export const getDeezerFilteredUserArtists = (
  artists: DeezerArtist[],
): DeezerArtist[] => {
  return artists.filter((artist) => artist.id in DEEZER_ARTISTS_CONFIG);
};

export const isDeezerSilentUserArtist = (artist: DeezerArtist): boolean => {
  return DEEZER_ARTISTS_CONFIG[artist.id].silent;
};

export const getDeezerNonSilentUserArtists = (
  artists: DeezerArtist[],
): DeezerArtist[] => {
  return artists.filter((artist) => !isDeezerSilentUserArtist(artist));
};
