import {
  SpotifyPlaylist,
  SpotifyPlaylistsState,
  SpotifyPlaylistState,
} from './types';
import { LoadingState } from '../../types';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { getSpotifyFilteredLibraryTracks } from '../utils';

type Action = ActionType<typeof actions>;

const initialLibraryState: SpotifyPlaylistsState = {
  playlists: [],
  playlistsState: {},
  loading: LoadingState.Idle,
  pagination: {
    total: 0,
    offset: 0,
  },
};

const getInitialPlaylistState = (): SpotifyPlaylistState => ({
  selectedTracks: [],
  tracks: [],
  loading: LoadingState.Idle,
  deleteLoading: LoadingState.Idle,
  pagination: {
    total: 0,
    offset: 0,
  },
});

const updatePlaylistsState = (
  state: SpotifyPlaylistsState,
  playlistId: SpotifyPlaylist['id'],
  update: Partial<SpotifyPlaylistState>,
) => ({
  ...state.playlistsState,
  [playlistId]: {
    ...state.playlistsState[playlistId],
    ...update,
  },
});

export const SpotifyPlaylistsReducer = createReducer<
  SpotifyPlaylistsState,
  Action
>(initialLibraryState)
  .handleAction(actions.spotifyFetchPlaylists.success, (state, action) => ({
    ...state,
    playlists: [...state.playlists, ...action.payload.playlists],
    playlistsState: action.payload.playlists.reduce(
      (prev, current) => ({
        ...prev,
        [current.id]: getInitialPlaylistState(),
      }),
      { ...state.playlistsState },
    ),
    pagination: {
      total: action.payload.total,
      offset: state.pagination.offset + action.payload.playlists.length,
    },
  }))
  .handleAction(actions.spotifyInitPlaylists.request, (state) => ({
    ...state,
    loading: LoadingState.Request,
    playlists: initialLibraryState.playlists,
    pagination: initialLibraryState.pagination,
    error: undefined,
  }))
  .handleAction(actions.spotifyInitPlaylists.success, (state) => ({
    ...state,
    loading: LoadingState.Success,
  }))
  .handleAction(
    [
      actions.spotifyInitPlaylists.failure,
      actions.spotifyFetchPlaylists.failure,
    ],
    (state, action) => ({
      ...state,
      loading: LoadingState.Failure,
      error: action.payload,
    }),
  )
  .handleAction(actions.spotifyInitPlaylist.request, (state, action) => ({
    ...state,
    playlistsState: updatePlaylistsState(state, action.payload, {
      loading: LoadingState.Request,
      tracks: getInitialPlaylistState().tracks,
      pagination: getInitialPlaylistState().pagination,
      error: undefined,
    }),
  }))
  .handleAction(actions.spotifyInitPlaylist.success, (state, action) => ({
    ...state,
    playlistsState: updatePlaylistsState(state, action.payload, {
      loading: LoadingState.Success,
    }),
  }))
  .handleAction(
    [
      actions.spotifyInitPlaylist.failure,
      actions.spotifyFetchPlaylistTracks.failure,
    ],
    (state, action) => ({
      ...state,
      playlistsState: updatePlaylistsState(state, action.payload.playlistId, {
        loading: LoadingState.Failure,
        error: action.payload.error,
      }),
    }),
  )
  .handleAction(actions.spotifyFetchPlaylistTracks.success, (state, action) => {
    const playlistState = state.playlistsState[action.payload.playlistId];
    return {
      ...state,
      playlistsState: updatePlaylistsState(state, action.payload.playlistId, {
        tracks: [
          ...playlistState.tracks,
          ...getSpotifyFilteredLibraryTracks(action.payload.tracks),
        ],
        pagination: {
          total: action.payload.total,
          offset:
            playlistState.pagination.offset + action.payload.tracks.length,
        },
      }),
    };
  })
  .handleAction(actions.spotifySelectPlaylistTracks, (state, action) => {
    const playlistState = state.playlistsState[action.payload.playlistId];
    return {
      ...state,
      playlistsState: updatePlaylistsState(state, action.payload.playlistId, {
        selectedTracks: [
          ...playlistState.selectedTracks,
          ...action.payload.tracks,
        ],
      }),
    };
  })
  .handleAction(actions.spotifyDeselectPlaylistAllTracks, (state, action) => ({
    ...state,
    playlistsState: updatePlaylistsState(state, action.payload.playlistId, {
      selectedTracks: getInitialPlaylistState().selectedTracks,
    }),
  }))
  .handleAction(actions.spotifyDeselectPlaylistTrack, (state, action) => {
    const playlistState = state.playlistsState[action.payload.playlistId];
    return {
      ...state,
      playlistsState: updatePlaylistsState(state, action.payload.playlistId, {
        selectedTracks: playlistState.selectedTracks.filter(
          (track) => track.id !== action.payload.track.id,
        ),
      }),
    };
  })
  .handleAction(
    actions.spotifyDeletePlaylistTracks.request,
    (state, action) => ({
      ...state,
      playlistsState: updatePlaylistsState(state, action.payload.playlistId, {
        deleteLoading: LoadingState.Request,
      }),
    }),
  )
  .handleAction(
    actions.spotifyDeletePlaylistTracks.failure,
    (state, action) => ({
      ...state,
      playlistsState: updatePlaylistsState(state, action.payload.playlistId, {
        deleteLoading: LoadingState.Failure,
      }),
    }),
  )
  .handleAction(
    actions.spotifyDeletePlaylistTracks.success,
    (state, action) => {
      const playlistState = state.playlistsState[action.payload.playlistId];
      return {
        ...state,
        playlistsState: updatePlaylistsState(state, action.payload.playlistId, {
          tracks: playlistState.tracks.filter(
            ({ id }) => !action.payload.ids.includes(id),
          ),
          selectedTracks: playlistState.selectedTracks.filter(
            ({ id }) => !action.payload.ids.includes(id),
          ),
          deleteLoading: LoadingState.Success,
        }),
      };
    },
  );
