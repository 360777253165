import React, { FC } from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as WaveSvg } from '../svg/wave.svg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  icon: {
    '&.MuiSvgIcon-root': {
      width: '100%',
      height: 'auto',
      marginTop: -1,
    },
  },
}));

export const Wave: FC = () => {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.icon} component={WaveSvg} inheritViewBox />
  );
};
