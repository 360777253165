import React, { FC, useCallback, useEffect } from 'react';
import { PlatformOption } from './PlatformOption';
import Cookies from 'js-cookie';
import { SPOTIFY_AUTH_TOKEN_KEY } from '../../spotify/constants';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../routes';
import { ReactComponent as SpotifySvg } from '../../svg/spotify.svg';

export const PlatformOptionSpotify: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get(SPOTIFY_AUTH_TOKEN_KEY)) {
      navigate(AppRoute.spotify, { replace: true });
    }
  }, []);

  const handleClick = useCallback(() => {
    navigate(AppRoute.spotify);
  }, [navigate]);

  return (
    <PlatformOption name="Spotify" icon={SpotifySvg} onClick={handleClick} />
  );
};
