import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useListItemStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    '&.MuiListItem-root': {
      cursor: 'pointer',
      padding: theme.spacing(1, 8.5, 1, 0),

      '&:hover': {
        backgroundColor: 'rgba(39,39,39,0.05)',
      },

      '& .MuiListItemSecondaryAction-root': {
        right: 0,
      },

      '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
        overflowWrap: 'break-word',
      },
    },
  },
  checkBox: {
    marginLeft: theme.spacing(2.5),

    '& svg': {
      fill: theme.palette.error.dark,
    },
  },
}));
