import React, { FC } from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    '&.MuiTypography-root': {
      lineHeight: 1.2,
      margin: spacing(1, 0),
      color: 'gray',
    },
  },
}));

export const Description: FC = (props) => {
  const { children } = props;
  const classes = useStyles();

  return <Typography className={classes.root}>{children}</Typography>;
};
