import { NotifiableError } from '@bugsnag/core/types/common';
import {
  deezerFetchLibraryTracks,
  deezerInitLibrary,
  deezerDeleteLibraryTracks,
} from '../actions';
import { logEvent } from 'firebase/analytics';
import { ActionType } from 'typesafe-actions';
import { takeLatest, take, call, put, select } from 'redux-saga/effects';
import {
  deleteDeezerLibraryTracksRequest,
  fetchDeezerLibraryRequest,
} from '../api';
import { DeezerLibraryTracksFetchResponse } from '../types';
import { getDeezerLibraryPagination } from '../selectors';
import { analytics } from '../../../analytics';
import { FIREBASE_EVENT } from '../../../analytics/events';
import { handleError } from '../../../errors';
import { Platform } from '../../../types';
import { DeezerPagination } from '../../types';

function* handleDeezerInitLibrary() {
  let pagination: DeezerPagination = yield select(getDeezerLibraryPagination);
  try {
    do {
      yield put(
        deezerFetchLibraryTracks.request({
          url: pagination.next,
        }),
      );
      yield take(deezerFetchLibraryTracks.success);
      pagination = yield select(getDeezerLibraryPagination);
    } while (pagination.next);
    yield put(deezerInitLibrary.success());
    logEvent(analytics, FIREBASE_EVENT.LIBRARY_INIT_SUCCESS);
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.LIBRARY_INIT_FAILURE,
    });
    yield put(deezerInitLibrary.failure(error));
  }
}

export function* watchDeezerInitLibrary() {
  yield takeLatest(deezerInitLibrary.request, handleDeezerInitLibrary);
}

function* handleDeezerFetchLibraryTracks(
  action: ActionType<typeof deezerFetchLibraryTracks.request>,
) {
  try {
    const data: DeezerLibraryTracksFetchResponse = yield call(
      fetchDeezerLibraryRequest,
      action.payload,
    );
    yield put(deezerFetchLibraryTracks.success(data));
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.LIBRARY_FETCH_TRACKS_FAILURE,
    });
    yield put(deezerFetchLibraryTracks.failure(error));
  }
}

export function* watchDeezerFetchLibraryTracks() {
  yield takeLatest(
    deezerFetchLibraryTracks.request,
    handleDeezerFetchLibraryTracks,
  );
}

function* handleDeezerDeleteLibraryTracks(
  action: ActionType<typeof deezerDeleteLibraryTracks.request>,
) {
  try {
    for (const trackId of action.payload) {
      yield call(deleteDeezerLibraryTracksRequest, trackId);
      logEvent(analytics, FIREBASE_EVENT.LIBRARY_TRACK_DELETE_SUCCESS, {
        deletedCount: 1,
        platform: Platform.Deezer,
      });
    }
    yield put(deezerDeleteLibraryTracks.success(action.payload));
  } catch (error) {
    handleError(error as NotifiableError, {
      firebaseEvent: FIREBASE_EVENT.LIBRARY_TRACK_DELETE_FAILURE,
    });
    yield put(deezerDeleteLibraryTracks.failure(error));
  }
}

export function* watchDeezerDeleteLibraryTracks() {
  yield takeLatest(
    deezerDeleteLibraryTracks.request,
    handleDeezerDeleteLibraryTracks,
  );
}
